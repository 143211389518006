import { colors } from "colors";
import styled, { keyframes } from "styled-components";

export const IntroChecklistWrapper = styled.div`
  max-width: 1000px;
  color: ${colors.goodBlack};
  h4 {
    margin-bottom: 16px;
    max-width: 1000px;
  }
  h5 {
    margin-top: 0;
    margin-bottom: 25px;
  }
  button {
    width: fit-content;
    white-space: nowrap;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 8px;
  background: #e0e0e0;
  border-radius: 4px;
  margin-bottom: 8px;
`;

export const Progress = styled.div<{ width: number }>`
  width: ${(props) => props.width}%;
  height: 100%;
  background: ${colors.mainGreen};
  border-radius: 4px;
  transition: width 0.3s ease;
`;

export const ChecklistContainer = styled.div`
  background: white;
  border-radius: 8px;
  padding: 24px;
`;

export const ChecklistItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  border: 1px solid #e0e0e0;

  &:hover {
    background: #f5f5f5;
  }
`;

export const Checkbox = styled.div<{ checked: boolean }>`
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  border: 2px solid
    ${(props) => (props.checked ? colors.mainGreen : colors.black)};
  border-radius: 50%;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.mainGreen};
`;

export const ItemText = styled.span<{ checked: boolean }>`
  color: ${colors.black};
  text-decoration: ${(props) => (props.checked ? "line-through" : "none")};
  font-size: 18px;
  font-weight: ${(props) => (props.checked ? "400" : "600")};
`;

export const ItemContent = styled.div`
  display: flex;
  align-items: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  h4 {
    color: ${colors.black};
    font-size: 14px;
    font-weight: 400;
    margin-top: 8px;
    max-width: 80%;
  }
`;

export const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

export const SkeletonBase = styled.div`
  background: linear-gradient(to right, #e6e7e8 8%, #dddee1 18%, #e6e7e8 33%);
  background-size: 800px 104px;
  animation: ${shimmer} 1.2s ease-in-out infinite;
`;

export const SkeletonText = styled(SkeletonBase)<{ width?: string; height?: string; margin?: string }>`
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '20px'};
  margin: ${({ margin }) => margin || '0'};
`;

export const SkeletonCheckbox = styled(SkeletonBase)`
  width: 24px;
  height: 24px;
  border-radius: 4px;
`;

export const SkeletonButton = styled(SkeletonBase)`
  width: 120px;
  height: 36px;
  border-radius: 4px;
`;

export const SkeletonProgress = styled(SkeletonBase)`
  width: 30%;
  height: 100%;
  border-radius: 4px;
`;