import { FC, useEffect, Dispatch, SetStateAction } from "react";
import { SettingWrapper, IntegrationCard } from "./Settings.styled";
import Zoom from "static/svgs/Zoom";
import Salesforce from "static/svgs/Salesforce";
import { Integration } from "types";
import { useSelector, useDispatch } from "react-redux";
import { startLoading, endLoading, setError } from "state/appActions";
import { User } from "types";
import Teams from "static/svgs/Teams";
import HubSpot from "static/svgs/HubSpot";
import {
  getZoomURL,
  getSalesforceURL,
  getTeamsURL,
  getHubspotURL,
  putUser,
  getSlackURL,
  getGoogleURL,
  putOrganization,
} from "api/helpers";
import Slack from "static/svgs/Slack";
import Google from "static/svgs/Google";
import Gong from "static/svgs/Gong";
import Chorus from "static/svgs/Chorus";

interface IntegrationsProps {
  setTourIsOpen: Dispatch<SetStateAction<boolean>>;
}

const Integrations: FC<IntegrationsProps> = ({ setTourIsOpen }) => {
  const user: User = useSelector((state: any) => state.auth.user);
  const userIsAdmin =
    user.user_role === "admin" || user.user_role === "super_admin";
  const isLoading: User = useSelector((state: any) => state.app.isLoading);
  let dispatch = useDispatch();

  const callSystems = [
    {
      icon: <Zoom />,
      enabled: user && user.zoom_access_token && user.zoom_access_token !== "",
      name: "Zoom",
      function: getZoomURL,
      field_to_clear: "zoom_access_token",
      disabled: Boolean(
        (user && user.microsoft_token && user.microsoft_token !== "") ||
          (user && user.google_token && user.google_token !== "")
      ),
      description:
        "Integrate with Zoom to manage your sales calls directly from our platform (Zoom Pro account required). This allows you to select which calls you want analyzed for each deal.",
    },
    {
      icon: <Teams />,
      enabled: user && user.microsoft_token && user.microsoft_token !== "",
      name: "Microsoft Teams",
      field_to_clear: "microsoft_token",
      disabled: Boolean(
        (user && user.zoom_access_token && user.zoom_access_token !== "") ||
          (user && user.google_token && user.google_token !== "")
      ),
      function: getTeamsURL,
      description:
        "Integrate with Teams to manage your sales calls directly from our platform. This allows you to select which calls you want analyzed for each deal.",
    },
  ];
  const crms = [
    {
      icon: <Salesforce />,
      enabled:
        user &&
        user.organization_info?.salesforce_token &&
        user.organization_info?.salesforce_token !== "",
      name: "Salesforce",
      function: getSalesforceURL,
      field_to_clear: "salesforce_token",
      disabled: Boolean(user && user && user.organization_info?.hubspot_token),
      description:
        "Integrate with Salesforce to synchronize deal data in Leadoff.ai with your CRM. This allows for more specific coaching and automatic updates to CRM fields of your choice.",
    },
    {
      icon: <HubSpot />,
      enabled: Boolean(user && user && user.organization_info?.hubspot_token),
      name: "Hubspot",
      disabled: Boolean(
        user &&
          user.organization_info?.salesforce_token &&
          user.organization_info?.salesforce_token !== ""
      ),
      function: getHubspotURL,
      field_to_clear: "hubspot_token",
      description:
        "Integrate with Hubspot to synchronize deal data in Leadoff.ai  with your CRM. This allows for more specific coaching and automatic updates to CRM fields of your choice.",
    },
  ];

  const notification_systems = [
    {
      icon: <Slack />,
      enabled: user && user.slack_user_id && user.slack_user_id !== "",
      name: "Slack",
      function: getSlackURL,
      field_to_clear: "slack_user_id",
      disabled: false,
      description:
        "Integrate with Slack to receive automatic updates about your deals and next steps via DM. This will also allow you to interact with our Deal Coach from the Slack interface.",
    },
  ];

  const calendars = [
    {
      icon: <Google />,
      enabled: user && user.google_token && user.google_token !== "",
      name: "Google Calendar",
      function: getGoogleURL,
      field_to_clear: "google_token",
      disabled: false,
      description:
        "Connect Google Calendar to have sales calls automatically recorded and analyzed.",
    },
  ];

  const salesSystems = [
    {
      icon: <Gong />,
      enabled:
        user &&
        user.organization_info?.gong_public_key &&
        user.organization_info?.gong_public_key !== "",
      name: "Gong",
      function: () => {},
      field_to_clear: "google_token",
      disabled: false,
      description:
        "Integrate with Gong to manage your sales calls directly from our platform. This automatically pulls recordings from your calls to analyze.",
    },
    {
      icon: <Chorus />,
      enabled:
        user &&
        user.organization_info?.chorus_api_token &&
        user.organization_info?.chorus_api_token !== "",
      name: "Chorus",
      function: () => {},
      field_to_clear: "chorus_api_token",
      disabled: false,
      description:
        "Integrate with Chorus to manage your sales calls directly from our platform. This automatically pulls recordings from your calls to analyze.",
    },
  ];

  const handleSingup = async (func: Function) => {
    dispatch(startLoading());
    let response = await func();
    if (response && response.redirect_url) {
      window.location.assign(response.redirect_url);
    } else {
      dispatch(endLoading());
      dispatch(
        setError(
          "Error loading the authenticator. Please refresh and try again"
        )
      );
    }
  };

  const handleDisconnect = async (field_to_clear: string) => {
    dispatch(startLoading());
    let data = {
      [field_to_clear]: "",
    };
    let response = await putUser(data);
    if (response.status === "success") {
      dispatch(endLoading());
      window.location.reload();
    }
    dispatch(endLoading());
  };

  const handleOrgDisconnect = async (type: string) => {
    dispatch(startLoading());
    let data = {};
    if (type === "salesforce") {
      data = {
        salesforce_instance_url: "",
        salesforce_refresh_token: "",
        salesforce_token: "",
        crm_name: "",
      };
    } else if (type === "hubspot") {
      data = {
        hubspot_token: "",
        hubspot_refresh_token: "",
        crm_name: "",
      };
    }
    let response = await putOrganization(data, user?.organization_info?._id);
    if (response.status === "success") {
      dispatch(endLoading());
      window.location.reload();
    }
    dispatch(endLoading());
  };

  useEffect(() => {
    if (user && !user?.completed_intro_tour && setTourIsOpen !== undefined) {
      setTourIsOpen(true);
    }
  }, []);

  return (
    <SettingWrapper>
      <h3>Integrations</h3>
      <hr />
      <div className="integrations-card-wrapper">
        <div className="inner-wrapper">
          <h3>Notification Systems</h3>
          {notification_systems.map(
            (integration: Integration, index: number) => (
              <IntegrationCard key={index} enabled={integration.enabled}>
                {integration.icon}
                <div className="text-wrapper">
                  <p>
                    <strong>{integration.name}</strong>
                  </p>
                  <p>{integration.description}</p>
                </div>
                <div className="button-wrapper">
                  <div
                    style={{
                      position: "relative",
                      width: "85px",
                      height: "40px",
                    }}
                  >
                    {!integration.disabled && (
                      <button
                        disabled={Boolean(integration.enabled)}
                        onClick={() => handleSingup(integration.function)}
                      >
                        {integration.enabled ? "Enabled" : "Connect"}
                      </button>
                    )}
                  </div>
                  {integration.enabled && (
                    <p
                      className="disable"
                      onClick={() =>
                        handleDisconnect(integration.field_to_clear)
                      }
                    >
                      Disconnect
                    </p>
                  )}
                </div>
              </IntegrationCard>
            )
          )}
        </div>
        <div className="inner-wrapper">
          <h3>Calendars</h3>
          {calendars.map((integration: Integration, index: number) => (
            <IntegrationCard enabled={integration.enabled}>
              {integration.icon}
              <div className="text-wrapper">
                <p>
                  <strong>{integration.name}</strong>
                </p>
                <p>{integration.description}</p>
              </div>
              <div className="button-wrapper">
                <div
                  style={{
                    position: "relative",
                    width: "85px",
                    height: "40px",
                  }}
                >
                  {!integration.disabled && (
                    <button
                      disabled={Boolean(integration.enabled)}
                      onClick={() => handleSingup(integration.function)}
                    >
                      {integration.enabled ? "Enabled" : "Connect"}
                    </button>
                  )}
                </div>
                {integration.enabled && (
                  <p
                    className="disable"
                    onClick={() => handleDisconnect(integration.field_to_clear)}
                  >
                    Disconnect
                  </p>
                )}
              </div>
            </IntegrationCard>
          ))}
        </div>
      </div>
      <div className="integrations-card-wrapper">
        <div className="inner-wrapper">
          <h3>Call Systems</h3>
          {callSystems.map((integration: Integration, index: number) => (
            <IntegrationCard enabled={integration.enabled}>
              {integration.icon}
              <div className="text-wrapper">
                <p>
                  <strong>{integration.name}</strong>
                </p>
                <p>{integration.description}</p>
              </div>
              <div className="button-wrapper">
                <div
                  style={{
                    position: "relative",
                    width: "85px",
                    height: "40px",
                  }}
                >
                  {!integration.disabled && (
                    <button
                      disabled={Boolean(integration.enabled)}
                      onClick={() => handleSingup(integration.function)}
                    >
                      {integration.enabled ? "Enabled" : "Connect"}
                    </button>
                  )}
                </div>
                {integration.enabled && (
                  <p
                    className="disable"
                    onClick={() => handleDisconnect(integration.field_to_clear)}
                  >
                    Disconnect
                  </p>
                )}
              </div>
            </IntegrationCard>
          ))}
          {salesSystems.map((integration: Integration, index: number) => (
            <IntegrationCard key={index} enabled={integration.enabled}>
              {!userIsAdmin && <div className="overlay"></div>}
              {integration.icon}
              <div className="text-wrapper">
                <p>
                  <strong>{integration.name}</strong>
                </p>
                <p>{integration.description}</p>
              </div>
              <div className="button-wrapper">
                <div
                  style={{
                    position: "relative",
                    width: "85px",
                    height: "40px",
                  }}
                >
                  {!integration.disabled && integration.enabled && (
                    <button
                      disabled={Boolean(integration.enabled)}
                      onClick={() => handleSingup(integration.function)}
                    >
                      {integration.enabled ? "Enabled" : "Connect"}
                    </button>
                  )}
                </div>
                {integration.enabled && (
                  <p
                    className="disable"
                    onClick={() =>
                      handleOrgDisconnect(
                        integration.name == "Salesforce"
                          ? "salesforce"
                          : "hubspot"
                      )
                    }
                  >
                    Disconnect
                  </p>
                )}
              </div>
            </IntegrationCard>
          ))}
        </div>
        <div className={userIsAdmin ? "inner-wrapper" : "disabled"}>
          <h3>Customer Relationship Managers</h3>
          {crms.map((integration: Integration, index: number) => (
            <IntegrationCard enabled={integration.enabled}>
              {!userIsAdmin && <div className="overlay"></div>}
              {integration.icon}
              <div className="text-wrapper">
                <p>
                  <strong>{integration.name}</strong>
                </p>
                <p>{integration.description}</p>
              </div>
              <div className="button-wrapper">
                <div
                  style={{
                    position: "relative",
                    width: "85px",
                    height: "40px",
                  }}
                >
                  {!integration.disabled && (
                    <button
                      disabled={Boolean(integration.enabled)}
                      onClick={() => handleSingup(integration.function)}
                    >
                      {integration.enabled ? "Enabled" : "Connect"}
                    </button>
                  )}
                </div>
                {integration.enabled && (
                  <p
                    className="disable"
                    onClick={() =>
                      handleOrgDisconnect(
                        integration.name == "Salesforce"
                          ? "salesforce"
                          : "hubspot"
                      )
                    }
                  >
                    Disconnect
                  </p>
                )}
              </div>
            </IntegrationCard>
          ))}
          {!userIsAdmin && (
            <h4>Only admins can connect to Customer Relationship Managers</h4>
          )}
        </div>
      </div>
    </SettingWrapper>
  );
};

export default Integrations;
