import styled from "styled-components";
import { colors } from "colors";

export const InsightsWrapper = styled.div`
  .tabs-header {
    display: flex;
    background: white;
    border-radius: 8px;
    gap: 0px;
    margin-bottom: 0px;
    width: fit-content;
    position: relative;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
    border-bottom: 1px solid #e5e7eb;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      background: linear-gradient(356deg, ${colors.mainGreen} 0%, #4cabca 100%);
      transition: all 0.3s ease;
      width: calc(100% / 3); // Assuming 3 tabs
      transform: translateX(calc(100% * var(--active-tab-index, 0)));
    }
  }

  .tab-button {
    padding: 12px 24px;
    border: none;
    background: transparent;
    color: black;
    cursor: pointer;
    font-size: 14px;

    font-weight: 600;
    transition: all 0.2s ease;
    position: relative;
    flex: 1;
    width: 200px;

    &.active {
      background: #1f2937;
    }

    &:hover:not(.active) {
      background: rgba(31, 41, 55, 0.1); // Changed to use a darker color with low opacity
    }
  }

  .tab-content {
    padding: 0px 0;
  }

  .active-tab-content {
    font-size: 14px;
    background: linear-gradient(356deg, ${colors.mainGreen} 0%, #4cabca 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;