import { colors } from "colors"
import styled from "styled-components"

export const SignupWrapper = styled.div`
  display: flex;
  height: 100vh;
  color: #333;
  min-height: 1090px;
  position: relative;
  background-color: ${colors.goodBlack};
  a {
    color: blue;
  }
  svg {
    rect:first-of-type {
      fill: ${colors.white};
    }
  }
  .blur {
    background: radial-gradient(
      65.75% 76.31% at 59.83% 28.24%,
      rgba(0, 191, 99, 0.3) 1%,
      ${colors.goodBlack} 97.39%
    );
    width: 100%;
    height: 400px;
    position: absolute;
    z-index: 20;
  }
`

export const FormPlaceHolder = styled.div`
  width: 50%;
`

export const OAuthWrappr = styled.div`
  width: 100%;
  height: auto;
`

export const LogoWrapper = styled.div`
  width: 247px;
  height: 65px;
  margin: 0 auto;
  margin-bottom: 40px;
  svg {
    width: 100%;
    height: 100%;
  }
`

export const InnerFormWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 40px;
  select {
    padding-right: 10px;
  }
  h1 {
    font-size: 38px;
  }
  h5 {
    margin-bottom: 55px;
  }
  h2 {
    font-size: 25px;
    font-weight: 400;
  }
  h4 {
    font-size: 20px;
    font-weight: 400;
  }
  .button-wrapper {
    width: 110px;
    margin: 0 auto;
    margin-right: 0;
    button {
      color: ${colors.mainGreen};
      height: 40px;
      font-size: 15px;
      &:hover {
        color: ${colors.goodBlack};
      }
    }
  }
  .buttons-wrapper {
    display: flex;
    width: 250px;
    justify-content: space-between;
  }
  .button-text-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 20px;
  }
  .terms {
    margin: 0 auto;
    margin-left: 0;
    margin-bottom: 30px;
    margin-top: 30px;
  }
`

export const FormWrapper = styled.div`
  flex: 0 1 100%;
  padding: 55px 50px;
  flex-wrap: wrap;
  background-color: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: relative;
  z-index: 22;
  margin-top: 130px;
  h4 {
    margin-bottom: 30px;
  }
  h2 {
    font-weight: 500;
    margin-top: 15px;
    margin-bottom: 30px;
    line-height: 32px;
  }
  .horizontal-divider {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 20px; /* Adjust as needed */
    height: 30px;
    margin: 40px 0;
  }

  .line {
    flex-grow: 1;
    height: 1px;
    background-color: #dee2d4;
  }

  .or-text {
    padding: 0 10px;
    font-size: 14px;
    font-weight: bold;
  }
  @media (min-width: 1077px) {
    flex: 0 1 50%;
  }
`

export const ContentWrapper = styled.div`
  display: none;
  position: absolute;
  z-index: 19;
  width: 60%;
  height: 100%;
  @media (min-width: 1077px) {
    flex: 0 1 50%;
    color: white;
    background: radial-gradient(
      116.75% 51.31% at 91.83% 94.24%,
      #5b5f64 0%,
      ${colors.goodBlack} 97.39%
    );
    display: block;
  }
`

export const SignupForm = styled.form`
  width: 100%;
  .subtext {
    font-size: 10px;
  }
  .flex-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tool-tip {
    margin-left: 20px;
    border: 1px solid ${colors.middleGray};
    border-radius: 100%;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    .tool-tip-wrapper {
      /* left: -200%; */
    }
    .triangle {
      transform: translate(-42%, -69%) rotate(132deg);
    }
  }
  .name-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .input-wrapper {
    width: 47%;
    display: block;
  }
  .email-wrapper {
    margin-top: 0;
    label {
      margin-top: -5px;
      margin-bottom: 40px;
    }
  }
`
