import React, { FC } from "react";
import {
  DealPredictionsWrapper,
  FeatureDetails,
  PercentageIndicator,
  RecommendationItem,
  RecommendationsSection,
  ShareButtonWrapper,
} from "./DealPredictions.styled";
import ShareButton from "static/svgs/ShareButton";

interface RecommendedFeature {
  feature_name: string;
  impact: number;
  explanation: string;
}

interface DealPredictionsProps {
  data: RecommendedFeature[] | undefined;
  overallScore: number | undefined;
}

const DealPredictions: FC<DealPredictionsProps> = ({ data, overallScore }) => {
  if (!data || !overallScore) {
    return null;
  }

  return (
    <DealPredictionsWrapper>
      <ShareButtonWrapper>
        <p className="share-button-text">Share Recommendations</p>
        <ShareButton />
      </ShareButtonWrapper>
      <div className="score-section">
        <h2>Deal Success Score</h2>
        <div className="score-tracker">
          <div className="progress-bar" style={{ width: `${overallScore}%` }}>
            <span className="score-label">{overallScore}%</span>
          </div>
        </div>
      </div>

      <RecommendationsSection>
        <h3>Increase likelihood to win by taking these next steps:</h3>
        {data.map((feature, index) => (
          <RecommendationItem key={index}>
            <PercentageIndicator>
              +{feature.impact.toFixed(2)}%
            </PercentageIndicator>
            <FeatureDetails>
              <div className="feature-name">
                {feature.feature_name.split("_").join(" ")}
              </div>
              <div className="explanation">{feature.explanation}</div>
            </FeatureDetails>
          </RecommendationItem>
        ))}
      </RecommendationsSection>
    </DealPredictionsWrapper>
  );
};

export default DealPredictions;
