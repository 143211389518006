import { colors } from "colors";
import styled from "styled-components";

export const MeddicSpiderChartWrapper = styled.div`
  grid-template-columns: 250px 1fr 250px;
  gap: 12px; // Reduced from 24px
  width: 100%;
  padding: 30px 0px; // Reduced from 20px
  align-items: start;
  position: relative;
  height: fit-content;
  padding-top: 0;
  .update-stages-button {
    position: absolute;
    right: 22%;
    top: 40px;
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    max-width: 225px;
    color: ${colors.black};
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      path {
        fill: ${colors.black};
      }
    }
  }

  .filters-left {
    justify-self: start;
  }

  .filters-right {
    justify-self: end;
  }

  .filter-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    h3 {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .filter-title-container {
    display: flex;
    flex-direction: row !important;
    gap: 25px;
    justify-content: flex-start;
  }

  .charts-wrapper {
    width: 820px !important;
  }

  .filters-left,
  .filters-right {
    position: relative;
    z-index: 1; // Place filters behind the chart
  }
  .loading-bars {
    display: flex;
    justify-content: center;
    gap: 4px;
    margin-top: 8px;
    height: 20px;
    width: 18px;
  }

  .bar {
    width: 4px;
    height: 70%;
    background-color: #00bf63;
    animation: loadingBar 0.9s ease-in-out infinite;
    transform-origin: center;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }

  @keyframes loadingBar {
    0% {
      transform: scaleY(1);
    }
    50% {
      transform: scaleY(2);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;

export const FiltersWrapper = styled.div`
  background: white;
  padding: 19px;
  border-top-right-radius: 5px;
  width: 100%;
  border-bottom: 1px solid #e5e7eb;

  > div {
    display: flex;
    flex-direction: column !important;
    gap: 24px;
    justify-content: center;
  }

  .filter-title-container {
    display: flex;
    flex-direction: row !important;
    gap: 25px;
    justify-content: flex-start;
  }
`;

export const FilterGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;

  &:last-child {
    margin-bottom: 0;
  }
`;
export const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  min-width: 115px;
`;

export const FilterLabel = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #111827;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ColorDot = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${(props) => props.color};
`;

export const FilterSelect = styled.select`
  width: 100%;
  height: 38px;
  padding: 0 14px;
  background: #1f2937;
  border: 1px solid #374151;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  margin-bottom: 12px;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='white' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px center;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    border-color: #4b5563;
  }

  &:focus {
    outline: none;
    border-color: #6366f1;
  }
`;

export const FilterTitle = styled.h2`
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 15px;
  color: black;
`;
interface ColorSelectProps {
  value?: string; // Using $ prefix to avoid DOM attribute warning
}

export const ColorSelect = styled.select<ColorSelectProps>`
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${(props) => props.value};
  appearance: none;
  -webkit-appearance: none;

  &:focus {
    outline: 1px solid #ccc;
  }

  /* Hide the text but keep it accessible for screen readers */
  color: transparent;

  /* Show color options on hover/focus */
  &:hover,
  &:focus {
    height: auto;
    color: #333;
    background-color: white;
    border: 1px solid #ccc;
    padding: 4px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  background-color: white;
  overflow: hidden;
`;

export const ChartWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px; // Add horizontal margin to space it from filters and insights
  width: 100%;
  min-width: 633px;
  min-height: 500px;
  width: 633px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  background: #1f2937;
  border-radius: 5px;
  margin: 10px;
`;

export const LegendWrapper = styled.div`
  background: none;
  border-radius: 8px;
  padding: 0;
  width: 33%;
  button {
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    max-width: 225px;
    color: ${colors.black};
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    svg {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      path {
        fill: ${colors.black};
      }
    }
  }
`;

export const LegendTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: black;
`;

export const LegendItem = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;

  &:before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: ${(props) => props.color};
  }
`;

export const LegendSkeletonLoader = styled.div`
  width: 100%;
  height: 40px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  border-radius: 4px;
  margin: 8px 0;
  animation: loading 1.5s infinite;

  @keyframes loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
`;

export const SparkleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 24px;
    height: 24px;
  }
`;

export const SearchContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const SearchInput = styled.input`
  width: 90%;
  max-width: 400px;
  height: 48px;
  padding: 0 20px;
  border-radius: 24px;
  border: 1px solid #e5e7eb;
  background: white;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  &::placeholder {
    color: #9ca3af;
  }

  &:focus {
    outline: none;
    border-color: #22c55e; // Changed to green
    box-shadow: 0 0 0 2px rgba(34, 197, 94, 0.1); // Changed to green
  }
`;

export const Bubble = styled.button`
  padding: 8px 16px;
  border-radius: 20px;
  border: none;
  background: linear-gradient(
    135deg,
    #22c55e 0%,
    #16a34a 100%
  ); // Changed to green gradient
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: 0.9;

  &:hover {
    opacity: 1;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(34, 197, 94, 0.2); // Changed to green
  }
`;
export const SuggestionBubbles = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: center;
  padding: 0 20px;
  .svg-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 24px;
    fill: ${colors.mainGreen};
    margin-top: 40px;
    svg {
      width: 64px;
      height: 64px;
      path {
        fill: ${colors.mainGreen};
      }
    }
  }
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0;
`;

export const LoaderBar = styled.div`
  width: 8px;
  height: 32px;
  margin: 0 3px;
  margin-top: 20px;
  background-color: #00bf63;
  border-radius: 2px;
  animation: loading 0.9s ease-in-out infinite;

  &:nth-child(1) {
    animation-delay: 0s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes loading {
    0% {
      transform: scaleY(1);
    }
    50% {
      transform: scaleY(2);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;

export const AiResponseContainer = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  color: black;
  overflow-y: scroll;
  max-height: 350px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 16px;
  h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  p {
    margin-top: 5px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
  }
`;

export const LoaderText = styled.div`
  perspective: 1000px;
  height: 40px;
  width: 250px;
  margin: 20px auto 0;
  position: relative;

  .text-cube {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 40px;
    transform-style: preserve-3d;
    transform-origin: center;
    animation: rotate 16s infinite cubic-bezier(0.4, 0, 0.2, 1);
  }

  .text-item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: ${colors.mainGreen};
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: center;
  }

  .front {
    transform: rotateX(0deg) translateZ(20px);
  }
  .bottom {
    transform: rotateX(-90deg) translateZ(20px);
  }
  .back {
    transform: rotateX(-180deg) translateZ(20px);
  }
  .top {
    transform: rotateX(90deg) translateZ(20px);
  }

  @keyframes rotate {
    0%,
    22% {
      transform: rotateX(0deg);
    }
    25%,
    47% {
      transform: rotateX(90deg);
    }
    50%,
    72% {
      transform: rotateX(180deg);
    }
    75%,
    97% {
      transform: rotateX(270deg);
    }
    100% {
      transform: rotateX(360deg);
    }
  }
`;
