import styled, { keyframes } from "styled-components";
import { colors, scoreToColor } from "colors";

interface DealSummaryWrapperProps {
  showStage: boolean;
}

export const DealSummaryWrapper = styled.div<DealSummaryWrapperProps>`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 101;
  color: ${colors.black};
  padding: 25px;
  padding-top: 0;
  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .general-feedback {
    height: ${(props) => (props.showStage ? "61%" : "87%")};
    overflow-y: auto;
    padding: 24px 30px 30px 0;
    padding-top: 0;

    h3 {
      font-size: 28px;
      font-weight: 700;
      margin-top: 20px;
      margin-bottom: 34px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -8px;
        width: 90px;
        height: 3px;
        background: ${colors.mainGreen};
        border-radius: 2px;
      }
    }

    h5 {
      font-size: 24px;
      font-weight: 600;
      margin: 24px 0 12px;
      color: ${colors.green};
      display: flex;
      align-items: center;
      &::before {
        content: "";
        display: inline-block;
        width: 4px;
        height: 20px;
        margin-right: 12px;
        border-radius: 2px;
      }
      &:nth-child(10n + 1)::before {
        background: #e8a6a6;
      }
      &:nth-child(10n + 2)::before {
        background: #d6b497;
      }
      &:nth-child(10n + 3)::before {
        background: #a6d8a6;
      }
      &:nth-child(10n + 4)::before {
        background: #a6cde8;
      }
      &:nth-child(10n + 5)::before {
        background: #f1a6e8;
      }
      &:nth-child(10n + 6)::before {
        background: #d1a6f1;
      }
      &:nth-child(10n + 7)::before {
        background: #a6f1e8;
      }
      &:nth-child(10n + 8)::before {
        background: #f1e8a6;
      }
      &:nth-child(10n + 9)::before {
        background: #c6f1a6;
      }
      &:nth-child(10n + 10)::before {
        background: #f1c0a6;
      }
    }

    /* Modern scrollbar styling */
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.mainGreen}40;
      border-radius: 4px;
      transition: background 0.2s ease;

      &:hover {
        background: ${colors.mainGreen}60;
      }
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 4px;
    }

    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: ${colors.mainGreen}40 transparent;
  }
  hr {
    width: 100%;
    margin-left: 5%;
    border: none;
    height: 1px;
    background-color: ${colors.middleGray};
    margin: 0 0;
    margin-bottom: 30px;
  }
  .all-wrapper {
    overflow-y: auto;
    height: ${(props) => (props.showStage ? "61%" : "87%")};
    /* Modern scrollbar styling */
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background: ${colors.mainGreen}40;
      border-radius: 4px;
      transition: background 0.2s ease;

      &:hover {
        background: ${colors.mainGreen}60;
      }
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 4px;
    }

    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: ${colors.mainGreen}40 transparent;
    h3 {
      font-size: 28px;
      font-weight: 700;
      margin-top: 20px;
      margin-bottom: 50px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -8px;
        width: 90px;
        height: 3px;
        background: ${colors.mainGreen};
        border-radius: 2px;
      }
    }
  }
  .full-wrapper {
    border-bottom: 1px solid ${colors.lightGray};
    h3 {
      font-size: 20px;
      font-weight: 500;
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }
`;

interface TabProps {
  active: boolean;
}

export const Tab = styled.div<TabProps>`
  height: 38px;
  padding: 8px 16px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.3px;
  transition: all 0.2s ease-in-out;
  position: relative;
  background-color: transparent;
  border: 1px solid ${colors.darkGray2};
  color: ${colors.darkGray2};
  cursor: pointer;

  &:hover {
    background-color: ${colors.darkGray2};
    transform: translateY(-1px);
    color: white;
  }

  ${(props) =>
    props.active &&
    `
      background-color: #1f2937;
      color: ${colors.white2};
      border: none;
      border-radius: 8px 8px 0 0;
      padding: 11px 17px;

      &:hover {
        background-color: #1f2937;
      }
      
      &::after {
        content: '';
        position: absolute;
        bottom: -3px;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 3px;
        background: ${colors.mainGreen};
      }
    `}

  & + & {
    margin-left: 8px;
  }
`;

export const TabsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  hr {
    width: 26px;
    transform: rotate(90deg);
    border: none;
    height: 1px;
    background-color: ${colors.grayWhite};
    margin: 0px -6px;
  }
`;

export const InfoWrapper = styled.div`
  width: 100%;
  padding: 10px 0px;
  text-align: left;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  .cited-text {
    font-style: italic;
    font-size: 13px;
  }
  .feedback-flex {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 15px;
    svg {
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px;
      path {
        fill: ${colors.mainGreen};
      }
    }
    p {
      margin: 0;
      margin-left: 0px;
      padding: 0;
    }
  }
  .width-wrapper {
    width: 50%;
    border-right: 1px solid ${colors.lightGray};
    padding-right: 8px;
    max-height: 372px;
    overflow: hidden;
    .scroll {
      overflow-y: auto;
      height: 85%;
      padding-right: 5px;
      svg {
        margin-right: 10px;
      }
    }
  }
  .width-wrapper-all {
    width: 50%;
    border-right: 1px solid ${colors.lightGray};
    padding-right: 20px;
    .scroll {
      overflow-y: auto;
    }
  }
  .width-wrapper-2 {
    width: 48%;
    padding-right: 15px;
    position: relative;
    padding-bottom: 20px;
    svg {
      height: 25px;
      width: 25px;
      margin-right: 10px;
    }
  }
  .flex {
    display: flex;
    flex-wrap: nowrap;
    h4 {
      width: auto;
      margin-right: 20px;
    }
  }
  p {
    padding-left: 25px;
  }
  .title {
    color: ${colors.mainGreen};
    font-weight: 600;
    margin-bottom: 20px;
    margin-top: 0px;
    padding-left: 0;
    font-size: 18px;
  }
  .cited {
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.lightGray};
  }
  /* Modern scrollbar styling */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.mainGreen}40;
    border-radius: 4px;
    transition: background 0.2s ease;

    &:hover {
      background: ${colors.mainGreen}60;
    }
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${colors.mainGreen}40 transparent;
`;

interface ScoreBoxProps {
  score: number;
}

export const ScoreBox = styled.div<ScoreBoxProps>`
  width: 42px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 700;
  span {
    font-size: 10px;
    font-weight: 300;
  }
  ${(props) => {
    let { background, text } = scoreToColor(props.score);
    return `
      background-color: ${background};
      color: ${text};
    `;
  }};
`;

export const ContactsWrapper = styled.div`
  text-align: left;
  width: 100%;
  margin-bottom: 50px;
  overflow: scroll;
  overflow-x: hidden;
  padding-right: 15px;
  height: 140px;
  .name {
    width: 20%;
  }
  .position {
    width: 33%;
  }
  .focus {
    width: 40%;
  }
  .bold {
    font-weight: 600;
    margin-bottom: 20px;
  }
  p {
    margin: 0;
    margin-bottom: 10px;
  }
`;

export const ContactDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface StageProps {
  splice: number;
  activeIndex: number;
}

export const StageWrapper = styled.div<StageProps>`
  display: flex;
  justify-content: space-between;
  margin-top: 55px;
  margin-bottom: -20px;
  height: 130px;
  .bar-background {
    width: 90%;
    margin: 0 auto;
    background: linear-gradient(darkGray 0%, #4b4d52 100%);
    height: 7px;
    border-radius: 5px;
    position: relative;
  }
  .progress-bar {
    background-color: ${colors.mainGreen};
    height: 5px;
    width: ${(props) => props.splice && `${props.splice * props.activeIndex}%`};
    position: absolute;
    top: 1px;
    max-width: 100%;
  }
  .points-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    top: -3px;
  }
  .point-wrapper {
    width: ${(props) => props.splice && `${props.splice}%;`};
  }
`;

interface PointWrapperProps {
  start: boolean;
  end: boolean;
}
export const PointWrapper = styled.div<PointWrapperProps>`
  display: flex;
  justify-content: center;
  ${(props) => props.start && `justify-content: flex-start;`}
  ${(props) => props.start && `justify-content: flex-end;`}
`;

interface PointProps {
  checked: boolean;
}

export const Point = styled.div<PointProps>`
  display: flex;
  justify-content: space-between;
  height: 15px;
  width: 15px;
  border-radius: 100%;
  margin-bottom: 15px;
  position: relative;
  ${(props) =>
    props.checked
      ? `
      outline: 5px solid white;
      box-shadow: 0px 4px 4px 0px #00000040;
      background: linear-gradient(180deg, #7CDF4D 0%, #4CABCA 100%);
  `
      : `
      height: 12px;
      width: 12px;
      outline: 8px solid ${colors.black};
      background: ${colors.darkGray2};
  `}
`;

export const Quote = styled.div<PointProps>`
  position: absolute;
  top: 33px;
  left: 20%;
  transform: translate(-50%, 0);
  position: relative;
  margin: 5px;
  display: inline-block;
  font-size: 30px;
  width: 100px;
  height: fit-content;
  font-size: 11px;
  padding: 6px 10px;
  text-align: center;
  color: ${colors.darkGray2};
  font-weight: 500;
  background-color: ${colors.black};
  border-radius: 5px;
  ${(props) =>
    props.checked &&
    `
    color: ${colors.white2};
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 5px;
        padding: 2px; /* control the border thickness */
        background: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
      }
  `}

  .point {
    height: 10px;
    width: 10px;
    position: absolute;
    background-color: ${colors.black};
    top: -5px;
    left: 50%;
    transform: translate(-50%, 0) rotate(45deg);
    ${(props) =>
      props.checked &&
      `
      &::before {
        content: "";
        position: absolute;
        inset: 0;
        border-radius: 0px;
        padding: 2px 0 0 2px; /* control the border thickness */
        background: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
        -webkit-mask: linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        pointer-events: none;
        border-left: none;
        border-bottom: none;
      }
      `}
  }
`;

export const GenerateButton = styled.button`
  width: 100%;
  padding: 14px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  background: linear-gradient(90deg, #7CDF4D 0%, #4CABCA 100%);
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  overflow: hidden;
  margin-top: 40px;
  margin-bottom: 0px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(90deg, #8DEF5D 0%, #5CBBDA 100%);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(124, 223, 77, 0.2);

    &::before {
      opacity: 1;
    }
  }

  &:active {
    transform: translateY(1px);
  }

  svg {
    width: 20px;
    height: 20px;
    fill: currentColor;
    position: relative;
    z-index: 1;
    path {
      fill: white;
    }
  }

  span {
    position: relative;
    z-index: 1;
  }
`;