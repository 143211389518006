import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { useDispatch, useSelector } from "react-redux";
import { startLoading, endLoading } from "state/appActions";
import {
  getDeals,
  deleteDeal,
  putDeal,
  getDealsQuick,
  getOrganizationUsers,
} from "api/helpers";
import moment from "moment";
import { Category, Priority, QuickDeal, Stage, UserData } from "types";
import { DropdownItem } from "components/DealPage/DealPage.styled";
import PageWrapper from "components/reusable/PageWrapper/PageWrapper";
import { GradientText } from "shared/shared_styled_comps/components.styled";
import Paginate from "components/reusable/Paginate/Paginate";
import {
  Deal,
  DealsWrapper,
  DealsHeader,
  DealsTable,
  DealsLoadWrapper,
  TableScoreBox,
  Tab,
  TabsWrapper,
  FiltersContainer,
  FiltersHeader,
  FiltersContent,
  Arrow,
  ScoreBox,
} from "./DealsPage.styled";
import SearchBar from "components/reusable/SearchBar/SearchBar";
import Filter from "static/svgs/Filter";
import { Deal as DealDetails, CategoryDefault } from "types";
import Trash from "static/svgs/Trash";
import Refresh from "static/svgs/Refresh";
import PriorityDropdown from "components/PriorityDropdown/PriorityDropdown";
import { colors } from "colors";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { NewDropdown } from "shared/shared_components/components";

interface DealsPageProps {
  tourStep: number;
  setTourStep: Dispatch<SetStateAction<number>>;
  setTourIsOpen: Dispatch<SetStateAction<boolean>>;
}

const defaultScores = [
  {
    category_name: "C",
    highest_score: 0,
  },
  {
    category_name: "M",
    highest_score: 0,
  },
  {
    category_name: "E",
    highest_score: 0,
  },
  {
    category_name: "D",
    highest_score: 0,
  },
  {
    category_name: "D",
    highest_score: 0,
  },
  {
    category_name: "P",
    highest_score: 0,
  },
  {
    category_name: "I",
    highest_score: 0,
  },
  {
    category_name: "C",
    highest_score: 0,
  },
  {
    category_name: "C",
    highest_score: 0,
  },
  {
    category_name: "V",
    highest_score: 0,
  },
];

const sortByOptions = [
  {
    label: "Score",
    value: "total_score",
  },
  {
    label: "Last Call",
    value: "last_call",
  },
  {
    label: "Stage",
    value: "deal_stage",
  },
  {
    label: "Value",
    value: "deal_value",
  },
  {
    label: "Close Date",
    value: "deal_close_date",
  },
];

const DealsPage: FC<DealsPageProps> = ({
  tourStep,
  setTourStep,
  setTourIsOpen,
}) => {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.user);
  const isLoading = useSelector((state: any) => state.app.isLoading);
  const [activeOption, setActiveOption] = useState<"my-deals" | "team-deals">(
    "my-deals"
  );

  const [data, setData] = useState<any>();
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState("Last Call");
  const [direction, setDirection] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalDeals, setTotalDeals] = useState(1);
  const tipRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [dealStages, setDealStages] = useState<{ [key: string]: string }>({});
  const [editingDealId, setEditingDealId] = useState<string | null>(null);
  const [editedDealName, setEditedDealName] = useState("");
  const [quickDeals, setQuickDeals] = useState<QuickDeal[]>([]);
  const [users, setUsers] = useState<UserData[]>([]);
  const stages = useSelector((state: any) => state.app.stages);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserData | null>(null);
  const [selectedDeal, setSelectedDeal] = useState<QuickDeal | null>(null);
  const [selectedStage, setSelectedStage] = useState<Stage | null>(null);
  const crmEnabled = Boolean(
    user.organization_info?.salesforce_token ||
      user.organization_info?.hubspot_token
  );

  // Filters
  const handleUpdateUser = (user_id: string) => {
    if (user_id === "") {
      setSelectedUser(null);
    } else {
      const user = users.find((user: UserData) => user._id === user_id);
      setSelectedUser(user || null);
    }
  };

  const handleUpdateDeal = (deal_id: string) => {
    if (deal_id === "") {
      setSelectedDeal(null);
    } else {
      const deal = quickDeals.find((deal: QuickDeal) => deal.id === deal_id);
      setSelectedDeal(deal || null);
      setSelectedStage(null);
    }
  };

  const handleUpdateStage = (stage_value: string) => {
    if (stage_value === "") {
      setSelectedStage(null);
    } else {
      const stage = stages.find((stage: Stage) => stage.value === stage_value);
      setSelectedStage(stage);
    }
    setSelectedDeal(null);
  };

  const toggleFilters = () => {
    setFiltersOpen(!filtersOpen);
  };

  const tableHeaders: string[] = [
    "Score",
    "Deal Name",
    ...(activeOption === "team-deals" ? ["Rep"] : []),
    "Last Call",
    "Stage",
    ...(crmEnabled ? ["Value"] : []),
    ...(crmEnabled ? ["Close Date"] : []),
    "Categories",
  ];

  const fetchDealsData = async (userOverride: boolean) => {
    dispatch(startLoading());
    let data = {
      sortBy:
        sortByOptions.find((option) => option.label === sortBy)?.value ||
        "last_call",
      sortOrder: direction ? "desc" : "asc",
      limit: 10,
      page: activePage,
      filterBy: "",
      searchBy: searchValue,
      orgDeals: activeOption === "team-deals",
      dealId: selectedDeal?.id,
      userId: userOverride ? undefined : selectedUser?._id,
      stage: selectedStage?.label,
    };
    const [response, quickDeals, users] = await Promise.all([
      getDeals(data),
      getDealsQuick(true),
      getOrganizationUsers(),
    ]);

    if (user && !user?.completed_intro_tour && tourStep > 0) {
      setTourIsOpen(true);
    }
    // Set deals data to state
    setTotalPages(response?.total_pages);
    setTotalDeals(response?.total_deals);
    console.log("Deals: ", response?.deals);
    setData(response?.deals);
    setQuickDeals(quickDeals);
    setUsers(users);
    dispatch(endLoading());
  };

  const deleteDealChoice = async (
    e: React.MouseEvent<HTMLDivElement>,
    deal_id: string
  ) => {
    e.preventDefault();
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this deal? All deal data will be deleted for good."
    );
    if (userConfirmed) {
      dispatch(startLoading());
      let response = await deleteDeal(deal_id);
      dispatch(endLoading());
      window.location.reload();
    } else {
      return;
    }
  };

  useEffect(() => {
    if (activeOption === "my-deals") {
      setSelectedUser(user);
    } else {
      setSelectedUser(null);
    }
    const init = async () => {
      window.scrollTo(0, 0);
      dispatch(startLoading());
      if (Object.keys(user).length !== 0) {
        await fetchDealsData(false);
      }
      dispatch(endLoading());
    };
    init();
  }, [
    activePage,
    sortBy,
    direction,
    user,
    selectedUser,
    selectedDeal,
    selectedStage,
  ]);

  useEffect(() => {
    setSelectedUser(user);
    if (activeOption === "team-deals") {
      setSelectedUser(null);
      setSelectedDeal(null);
      setSelectedStage(null);
    } else {
      setSelectedUser(user);
      setSelectedDeal(null);
      setSelectedStage(null);
    }
  }, [activeOption]);

  const updateSort = (header: string) => {
    if (header === "Stage") {
      header = "deal_stage";
    } else if (header === "Deal Name") {
      header = "deal_name";
    } else if (header === "Score") {
      header = "total_score";
    } else if (header === "Last Call") {
      header = "created_at";
    } else if (header === "Rep") {
      header = "user";
    }
    if (sortBy === header) {
      setDirection(!direction);
    } else {
      setDirection(true);
    }
    setSortBy(header);
    console.log(`header: ${header}`);
    console.log(`direct: ${direction ? "desc" : "asc"}`);
  };

  const handleDealDetailsNavigation = (deal_id: string) => {
    if (!user?.completed_intro_tour) {
      setTourStep(6);
      setTourIsOpen(false);
      navigate(`/app/deals/${deal_id}`);
    } else {
      navigate(`/app/deals/${deal_id}`);
    }
  };

  const handlePriorityChange = async (
    deal_id: string,
    newPriority: Priority
  ) => {
    try {
      const updatedDeal = await putDeal({ priority: newPriority }, deal_id);
    } catch (error) {
      console.error("Error updating deal priority:", error);
    }
  };

  const handleStageChange = async (deal_id: string, newStage: string) => {
    try {
      setDealStages((prevStages) => ({
        ...prevStages,
        [deal_id]: newStage,
      }));
      const updatedDeal = await putDeal({ deal_stage: newStage }, deal_id);
    } catch (error) {
      console.error("Error updating deal stage:", error);
    }
  };

  const toggleSortDirection = () => {
    setDirection(!direction);
  };

  const handleDealNameEdit = (dealId: string, currentName: string) => {
    setEditingDealId(dealId);
    setEditedDealName(currentName);
  };

  const handleDealNameSave = async (dealId: string) => {
    try {
      setData((prevData: DealDetails[]) =>
        prevData.map((deal: DealDetails) =>
          deal._id === dealId ? { ...deal, deal_name: editedDealName } : deal
        )
      );
      setEditingDealId(null);
      await putDeal({ deal_name: editedDealName }, dealId);
    } catch (error) {
      console.error("Error updating deal name:", error);
    }
  };

  useEffect(() => {
    const tabsHeader = document.querySelector(".tabs-header") as HTMLElement;
    if (activeOption === "my-deals") {
      if (tabsHeader) {
        tabsHeader.style.setProperty("--active-tab-index", "0");
      }
    } else {
      if (tabsHeader) {
        tabsHeader.style.setProperty("--active-tab-index", "1");
      }
    }
  }, [activeOption]);

  return (
    <PageWrapper pageTitle="Deals" className="deals-page">
      <TabsWrapper className="deal-tabs-wrapper tabs-header" numberOfTabs={2}>
        <Tab
          active={activeOption === "my-deals"}
          key={0}
          onClick={() => setActiveOption("my-deals")}
          className={`tab-button ${
            activeOption === "my-deals" ? "active" : ""
          }`}
        >
          {activeOption === "my-deals" ? (
            <GradientText className="active-tab-content">My Deals</GradientText>
          ) : (
            "My Deals"
          )}
        </Tab>
        <Tab
          active={activeOption === "team-deals"}
          key={1}
          onClick={() => setActiveOption("team-deals")}
          className={`tab-button ${
            activeOption === "team-deals" ? "active" : ""
          }`}
        >
          {activeOption === "team-deals" ? (
            <GradientText className="active-tab-content">
              Team Deals
            </GradientText>
          ) : (
            "Team Deals"
          )}
        </Tab>
      </TabsWrapper>
      <DealsWrapper activeOption={activeOption}>
        <FiltersContainer isOpen={filtersOpen}>
          <FiltersHeader onClick={toggleFilters} isOpen={filtersOpen}>
            <span>Filters</span>
            <Arrow isOpen={filtersOpen} />
          </FiltersHeader>
          <FiltersContent isOpen={filtersOpen}>
            <NewDropdown
              selectedValue={selectedDeal?.name || "Deals"}
              onSelect={(value) => handleUpdateDeal(value)}
            >
              {selectedDeal && <DropdownItem value={""}>All</DropdownItem>}
              {quickDeals?.map((deal: QuickDeal, index: number) =>
                selectedUser ? (
                  deal.user_id === selectedUser._id && (
                    <DropdownItem key={index} value={deal.id}>
                      {deal.name}
                    </DropdownItem>
                  )
                ) : (
                  <DropdownItem key={index} value={deal.id}>
                    {deal.name}
                  </DropdownItem>
                )
              )}
            </NewDropdown>
            <NewDropdown
              selectedValue={
                selectedUser
                  ? selectedUser?.first_name + " " + selectedUser?.last_name
                  : "Users"
              }
              onSelect={(value) => handleUpdateUser(value)}
              className={`${activeOption === "my-deals" ? "disabled" : ""}`}
            >
              {selectedUser && <DropdownItem value={""}>All</DropdownItem>}
              {users?.map((user: UserData, index: number) => (
                <DropdownItem key={index} value={user._id}>
                  {user.first_name} {user.last_name}
                </DropdownItem>
              ))}
            </NewDropdown>
            <NewDropdown
              selectedValue={selectedStage?.label || "Stages"}
              onSelect={(value) => handleUpdateStage(value)}
            >
              {selectedStage && <DropdownItem value={""}>All</DropdownItem>}
              {stages?.map((stage: Stage, index: number) => (
                <DropdownItem key={index} value={stage.value}>
                  {stage.label}
                </DropdownItem>
              ))}
            </NewDropdown>
            <p>Sort by:</p>
            <NewDropdown
              selectedValue={sortBy}
              onSelect={(value) => setSortBy(value)}
            >
              {sortByOptions.map((option, index) => (
                <DropdownItem key={index} value={option.label}>
                  {option.label}
                </DropdownItem>
              ))}
            </NewDropdown>
            <p onClick={toggleSortDirection} className="sort-direction">
              {direction ? "Descending" : "Ascending"}
            </p>
            <button
              onClick={toggleSortDirection}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                marginLeft: "8px",
                fontSize: "16px",
                color: colors.mainGreen,
              }}
              aria-label="Toggle sort direction"
            >
              {direction ? "↓" : "↑"}
            </button>
          </FiltersContent>
        </FiltersContainer>
        <DealsTable>
          <DealsHeader>
            {tableHeaders.map((header: string, index: number) => (
              <div
                key={index}
                className={`title-wrapper ${header
                  .toLowerCase()
                  .replace(/\s+/g, "-")} cell`}
              >
                <p>{header}</p>
              </div>
            ))}
            <div className={`title-wrapper spacer`}>
              <p></p>
            </div>
          </DealsHeader>
          <div className="deals-table-wrapper">
            {data ? (
              data?.map((deal: DealDetails, index: number) => {
                return (
                  <>
                    <Deal
                      onClick={(
                        e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
                      ) => {
                        const target = e.target as Element;
                        if (
                          // !target.closest(".priority-dropdown") &&
                          // !target.closest(".stage-select") &&
                          // !target.closest(".name") &&
                          !target.closest(".delete-deal") &&
                          !target.closest(".spin-wrapper")
                        ) {
                          handleDealDetailsNavigation(deal?._id);
                        }
                      }}
                      key={index}
                      className={deal.processing ? "processing" : ""}
                    >
                      <div className="score cell">
                        <TableScoreBox
                          score={
                            deal?.deal_predictions?.overall_win_probability
                              ? deal?.deal_predictions
                                  ?.overall_win_probability / 10
                              : deal?.overall_deal_score / 10
                          }
                        >
                          {Math.round(
                            deal?.deal_predictions?.overall_win_probability ||
                              deal?.overall_deal_score
                          )}
                          %
                        </TableScoreBox>
                      </div>
                      <div className="name cell">{deal.deal_name}</div>
                      {activeOption === "team-deals" && (
                        <div className="lead cell">
                          <div className="pfp">
                            {user.image_url &&
                            deal.user_email === user.email ? (
                              <img src={user.image_url} />
                            ) : user.first_name &&
                              deal.user_id === user.email ? (
                              <div className="letter-wrapper">
                                {user.first_name[0].toUpperCase()}
                                {user.last_name[0].toUpperCase()}
                              </div>
                            ) : (
                              <div className="letter-wrapper">
                                {deal.user_first_name[0].toUpperCase()}
                                {deal.user_last_name[0].toUpperCase()}
                              </div>
                            )}
                            <div className="text">
                              <p>
                                {`${deal.user_first_name} ${deal.user_last_name}`}
                              </p>
                              <p className="role">{user.role && user.role}</p>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="last-call cell">
                        {moment(deal?.most_recent_call?.call_date).format(
                          "MMM DD, YYYY"
                        )}
                      </div>
                      <div className="stage-select cell">
                        {dealStages[deal._id] || deal.deal_stage}
                      </div>
                      {crmEnabled && (
                        <div className="value cell">
                          {deal.opportunity?.amount ? (
                            `$${deal.opportunity?.amount.toLocaleString()}`
                          ) : (
                            <div
                              style={{
                                width: "70px",
                                height: "2px",
                                backgroundColor: colors.mainGreen,
                              }}
                            />
                          )}
                        </div>
                      )}
                      {crmEnabled && (
                        <div className="close-date cell">
                          {deal.opportunity?.close_date ? (
                            moment(deal.opportunity?.close_date).format(
                              "MMM DD, YYYY"
                            )
                          ) : (
                            <div
                              style={{
                                width: "100px",
                                height: "2px",
                                backgroundColor: colors.mainGreen,
                              }}
                            />
                          )}
                        </div>
                      )}
                      <div className="scores cell">
                        <div className="scores-wrapper">
                          {deal.highest_scores.length > 0
                            ? deal.highest_scores.map(
                                (category: Category, index: number) => {
                                  return (
                                    <ScoreBox
                                      ref={containerRef}
                                      key={index}
                                      score={category.highest_score}
                                    >
                                      <div
                                        ref={tipRef}
                                        className="floating-advice"
                                        style={{
                                          minHeight: "auto",
                                          paddingBottom: "20px",
                                        }}
                                      >
                                        <div className="triangle"></div>
                                        <h3>
                                          <strong>
                                            {category.category_name}
                                          </strong>
                                        </h3>
                                        <p>
                                          {category.feedback &&
                                            category.feedback.map(
                                              (feedback: {
                                                feedback_text: string;
                                              }) => (
                                                <p>
                                                  • {feedback.feedback_text}
                                                </p>
                                              )
                                            )}
                                        </p>
                                      </div>
                                      {category.category_name[0]}
                                    </ScoreBox>
                                  );
                                }
                              )
                            : defaultScores.map(
                                (category: CategoryDefault, index: number) => (
                                  <ScoreBox
                                    key={index}
                                    score={category.highest_score}
                                  >
                                    {category.category_name[0]}
                                  </ScoreBox>
                                )
                              )}
                        </div>
                      </div>
                      {/* <td>
                        <PriorityDropdown
                          initialPriority={deal.priority || "Medium"}
                          onPriorityChange={(newPriority) =>
                            handlePriorityChange(deal._id, newPriority)
                          }
                        />
                      </td> */}
                      {/* <td>
                        <GreenButton
                          className={`${
                            !user?.completed_intro_tour && "deal-details"
                          }`}
                          width={"40px"}
                          height={36}
                          fontSize={12}
                          onClick={() => handleDealDetailsNavigation(deal?._id)}
                        >
                          ➜
                        </GreenButton>
                      </td> */}
                      {deal.processing ? (
                        <div
                          className="spin-wrapper cell"
                          style={{ pointerEvents: "auto" }}
                          onClick={(e) => deleteDealChoice(e, deal._id)}
                        >
                          <Refresh />
                        </div>
                      ) : (
                        <div
                          className="delete-deal cell"
                          onClick={(e) => deleteDealChoice(e, deal._id)}
                        >
                          <Trash />
                        </div>
                      )}
                    </Deal>
                  </>
                );
              })
            ) : (
              <DealsLoadWrapper></DealsLoadWrapper>
            )}
          </div>
        </DealsTable>
      </DealsWrapper>
      <Paginate
        pageValue={10}
        pages={totalPages}
        totalRecords={totalDeals}
        activePage={activePage}
        setActivePage={setActivePage}
      />
    </PageWrapper>
  );
};

export default DealsPage;
