import React, { FC, useState, useEffect } from "react";
import {
  LegendWrapper,
  SparkleWrapper,
  LegendTitle,
  LegendSkeletonLoader,
  SearchContainer,
  SearchInput,
  LoaderContainer,
  LoaderBar,
  LoaderText,
  SuggestionBubbles,
  Bubble,
  AiResponseContainer,
} from "../MeddicSpiderChart/MeddicSpiderChart.styled";
import Sparkle from "static/svgs/Sparkle";
import LeadoffRobot from "static/svgs/LeadoffRobot";
import { postAMAStatic, postRadarInsights } from "api/helpers";
import { BackButton, CopyButton } from "./InisghtsAMA.styled";
import { ArrowLeft } from "lucide-react";

interface InsightsAMAProps {
  loading: boolean;
  title: string;
  filter1: {
    deal_id: string | null;
    rep: string | null;
    stage: string | null;
    team_id: string | null;
    name: string;
  };
  filter2: {
    deal_id: string | null;
    rep: string | null;
    stage: string | null;
    team_id: string | null;
    name: string;
  };
  bubbleQueries: string[];
  prompt_type: string;
}

interface Insight {
  category: string;
  reason: string;
}

const LOADING_MESSAGES = [
  "Analyzing data patterns",
  "Processing pipelines",
  "Generating insights",
  "Crunching numbers",
  "Comparing performance metrics",
  "Evaluating trends",
  "Calculating correlations",
  "Examining deal history",
  "Synthesizing information",
  "Building recommendations",
];

const InsightsAMA: FC<InsightsAMAProps> = ({
  loading,
  title,
  filter1,
  filter2,
  bubbleQueries,
  prompt_type,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [aiResponse, setAiResponse] = useState("");
  const [aiLoading, setAiLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (aiLoading) {
      const messageInterval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % 8);
      }, 20000);

      return () => clearInterval(messageInterval);
    }
  }, [aiLoading]);

  useEffect(() => {
    setSearchQuery("");
    setAiResponse("");
  }, [
    filter1.deal_id,
    filter1.rep,
    filter1.stage,
    filter1.team_id,
    filter2.deal_id,
    filter2.rep,
    filter2.stage,
    filter2.team_id,
  ]);

  const getFourMessages = () => {
    return [
      LOADING_MESSAGES[currentIndex % 8],
      LOADING_MESSAGES[(currentIndex + 1) % 8],
      LOADING_MESSAGES[(currentIndex + 2) % 8],
      LOADING_MESSAGES[(currentIndex + 3) % 8],
    ];
  };

  const handleSearch = async (manualQuery?: string) => {
    const trimmedQuery = (manualQuery || searchQuery).trim();
    if (!trimmedQuery) {
      setAiResponse("Please enter a question before searching.");
      return;
    }

    setAiResponse("");
    setAiLoading(true);
    try {
      const searchData = {
        prompt_type,
        query: trimmedQuery,
        filter1,
        filter2,
      };
      const data = await postAMAStatic(searchData);
      // Format the response with proper HTML
      const formattedMessage = data.message
        .split(" - ")
        .map((section: string, index: number) => {
          if (index === 0) return section;
          return `<br/><br/>• ${section.trim()}`;
        })
        .join("")
        .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
      // Remove html tags if they exist
      const cleanedMessage = formattedMessage.replace(/^```html\n|\n```$/g, "");
      setAiResponse(cleanedMessage);
    } catch (error) {
      console.error("Search failed:", error);
      setAiResponse("Sorry, there was an error processing your request.");
    } finally {
      setAiLoading(false);
    }
  };

  return (
    <LegendWrapper>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          marginBottom: "20px",
        }}
      >
        <SparkleWrapper>
          <Sparkle />
        </SparkleWrapper>
        <LegendTitle>{title}</LegendTitle>
      </div>
      {loading ? (
        <>
          <LegendSkeletonLoader />
          <LegendSkeletonLoader />
          <LegendSkeletonLoader />
          <LegendSkeletonLoader />
          <LegendSkeletonLoader />
        </>
      ) : (
        <>
          <SearchContainer>
            <SearchInput
              placeholder="Ask anything..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSearch();
                }
              }}
              aria-label="Search comparison queries"
            />
            {!aiResponse &&
              (aiLoading ? (
                <LoaderContainer>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        justifyContent: "center",
                      }}
                    >
                      <LoaderBar />
                      <LoaderBar />
                      <LoaderBar />
                    </div>
                    <LoaderText>
                      <div className="text-cube">
                        <div className="text-item front">
                          {getFourMessages()[0]}
                        </div>
                        <div className="text-item bottom">
                          {getFourMessages()[1]}
                        </div>
                        <div className="text-item back">
                          {getFourMessages()[2]}
                        </div>
                        <div className="text-item top">
                          {getFourMessages()[3]}
                        </div>
                      </div>
                    </LoaderText>
                  </div>
                </LoaderContainer>
              ) : (
                <SuggestionBubbles>
                  {bubbleQueries.map((query, index) => {
                    // Replace any template variables in the query string
                    const formattedQuery = query
                      .replace(/\$\{filter1\.name\}/g, filter1.name) // Move more specific replacement first
                      .replace(/\$\{filter2\.name\}/g, filter2.name) // Move more specific replacement first
                      .replace(/\$\{filter1\}/g, JSON.stringify(filter1))
                      .replace(/\$\{filter2\}/g, JSON.stringify(filter2));

                    return (
                      <Bubble
                        key={index}
                        onClick={() => {
                          setSearchQuery(formattedQuery);
                          handleSearch(formattedQuery);
                        }}
                      >
                        {formattedQuery}
                      </Bubble>
                    );
                  })}
                  <div className="svg-container">
                    <LeadoffRobot />
                  </div>
                </SuggestionBubbles>
              ))}
          </SearchContainer>
        </>
      )}
      {aiResponse && (
        <div style={{ position: "relative" }}>
          <BackButton onClick={() => setAiResponse("")}>
            <ArrowLeft />
          </BackButton>
          <AiResponseContainer>
            <div style={{ position: "relative" }}>
              <CopyButton
                onClick={() => navigator.clipboard.writeText(aiResponse)}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 9H11C9.89543 9 9 9.89543 9 11V20C9 21.1046 9.89543 22 11 22H20C21.1046 22 22 21.1046 22 20V11C22 9.89543 21.1046 9 20 9Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M5 15H4C3.46957 15 2.96086 14.7893 2.58579 14.4142C2.21071 14.0391 2 13.5304 2 13V4C2 3.46957 2.21071 2.96086 2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H13C13.5304 2 14.0391 2.21071 14.4142 2.58579C14.7893 2.96086 15 3.46957 15 4V5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </CopyButton>
              <div dangerouslySetInnerHTML={{ __html: aiResponse }} />
            </div>
          </AiResponseContainer>
        </div>
      )}
    </LegendWrapper>
  );
};

export default InsightsAMA;
