import React from "react";

const HeroBackground = () => (
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 600">
        <defs>
          <linearGradient id="bg-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stop-color="#1f2937" />
            <stop offset="100%" stop-color="#1f2937" />
          </linearGradient>

          <radialGradient
            id="particle-glow"
            cx="50%"
            cy="50%"
            r="50%"
            fx="50%"
            fy="50%"
          >
            <stop offset="0%" stop-color="#64FFDA" stop-opacity="0.8" />
            <stop offset="100%" stop-color="#64FFDA" stop-opacity="0" />
          </radialGradient>

          <linearGradient
            id="connection-gradient"
            x1="0%"
            y1="0%"
            x2="100%"
            y2="0%"
          >
            <stop offset="0%" stop-color="#64FFDA" stop-opacity="0.1" />
            <stop offset="50%" stop-color="#64FFDA" stop-opacity="0.3" />
            <stop offset="100%" stop-color="#64FFDA" stop-opacity="0.1" />
          </linearGradient>
        </defs>
        <rect width="1200" height="600" fill="url(#bg-gradient)" />
        <path
          d="M0 150 L1200 350"
          stroke="#64FFDA"
          stroke-width="0.5"
          stroke-opacity="0.2"
        />
        <path
          d="M0 300 L1200 500"
          stroke="#64FFDA"
          stroke-width="0.5"
          stroke-opacity="0.2"
        />
        <path
          d="M0 450 L1200 250"
          stroke="#64FFDA"
          stroke-width="0.5"
          stroke-opacity="0.2"
        />
        <path
          d="M200 0 L300 600"
          stroke="#64FFDA"
          stroke-width="0.5"
          stroke-opacity="0.1"
        />
        <path
          d="M500 0 L400 600"
          stroke="#64FFDA"
          stroke-width="0.5"
          stroke-opacity="0.1"
        />
        <path
          d="M700 0 L800 600"
          stroke="#64FFDA"
          stroke-width="0.5"
          stroke-opacity="0.1"
        />
        <path
          d="M1000 0 L900 600"
          stroke="#64FFDA"
          stroke-width="0.5"
          stroke-opacity="0.1"
        />
        <circle cx="300" cy="150" r="4" fill="#64FFDA" opacity="0.7" />
        <circle
          cx="300"
          cy="150"
          r="12"
          fill="url(#particle-glow)"
          opacity="0.5"
        />
        <circle cx="500" cy="250" r="4" fill="#64FFDA" opacity="0.7" />
        <circle
          cx="500"
          cy="250"
          r="12"
          fill="url(#particle-glow)"
          opacity="0.5"
        />
        <circle cx="700" cy="200" r="4" fill="#64FFDA" opacity="0.7" />
        <circle
          cx="700"
          cy="200"
          r="12"
          fill="url(#particle-glow)"
          opacity="0.5"
        />
        <circle cx="900" cy="300" r="4" fill="#64FFDA" opacity="0.7" />
        <circle
          cx="900"
          cy="300"
          r="12"
          fill="url(#particle-glow)"
          opacity="0.5"
        />
        <circle cx="400" cy="400" r="4" fill="#64FFDA" opacity="0.7" />
        <circle
          cx="400"
          cy="400"
          r="12"
          fill="url(#particle-glow)"
          opacity="0.5"
        />
        <circle cx="800" cy="450" r="4" fill="#64FFDA" opacity="0.7" />
        <circle
          cx="800"
          cy="450"
          r="12"
          fill="url(#particle-glow)"
          opacity="0.5"
        />
        <line
          x1="300"
          y1="150"
          x2="500"
          y2="250"
          stroke="url(#connection-gradient)"
          stroke-width="2"
        />
        <line
          x1="500"
          y1="250"
          x2="700"
          y2="200"
          stroke="url(#connection-gradient)"
          stroke-width="2"
        />
        <line
          x1="700"
          y1="200"
          x2="900"
          y2="300"
          stroke="url(#connection-gradient)"
          stroke-width="2"
        />
        <line
          x1="900"
          y1="300"
          x2="800"
          y2="450"
          stroke="url(#connection-gradient)"
          stroke-width="2"
        />
        <line
          x1="800"
          y1="450"
          x2="400"
          y2="400"
          stroke="url(#connection-gradient)"
          stroke-width="2"
        />
        <line
          x1="400"
          y1="400"
          x2="300"
          y2="150"
          stroke="url(#connection-gradient)"
          stroke-width="2"
        />
        <polygon
          points="100,100 120,85 140,100 120,115"
          fill="#64FFDA"
          fill-opacity="0.2"
        />
        <polygon
          points="1000,200 1020,185 1040,200 1020,215"
          fill="#64FFDA"
          fill-opacity="0.2"
        />
        <polygon
          points="200,500 220,485 240,500 220,515"
          fill="#64FFDA"
          fill-opacity="0.2"
        />
        <polygon
          points="900,100 920,85 940,100 920,115"
          fill="#64FFDA"
          fill-opacity="0.2"
        />
        <path
          d="M50 350 L150 350 L150 400 L250 400 L250 350 L350 350"
          fill="none"
          stroke="#64FFDA"
          stroke-width="1"
          stroke-opacity="0.2"
        />
        <path
          d="M850 150 L950 150 L950 200 L1050 200 L1050 150 L1150 150"
          fill="none"
          stroke="#64FFDA"
          stroke-width="1"
          stroke-opacity="0.2"
        />
        <polygon
          points="600,100 650,75 700,100 700,150 650,175 600,150"
          fill="none"
          stroke="#64FFDA"
          stroke-width="1"
          stroke-opacity="0.3"
        />
      </svg>
);

export default HeroBackground;
