import { MarketingGreenButtonWrapper } from "components/MarketingHomepage/MarketingHomepage.styled";
import React, { useEffect, useRef, useState } from "react";
import {
  ToggleSwitchWrapper,
  DropdownList,
  DropdownContainer,
  DropdownButton,
} from "shared/shared_styled_comps/components.styled";
import {
  ButtonContainer,
  OutlinedMarketingButton,
} from "shared/shared_styled_comps/components.styled";
import { ReactNode } from "react";

interface ToggleSwitchProps {
  checked: boolean;
  onChange: () => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked,
  onChange,
}) => {
  return (
    <ToggleSwitchWrapper onClick={onChange}>
      <div className={`switch ${checked ? "on" : "off"}`} />
    </ToggleSwitchWrapper>
  );
};

interface TwoButtonProps {
  leftLink?: string;
  rightLink?: string;
  leftText?: string;
  rightText?: string;
  leftAlign?: boolean;
}

export const TwoButtonDiv: React.FC<TwoButtonProps> = ({
  leftLink = "",
  rightLink = "",
  leftText,
  rightText,
  leftAlign,
}) => {
  return (
    <ButtonContainer
      style={{ justifyContent: leftAlign ? "flex-start" : "center" }}
    >
      {leftText && (
        <MarketingGreenButtonWrapper
          onClick={() => {
            window.location.assign(leftLink);
          }}
          width={250}
        >
          {leftText}
        </MarketingGreenButtonWrapper>
      )}
      {rightText && (
        <MarketingGreenButtonWrapper
          onClick={() => {
            window.location.assign(rightLink);
          }}
          width={250}
        >
          {rightText}
        </MarketingGreenButtonWrapper>
      )}
    </ButtonContainer>
  );
};

interface DropdownProps {
  children: ReactNode;
  selectedValue: ReactNode;
  onSelect?: (value: any) => void;
  className?: string;
}

interface DropdownItemProps {
  value: any;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  children?: ReactNode;
}

export const NewDropdown: React.FC<DropdownProps> = ({
  children,
  selectedValue,
  onSelect,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsOpen(false);
    }
    if (event.key === "Enter" || event.key === " ") {
      setIsOpen(!isOpen);
    }
  };

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent the click from bubbling up
    setIsOpen(false);
    onSelect?.(selectedValue);
  };

  return (
    <DropdownContainer ref={dropdownRef} className={className}>
      <DropdownButton
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={handleKeyDown}
        aria-expanded={isOpen}
        aria-haspopup="listbox"
      >
        <span>{selectedValue}</span>
      </DropdownButton>

      <DropdownList
        className="dropdown-list"
        isOpen={isOpen}
        role="listbox"
        aria-label="Options"
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement<DropdownItemProps>(child)) {
            return React.cloneElement(child, {
              onClick: (e: React.MouseEvent<HTMLElement>) => {
                e.stopPropagation();
                if (child.props?.value !== undefined && onSelect) {
                  onSelect(child.props.value);
                }
                setIsOpen(false);
              },
            });
          }
          return child;
        })}
      </DropdownList>
    </DropdownContainer>
  );
};
