import { MarketingGreenButtonWrapper } from "components/MarketingHomepage/MarketingHomepage.styled";
import React, { useEffect } from "react";
declare global {
  interface Window {
    hbspt: {
      forms: {
        create: (config: {
          region?: string;
          portalId: string;
          formId: string;
          target: string;
          cssRequired?: string;
        }) => void;
      };
    };
  }
}
const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";

    script.onload = () => {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "46304418",
        formId: "a9e695f4-b3b9-4996-b229-7a54b6e4de32",
        target: "#hubspotForm",
        cssRequired: `
          form.hs-form {
            display: flex;
            flex-wrap: wrap;
            gap: 24px;
          }
          
          .hs-form-field {
            flex: 0 0 calc(50% - 12px);
            margin: 0;
          }
          
          .hs-input {
            width: 100% !important;
            padding: 12px !important;
            border: 1px solid rgba(255, 255, 255, 0.2) !important;
            border-radius: 4px !important;
            background: white !important;
            color: black !important;
          }
          
          .hs-input:focus {
            outline: none;
            border-color: rgba(255, 255, 255, 0.5) !important;
          }
          
          .hs-label-wrapper {
            margin-bottom: 8px;
          }
          
          label {
            color: #FFFFFF !important;
            font-size: 16px;
          }
          
          .hs_submit {
            flex: 0 0 100%;
          }

          .hs-submit {
          width: 200px;
          max-width: 200px;
          margin-top: 0;
          }

          .actions { 
          margin-top: 0;
          }
          
          .hs-button {
            background: #FFFFFF;
            color: #1A1E23;
            font-weight: 600;
            cursor: pointer;
            border: none;
            padding: 16px 32px;
            border-radius: 4px;
          }
          
          .hs-button:hover {
            opacity: 0.9;
          }
        `,
      });
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div id="hubspotForm"></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          flexWrap: "nowrap", // Ensures items stay in one row
          width: "100%",
          justifyContent: "flex-start",
        }}
      >
        <h4
          style={{
            margin: 0,
            whiteSpace: "nowrap", // Prevents text from wrapping
          }}
        >
          Want to learn more?
        </h4>
        <MarketingGreenButtonWrapper
          width={150}
          onClick={() => {
            window.location.assign("https://meetings.hubspot.com/jeff1418");
          }}
        >
          Book a demo
        </MarketingGreenButtonWrapper>
      </div>
    </>
  );
};

export default HubSpotForm;
