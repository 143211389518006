import { useEffect, useState, FC } from "react";
import { InsightsWrapper } from "./Insights.styled";
import PageWrapper from "components/reusable/PageWrapper/PageWrapper";
import ScoreChart from "components/ScoreChart/ScoreChart";
import MeddicSpiderChart from "components/MeddicSpiderChart/MeddicSpiderChart";
import StageTimingChart from "components/StageTimingChart/StageTimingChart";
import WinningBehaviorsChart from "components/WinningBehaviorsChart/WinningBehaviorsChart";
import { GradientText } from "shared/shared_styled_comps/components.styled";
interface InsightsProps {}

const Insights: FC<InsightsProps> = () => {
  const [activeTab, setActiveTab] = useState<number>(0);

  useEffect(() => {
    const tabsHeader = document.querySelector(".tabs-header") as HTMLElement;
    if (tabsHeader) {
      tabsHeader.style.setProperty("--active-tab-index", activeTab.toString());
    }
  }, [activeTab]);

  const tabs = [
    {
      label: "MEDDIC Analysis",
      content: (
        <>
          <MeddicSpiderChart />
          <ScoreChart />
        </>
      ),
    },
    { label: "Winning Behaviors", content: <WinningBehaviorsChart /> },
    { label: "Pipeline Trends", content: <StageTimingChart /> },
  ];

  return (
    <PageWrapper pageTitle="Insights">
      <InsightsWrapper>
        <div className="tabs-header">
          {tabs.map((tab, index) => (
            <button
              key={index}
              className={`tab-button ${activeTab === index ? "active" : ""}`}
              onClick={() => setActiveTab(index)}
            >
              {activeTab === index ? (
                <GradientText className="active-tab-content">
                  {tab.label}
                </GradientText>
              ) : (
                tab.label
              )}
            </button>
          ))}
        </div>
        <div className="tab-content">
          {tabs.map((tab, index) => (
            <div
              key={index}
              style={{ display: activeTab === index ? "block" : "none" }}
            >
              {tab.content}
            </div>
          ))}
        </div>
      </InsightsWrapper>
    </PageWrapper>
  );
};

export default Insights;
