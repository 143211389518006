import styled from "styled-components";
import { colors } from "colors";

export const TabsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  hr {
    width: 26px;
    transform: rotate(90deg);
    border: none;
    height: 1px;
    background-color: ${colors.grayWhite};
    margin: 0px -6px;
  }
  button {
    height: 35px;
    padding: 6.65px 13.31px 6.65px 13.31px;
    border-radius: 5px;
    gap: 6.65px;
    background-color: ${colors.darkGray2};
    border: none;
    box-shadow: ${colors.boxShadow2};
    color: ${colors.white2};
    &:hover {
      background-color: ${colors.white};
      color: ${colors.black};
    }
    &.selected {
      background-color: ${colors.white};
      color: ${colors.black};
    }
  }
`;

export const InnerSettingsWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  border-radius: 10px;
  border: 1px solid #e4e7e5;
`;

export const SettingWrapper = styled.div`
  width: 100%;
  height: auto;
  min-height: 700px;
  background-color: white;
  padding-top: 40px;
  box-shadow: ${colors.goodBoxShadow};
  border-radius: 5px;
  color: ${colors.goodBlack};
  .profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    h3 {
      margin-left: 30px;
    }
    button {
      background-color: ${colors.lightGreen};
      color: ${colors.mainGreen};
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      margin-right: 30px;
    }
  }
  h3 {
    margin-left: 30px;
    margin-bottom: 20px;
  }
  hr {
    width: 100%;
    height: 1px;
    border: none;
    background-color: ${colors.middleGray};
    margin-bottom: 50px;
  }
  .integrations-card-wrapper {
    padding-left: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    h3 {
      margin-left: 0;
    }
    .inner-wrapper {
      margin-right: 20px;
      margin-bottom: 25px;
    }
  }
  .profile-info-wrapper {
    padding: 0 40px;
    position: relative;
  }
  .pfp {
    width: 145px;
    height: 145px;
    min-width: 145px;
    min-height: 145px;
    border-radius: 100%;
    margin-left: 2px;
    cursor: pointer;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }
  .profile-pic {
    input {
      display: none;
    }
    label {
      background-color: ${colors.darkGray};
      position: absolute;
      bottom: -30px;
      cursor: pointer;
      left: 50%;
      transform: translate(-50%, -50%);
      border: none;
      color: ${colors.white};
      width: 100px;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        path {
          fill: ${colors.white};
        }
      }
    }
  }
  .dual-input-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    max-width: 900px;
    .small-input {
      width: 400px;
    }
  }
  .disabled {
    position: relative;
    z-index: 10;
    pointer-events: none;
    margin-bottom: 25px;
    h4 {
      margin-bottom: -15px;
      max-width: 550px;
      margin-top: -10px;
      font-size: 17px;
    }
  }
  .edit-wrapper {
    margin-top: 40px;
    position: relative;
  }
  .display-wrapper {
    margin-top: 40px;
    position: relative;
  }
  .letter-wrapper {
    width: 145px;
    height: 145px;
    min-width: 145px;
    min-height: 145px;
    border-radius: 100%;
    background-color: ${colors.mainGreen};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 500;
  }
`;

interface IntegrationcardProps {
  enabled: boolean | undefined | string;
}

export const IntegrationCard = styled.div<IntegrationcardProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 550px;
  height: 173px;
  margin-bottom: 30px;
  padding: 30px 50px;
  border-radius: 12px;
  position: relative;
  z-index: 10;
  box-shadow: ${colors.goodBoxShadow};

  p {
    color: ${colors.goodBlack};
    margin-top: 0px;
    margin-bottom: 5px;
  }
  ${(props) =>
    props.enabled &&
    `
    background-color: ${colors.goodBlack};
    p {
      color: white;
    }
  `}
  svg {
    height: 152px;
    width: 190px;
    margin-right: 20px;
    .rect {
      fill: white;
    }
  }
  .text-wrapper {
    width: 610px;
    margin-right: 20px;
  }
  .disable {
    text-decoration: underline;
    font-size: 12px;
    margin: 0 auto;
    width: fit-content;
    margin-top: 15px;
    cursor: pointer;
  }
  .overlay {
    position: absolute;
    top: 0;
    border-radius: 5px;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(184, 181, 181, 0.64); /* Semi-transparent gray */
    pointer-events: none; /* Prevent clicking */
    z-index: 1000;
  }
  button {
    color: ${colors.mainGreen};
    padding: 12px 18px;
    background: ${colors.lightGreen};
    border-radius: 5px;
    border: none;
    ${(props) =>
      !props.enabled
        ? `
        &:hover {
          background: ${colors.white};
          color: ${colors.black};
        }
  `
        : `
  background-color: ${colors.darkGray};
  position: absolute;
  border: none;
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 5px;
    padding: 1px; /* control the border thickness */
    background: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
  `}
  }
`;

export const OrganizationWrapper = styled.div`
  width: 100%;
  background-color: white;
  border-radius: 5px;
  box-shadow: ${colors.goodBoxShadow};
  color: ${colors.goodBlack};
  padding: 20px;
  .manage-teams {
    width: 132px;
    height: 36px;
    margin-left: 30px;
    padding: 6px 12px 6px 12px;
    gap: 6px;
    border: 1px solid ${colors.white};
    color: ${colors.white};
    border-radius: 5px;
    background-color: ${colors.middleGray};
    &:hover {
      color: ${colors.black};
      background-color: ${colors.white};
    }
  }
  .method-select-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 25px;
    select {
      margin-left: 10px;
    }
  }
  .user-table-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px auto;
  }
  .invite {
    width: 132px;
    height: 36px;
    padding: 6px 12px 6px 12px;
    gap: 6px;
    color: ${colors.mainGreen};
    border-radius: 5px;
    background-color: ${colors.lightGreen};
    border: none;
    &:hover {
      color: ${colors.black};
      background-color: ${colors.white};
    }
  }
`;

interface UserRowProps {
  header?: boolean;
  isCurrentUser?: boolean;
}

export const UserRowWrapper = styled.div<UserRowProps>`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0 15px;
  &:hover {
    background-color: ${colors.lightGreen};
  }
  ${(props) =>
    props.header
      ? `margin-bottom: 0; p{margin: 5px auto;} font-size: 18px; font-weight: 700;`
      : `font-size: 16px; font-weight: 400;`}
  ${(props) => props.isCurrentUser && `background-color: ${colors.lightGreen};`}
  .close-wrapper {
    cursor: pointer;
  }
  .profile-icon {
    border-radius: 100%;
    width: 42px;
    height: 42px;
    margin-right: 20px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
    ${(props) => props.header && `background: transparent;`}
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }
  .space {
    height: 30px;
    width: 30px;
    min-height: 30px;
    min-width: 30px;
  }
  .svg-wrapper {
    width: 30px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    svg {
      height: 30px;
      width: 30px;
      min-height: 30px;
      min-width: 30px;
      path {
        stroke: ${colors.white};
      }
    }
  }
  h2 {
    font-size: 18px;
    line-height: 27px;
    font-weight: 500;
    text-align: left;
  }
  p {
    line-height: 24px;
    text-align: left;
  }
  .email {
    width: 30%;
  }
  .role {
    width: 15%;
  }
  .name {
    width: 20%;
  }
  .active {
    width: 8%;
  }
  .team {
    width: 15%;
  }
`;

export const UserTableWrapper = styled.div`
  width: 100%;
  border: 1px solid ${colors.middleGray};
  hr {
    border: none;
    height: 1px;
    background-color: ${colors.middleGray};
    width: 100%;
    margin: 5px auto;
    margin-top: 0;
    margin-bottom: 20px;
  }
`;

export const InviteModal = styled.div`
  height: 70vh;
  position: relative;
  width: 50%;
  min-height: 744px;
  margin: 0 auto;
  background-color: #34363c;
  border-radius: 5px;
  max-width: 600px;
  z-index: 1000;
  padding: 85px;
  color: white;
  padding: 30px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  h3 {
    width: 100%;
    text-align: center;
  }
  .input-wrapper {
    width: 100%;
  }
  .submit-button {
    color: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
    background: none;
    width: 90%;
    margin: 0 auto;
    height: 50px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(
      180deg,
      #7cdf4d 0%,
      #4cabca 100%
    ); /* Gradient colors */
    border-image-slice: 1; /* Ensure the gradient is applied to the entire border */
    padding: 10px 20px; /* Add padding to make the button visible */
    background-color: transparent; /* Transparent background to see the gradient border */
    color: white; /* Text color */
    font-size: 16px; /* Font size */
    cursor: pointer; /* Cursor style */
    border-radius: 5px;
    background-clip: padding-box;
    background: linear-gradient(
      180deg,
      #7cdf4d 0%,
      #4cabca 100%
    ); /* Gradient background */
    -webkit-background-clip: text; /* Clip the gradient to the text */
    -webkit-text-fill-color: transparent; /* Make the text transparent */
    color: transparent;
    &:hover {
      -webkit-background-clip: unset; /* Clip the gradient to the text */
      -webkit-text-fill-color: unset;
      color: #333;
    }
  }
`;

export const TeamsWrapper = styled.div`
  p {
    line-height: 24px;
    text-align: left;
    margin: 10px 0;
  }
  .email {
    width: 25%;
  }
  .role {
    width: 15%;
  }
  .name {
    width: 18%;
  }
  .active {
    width: 20%;
  }
  .edit {
    justify-content: flex-start;
  }
  .user-table {
    margin-bottom: 50px;
  }
`;
