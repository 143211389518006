import styled, { keyframes } from "styled-components";
import { colors, scoreToColor } from "colors";

export const DealPageWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  @media screen and (min-width: 1470px) {
    justify-content: flex-start;
  }
  .upload {
    height: 100%;
    width: 100%;
    font-size: 40px;
    text-align: center;
    .svg-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 150px;
    }
    svg {
      height: 200px;
      width: 200px;
      margin: 0 auto;
      path {
        fill: ${colors.mainGreen};
      }
    }
  }
  .left-wrapper {
    flex-wrap: nowrap;
    align-items: flex-end;
    .total-score-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
    .floating-wrapper-b {
      border-bottom: none;
      position: relative;
      margin-bottom: -1px;
      z-index: 100;
      border-radius: 3px 3px 0px 0px;
      background-color: ${colors.darkGray2};
      box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
    }
    .floating-wrapper-a {
      position: relative;
      background-color: white;
      z-index: 99;
      padding: 0;
      width: 1050px;
      border-radius: 5px;
      overflow: visible;
      box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
      border: none;
      ::-webkit-scrollbar {
        height: 14px;
        width: 14px;
        background: ${colors.middleGray};
      }

      ::-webkit-scrollbar-thumb {
        background: ${colors.black};
        -webkit-border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
      }

      ::-webkit-scrollbar-corner {
        background: #000;
      }

      ::-webkit-scrollbar-track {
        height: 100px; /* Fixed height of the scrollbar track */
      }
      .white-underline {
        font-size: 28px;
        font-weight: 700;
        margin-top: 20px;
        margin-bottom: 34px;
        position: relative;
        color: ${colors.black};
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -8px;
          width: 90px;
          height: 3px;
          background: ${colors.mainGreen};
          border-radius: 2px;
        }
      }
    }
  }
  .coach-header {
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    color: ${colors.mainGreen};
    margin-bottom: 20px;
    margin-left: 0px;
  }
  .confirm-oppty {
    width: 500px;
    height: 300px;
    background-color: ${colors.darkGray};
    border-radius: 5px;
    padding: 30px;
    text-align: center;
    h2 {
      text-align: center;
      color: ${colors.mainGreen};
    }
    button {
      margin: 10px;
      color: ${colors.darkGray};
      background-color: ${colors.mainGreen};
      border: none;
      border-radius: 5px;
      padding: 10px;
      cursor: pointer;
      font-weight: 600;
    }
  }
`;

export const Deal = styled.div`
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 0 auto;
  margin-left: 0;
  height: 93%;
`;

export const TopRow = styled.div`
  width: 100%;
`;

export const Feedback = styled.div``;

export const DealHeader = styled.div`
  width: 100%;
`;

export const CategoryWrapper = styled.div`
  position: relative;
  text-align: left;
  height: auto;
  padding: 15px 30px;
  color: ${colors.black};
  max-height: 665px; // Add a max-height to enable scrolling
  overflow-y: auto; // Enable vertical scrolling

  /* Modern scrollbar styling */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.mainGreen}40;
    border-radius: 4px;
    transition: background 0.2s ease;

    &:hover {
      background: ${colors.mainGreen}60;
    }
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${colors.mainGreen}40 transparent;
  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.black};
  }
  .title-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 18px;
    margin-top: 30px;
  }
`;

export const Category = styled.div`
  .flex-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start !important;
    align-items: flex-start;
    margin-bottom: 15px;
    padding-left: 20px;
    width: 100% !important;
    p {
      margin: 0;
    }
  }
`;

export const CheckboxLabel = styled.label`
  /* Style the label to mimic the appearance of a checkbox */
  display: inline-block;
  width: 20px;
  height: 20px;
  min-height: 20px;
  min-width: 20px;
  border-radius: 5px;
  margin-right: 15px;
  margin-top: 4px;
  background: linear-gradient(120deg, #2f2f2f 0%, #4b4d52 100%);
  cursor: pointer;
  svg {
    display: none;
  }
`;

export const CheckboxInput = styled.input`
  /* Hide the default checkbox */
  display: none;
  &:checked + ${CheckboxLabel} {
    background: none;
    transform: scale(1.2);
    svg {
      display: block;
      width: 20px;
      height: 20px;
    }
  }
`;

export const AddCallWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  backdrop-filter: blur(5px); /* Blur effect */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

export const AddCallModal = styled.div`
  height: 90vh;
  position: relative;
  width: 50%;
  margin: 0 auto;
  background-color: #34363c;
  border-radius: 5px;
  max-width: 600px;
  z-index: 1000;
`;

interface TabProps {
  active: boolean;
}

export const Tab = styled.button<TabProps>`
  padding: 12px 24px;
  border: 0;
  outline: 0;
  background: transparent;
  color: black;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease;
  position: relative;
  flex: 1;
  width: 200px;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none; // Add this
  text-shadow: none; // Add this
  border-radius: 0; // Add this
  border-style: none; // Add this explicitly
  border-width: 0; // Add this explicitly

  &::-moz-focus-inner {
    // Firefox specific fix
    border: 0;
  }

  ${(props) =>
    props.active &&
    `
    background: #1f2937;
    border: 0;  // Reinforce no border in active state
  `}

  ${(props) =>
    !props.active &&
    `
    &:hover {
      background: rgba(31, 41, 55, 0.1);
    }
  `}

  &:focus {
    outline: none;
    border: 0;
  }
`;

interface TabsWrapperProps {
  numberOfTabs: number;
}

export const TabsWrapper = styled.div<TabsWrapperProps>`
  display: flex;
  background: white;
  border-radius: 8px;
  gap: 0px;
  margin-bottom: 0px;
  width: fit-content;
  position: relative;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  border-bottom: 1px solid #e5e7eb;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background: linear-gradient(356deg, ${colors.mainGreen} 0%, #4cabca 100%);
    transition: all 0.3s ease;
    width: calc(100% / ${(props) => props.numberOfTabs});
    transform: translateX(calc(100% * var(--active-tab-index, 0)));
  }
`;

interface ScoreBoxProps {
  score: number;
}
export const FloatingBox = styled.div`
  width: 125px;
  height: 125px;
  position: relative;
  z-index: 98;
  padding: 10px;
  gap: 0px;
  border-radius: 5px;
  box-shadow: 0px 3px 7px 0px #0000001a, 0px 13px 13px 0px #00000017,
    0px 30px 18px 0px #0000000d, 0px 54px 22px 0px #00000003,
    0px 84px 24px 0px #00000000;
  p {
    margin-top: 35px;
    text-align: center;
  }
  .title-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    h4 {
      font-size: 12px;
    }
  }
  .svg-wrapper {
    width: 30px;
    height: 30px;
    min-height: 30px;
    min-width: 30px;
    background-color: ${colors.black};
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    svg {
      height: 20px;
      width: 20px;
      min-height: 20px;
      min-width: 20px;
    }
  }
`;

export const WidgetsWrapper = styled.div`
  width: 327px;
  height: 696px;
  min-width: 327px;
  padding: 20px;
  margin-right: 30px;
`;

const shimmer = keyframes`
  0% {
    background-position: -200% center;
  }
  100% {
    background-position: 200% center;
  }
`;

export const ScoreBar = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 40px;
  padding: 15px 10px;
  padding-top: 25px;
  margin: 15px 0;
  margin-top: 0px;
  padding-left: 10px;
  max-width: 100%;
  background-color: white;
  border-radius: 5px;
  border-radius: 0 0px 5px 5px;
  p {
    text-align: center;
    font-size: 12px;
  }
  .score-circle-wrapper {
    width: 100px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    p {
      width: 100%;
      color: ${colors.black};
      margin-bottom: 0px;
    }
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.03);

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          90deg,
          transparent,
          rgba(255, 255, 255, 0.3),
          transparent
        );
        background-size: 200% 100%;
        pointer-events: none;
        border-radius: inherit;
      }
    }
  }
`;

interface CircleScoreProps {
  score: number | undefined;
  index: number;
}

const scaleUpDown = keyframes`
  0%, 100% {
    transform: scale(1); /* Start and end with original scale */
  }
  50% {
    transform: scale(1.1); /* Scale to 120% in the middle */
  }
`;

const fillCircle = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const CircleScore = styled.div<CircleScoreProps>`
  position: relative;
  border-radius: 100%;
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  isolation: isolate;
  border: 2px solid ${colors.mainGreen};
  z-index: 1; // Ensure the container participates in stacking context
  &::before {
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    border-radius: 100%;
    z-index: -1;
    ${(props) => {
      if (props.score !== undefined) {
        let { background } = scoreToColor(props.score);
        return `
          background: conic-gradient(
            ${background} 0deg ${props.score * 36}deg,
            transparent ${props.score * 36}deg 360deg
          );
        `;
      }
    }}
  }
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 100%;
    background: linear-gradient(
      180deg,
      rgba(124, 223, 77, 1) 0%,
      rgba(76, 171, 202, 1) 100%
    );
    z-index: 0; // Between the ring and the text
  }
  span {
    position: relative;
    z-index: 1; // Keep text on top
  }
  animation: ${scaleUpDown} 0.6s ease-in-out forwards;
  animation-delay: ${(props) => props.index * 0.1}s;
  cursor: pointer;
  font-weight: 700;
`;

export const OpportunityWrapper = styled.div`
  position: relative;
  margin-right: 50px;
`;

export const OpportunitySearch = styled.input`
  margin-bottom: 10px;
  width: 100%;
`;

export const OpportunityList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const OpportunityListUl = styled.ul`
  max-height: 200px;
  overflow-y: auto;
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const OpportunityListItem = styled.li<{ isSelected: boolean }>`
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.isSelected ? "#e0e0e0" : "transparent"};
  border-bottom: 1px solid #eee;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const SelectedOpportunity = styled.div`
  margin-bottom: 10px;
  width: 100%;
  padding: 8px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ClearButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #888;
`;

export const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  color: ${colors.white2};
  gap: 60px;
  .notes-button-wrapper {
    position: fixed;
    right: 0;
    top: 130px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid ${colors.mainGreen};
    border-radius: 4px 0 0 4px;
    border-right: none;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }
    svg {
      width: 30px;
      height: 30px;
      margin: 0;
      path {
        fill: ${colors.mainGreen};
      }
    }
  }
  .opportunity-section {
    margin-right: 40px;
    .opportunity-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .hubspot-deal-section {
    margin-right: 40px;
  }
  .hubspot-deal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .opportunity-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  a {
    color: ${colors.mainGreen};
    text-decoration: none;
    margin-bottom: 0px;
    &:hover {
      text-decoration: underline;
    }
  }
  h2 {
    color: ${colors.offWhite};
    margin-top: 10px;
    max-width: 490px;
    width: auto;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; // Limits the text to 2 lines
    -webkit-box-orient: vertical;
    text-overflow: ellipsis; // Adds the ellipsis at the end of the text
    word-wrap: break-word;
  }
  .share {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.black};
    border-radius: 20px;
    padding: 7px 20px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }
  svg {
    margin-left: 7px;
    width: 20px;
    height: 16px;
    path {
      fill: ${colors.white2};
    }
  }
  .deal-name-wrapper {
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: absolute;
    margin-right: 30px;
    top: -90px;
    left: 0;
    overflow: hidden;
    svg {
      cursor: pointer;
      path {
        fill: ${colors.black};
      }
    }
    h2 {
      color: ${colors.goodBlack};
      padding-bottom: 10px;
      margin-top: 10px;
      max-width: 800px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      z-index: 1; // Ensure h2 is above other elements
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 3px;
        width: 90px;
        height: 3px;
        background: ${colors.mainGreen};
        border-radius: 2px;
        z-index: -1; // Ensure the underline is below the text
      }
    }

    input {
      font-size: 26px;
      color: ${colors.middleGray};
      background-color: transparent;
      border: 1px solid ${colors.offWhite};
      border-radius: 5px;
      margin-top: 10px;
    }
    .checkmark {
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${colors.mainGreen};
      border-radius: 5px;
      margin-left: 15px;
    }
  }
  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
  }
  .call-wrapper {
    width: 100%;
    margin-bottom: 40px;
    h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: -0.01em;
      text-align: left;
    }
    select {
      width: 100%;
      max-width: 100%;
    }
    p {
      margin-top: 8px;
      margin-left: 3px;
      font-size: 11px;
    }
  }
  button {
    width: 100%;
    height: 30px;
    background-color: ${colors.middleGray};
    border: none;
    color: ${colors.offWhite};
    text-align: center;
    padding: 8px 0;
    border-radius: 3px;
    cursor: pointer;
    &:hover {
      color: ${colors.black};
      background-color: ${colors.offWhite};
    }
  }
  .opportunity-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .checkmark {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colors.mainGreen};
    border-radius: 5px;
    margin-left: 15px;
    cursor: pointer;
  }
  .opportunity-name {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1; // Limits the text to 2 lines
    -webkit-box-orient: vertical;
    text-overflow: ellipsis; // Adds the ellipsis at the end of the text
    word-wrap: break-word;
  }
`;

const strikeAnimation = keyframes`
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
`;

const strike = keyframes`
  from {
    text-decoration-color: transparent;
  }
  to {
    text-decoration-color: auto;
  }
`;

export const StrikeThroughText = styled.div`
  position: relative;
  display: inline-block;
  text-decoration: line-through;
  color: black; /* Adjust color as needed */
  line-height: 24px;
  animation: ${strike} 1s forwards;

  /* &:after {
    content: '';
    position: absolute;
    bottom: 50%;
    left: 0;
    width: 100%;
    height: 2px; /* Adjust thickness as needed */
  /* background-color: black; /* Adjust color as needed */
  /* animation: ${strikeAnimation} 1s forwards;
  } */
`;

const skeletonLoading = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

export const ScoreLoader = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f5f5f5 0%, #e0e0e0 20%, #f5f5f5 40%);
  background-size: 200% 100%;
  animation: ${skeletonLoading} 1.5s ease-in-out infinite;
  background-repeat: no-repeat;
  border-radius: 8px;
`;

// Use the same animation for both loaders for consistency
export const SummaryLoader = styled.div`
  width: 100%;
  margin-top: 35px;
  margin-bottom: 55px;
  height: 600px;
  background: linear-gradient(90deg, #f5f5f5 0%, #e0e0e0 20%, #f5f5f5 40%);
  background-size: 200% 100%;
  animation: ${skeletonLoading} 1.5s ease-in-out infinite;
  background-repeat: no-repeat;
`;

export const HeaderWrapper = styled.div`
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  .salesforce-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      transform: scale(1.02);
      transition: transform 0.2s ease-in-out;
    }
    cursor: pointer;
    .rect {
      fill: none;
    }
    svg {
      height: 90px;
      width: 90px;
    }
    .click-logo {
      height: 12px;
      width: 12px;
    }
  }
  .hubspot-logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover {
      transform: scale(1.02);
      transition: transform 0.2s ease-in-out;
    }
    cursor: pointer;
    .rect {
      fill: none;
    }
    svg {
      height: 60px;
      width: 60px;
    }
    .click-logo {
      height: 12px;
      width: 12px;
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    background-color: white;
    border-radius: 5px 5px 0 0;
    padding: 15px 0;
    color: ${colors.black};
    border-bottom: 1px solid #e5e7eb;
    padding-left: 15px;
    width: 100%;
    justify-content: flex-start;
    h4 {
      margin-right: 30px;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const InfoItem = styled.div`
  width: fit-content;
  .hubspot-deal-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
`;

export const InfoLabel = styled.h5`
  color: #6b7280;
  font-size: 10px;
  font-weight: 600;
  margin: 0 0 0.5rem 0;
  text-transform: uppercase;
  letter-spacing: 0.025em;
`;

export const InfoValue = styled.p`
  color: #111827;
  font-size: 13px;
  font-weight: 500;
  margin: 0;
`;

export const DropdownButton = styled.button`
  width: 100%;
  padding: 12px 16px;
  background: #1a2233;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #2a3344;
  }

  /* Chevron icon */
  &::after {
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #ffffff;
    margin-left: 10px;
  }
`;

export const DropdownList = styled.ul<{ isOpen: boolean }>`
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  width: 100%;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  margin: 0;
  list-style: none;
  z-index: 100;

  display: ${(props) => (props.isOpen ? "block" : "none")};
`;

export const DropdownItem = styled.li`
  padding: 10px 16px;
  color: #1a2233;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #f3f4f6;
  }
`;

const shimmerAnimation = keyframes`
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(150%);
  }
`;

interface ScoreBoxProps {
  score: number;
}

export const ScoreContainer = styled.div<ScoreBoxProps>`
  position: relative;
  width: 100px;
  height: 50px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  transition: transform 0.3s ease;
  box-shadow: ${colors.goodBoxShadow};
  ${(props) => {
    let { background, text } = scoreToColor(props.score / 10);
    return `
      background: ${background};
      color: ${text};
    `;
  }};

  &:hover {
    transform: translateY(-2px);
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
      background: linear-gradient(
        75deg,
        transparent,
        rgba(255, 255, 255, 0.08),
        transparent
      );
      animation: ${shimmerAnimation} 0.7s infinite;
    }
  }
`;

export const Score = styled.div`
  font-size: 28px;
  font-weight: 700;
  color: ${colors.goodBlack};
  display: flex;
  align-items: baseline;
  z-index: 1;

  .percentage {
    font-size: 20px;
    margin-left: 2px;
    opacity: 0.8;
  }
`;

export const ScoreLabel = styled.div`
  font-size: 14px;
  color: ${colors.goodBlack};
  opacity: 0.7;
  margin-top: 4px;
  font-weight: 500;
  z-index: 1;
`;

export const ScoreBox = styled.div<ScoreBoxProps>`
  height: 25px;
  width: 25px;
  min-height: 25px;
  min-width: 25px;
  color: ${colors.white2};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: relative;
  font-weight: 700;
  border-radius: 6px;
  margin-right: 10px;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
  ${(props) => {
    let { background, text } = scoreToColor(props.score);
    return `
      background-color: ${background};
      color: ${text};
    `;
  }};
  .floating-advice {
    width: 330px;
    min-height: 286px;
    height: fit-content;
    background-color: white;
    display: none;
    position: absolute;
    top: 47px;
    left: 27%;
    transform: translate(-50%, 0);
    z-index: 100;
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 80px;
    z-index: 1000;
    box-shadow: ${colors.goodBoxShadow};
    h3 {
      position: relative;
      padding: 0;
      margin-bottom: 10px;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -8px;
        width: 90px;
        height: 3px;
        background: ${colors.mainGreen};
        border-radius: 2px;
      }
    }
    p {
      width: 100%;
      text-align: left;
      font-weight: 400;
    }
    .triangle {
      height: 38px;
      width: 20px;
      position: absolute;
      top: -13px;
      left: 50%;
      transform: translate(-50%, 0) rotate(45deg);
      background: white;
      z-index: 99;
    }
    button {
      width: 90%;
      height: 60px;
      background-color: ${colors.black};
      border: none;
    }
  }
  &:hover {
    .floating-advice {
      display: block;
    }
  }
`;
