import React, { FC, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  CommentsContainer,
  Comment,
  VoteSection,
  VoteButton,
  VoteCount,
  CommentBody,
  CommentHeader,
  Avatar,
  Username,
  Time,
  ReplyButton,
  CommentContent,
  CommentsSection,
  UserList,
  UserListItem,
  MentionSpan,
} from "./DealComments.styled";
import moment from "moment";
import { DealComment, User, Mention } from "types";
import { TextInput } from "components/DealCoachChat/DealCoachChat.styled";
import Send from "static/svgs/Send";
import {
  deleteDealComment,
  gettDealComments,
  postDealComment,
} from "api/helpers";
import { useSelector } from "react-redux";
import Trash from "static/svgs/Trash";

// Mock API function (replace with your actual API call)
const fetchComments = async (dealId: string | undefined) => {
  if (!dealId) return [];
  let response = await gettDealComments(dealId);
  return response;
};

interface DealCommentsProps {
  dealId?: string;
}

interface FetchedComments {
  comments_list: DealComment[];
}

interface CommentMap {
  [key: string]: DealComment;
}

const DealComments: FC<DealCommentsProps> = ({ dealId }) => {
  const [comments, setComments] = useState<DealComment[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [showUsersList, setShowUsersList] = useState<boolean>(false);
  const [currentParentId, setCurrentParentId] = useState<string>("");
  const [mentions, setMentions] = useState<Mention[]>([]);
  const [currentComment, setCurrentComment] = useState<string>("");
  const [mentionIndex, setMentionIndex] = useState<number>(-1);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const user: User = useSelector((state: any) => state.auth.user);
  const itemRefs = useRef<(HTMLLIElement | null)[]>([]);
  const commentRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [selectedMention, setSelectedMention] = useState(false);

  useEffect(() => {
    const loadComments = async () => {
      const fetchedComments = await fetchComments(dealId);
      const commentMap: CommentMap = {};
      const rootComments: DealComment[] = [];
      setUsers(fetchedComments?.users_list);
      setFilteredUsers(fetchedComments?.users_list);
      let comments: DealComment[] = fetchedComments?.comments_list || [];
      comments.forEach((comment: DealComment) => {
        comment.children = [];
        commentMap[comment._id] = comment;
      });
      comments.forEach((comment) => {
        if (comment.parent_id) {
          if (commentMap[comment.parent_id]) {
            commentMap[comment.parent_id].children.push(comment);
          }
        } else {
          rootComments.push(comment);
        }
      });
      setComments(rootComments);
    };
    loadComments();
  }, []);

  useEffect(() => {
    if (currentComment === "") {
      setMentions([]);
    }
    if (selectedMention) {
      setSelectedMention(false); // Reset after processing the selected mention
      return;
    }
   //  const lastAtSymbol = currentComment.lastIndexOf("@");
   //  if (lastAtSymbol >= 0) {
   //    const query = currentComment.slice(lastAtSymbol + 1);
   //    if (query.length > 0) {
   //      const matchedUsers = users.filter((user) =>
   //        (user.first_name + " " + user.last_name)
   //          .toLowerCase()
   //          .startsWith(query.toLowerCase())
   //      );
   //      setFilteredUsers(matchedUsers);
   //      setShowUsersList(true);
   //      setMentionIndex(lastAtSymbol); // Set position of the mention
   //    } else {
   //      setShowUsersList(true);
   //    }
   //  } else {
   //    setFilteredUsers(users);
   //    setShowUsersList(false);
   //  }
       // Find the last "@" symbol and get the text after it
       const lastAtSymbol = currentComment.lastIndexOf('@');
       const mentionText = currentComment.slice(lastAtSymbol + 1).toLowerCase();
   
       // Filter the users based on the text after the "@" symbol
       if (lastAtSymbol !== -1) {
         const filtered = users.filter((user) =>
           `${user.first_name.toLowerCase()} ${user.last_name.toLowerCase()}`.includes(mentionText)
         );
         setFilteredUsers(filtered);
         setShowUsersList(true);
         setMentionIndex(lastAtSymbol);
       } else {
         // Close the list if "@" is removed or there's no matching text
         setFilteredUsers(users);
         setShowUsersList(false);
       }
  }, [currentComment]);

  const handleUserClick = (user: User) => {
    const lastAtSymbol = currentComment.lastIndexOf("@");
    const beforeMention = currentComment.slice(0, lastAtSymbol); // Include the "@" symbol and text before it
    const updatedComment = `${beforeMention}@${
      user?.first_name + " " + user?.last_name
    }`;
    setMentions([
      ...mentions,
      {
        id: user?.user_id,
        name: user?.first_name + " " + user?.last_name,
        index: mentionIndex,
      },
    ]);
    setSelectedMention(true);
    setShowUsersList(false);
    setFilteredUsers(users);
    setMentionIndex(0);
    setCurrentComment(updatedComment);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (showUsersList) {
      if (event.key === "ArrowDown") {
        event.preventDefault();
        setHighlightedIndex(
          (prevIndex) => (prevIndex + 1) % filteredUsers.length
        );
      } else if (event.key === "ArrowUp") {
        event.preventDefault();
        setHighlightedIndex(
          (prevIndex) =>
            (prevIndex - 1 + filteredUsers.length) % filteredUsers.length
        );
      } else if (event.key === "Enter" && filteredUsers.length > 0) {
        event.preventDefault();
        handleUserClick(filteredUsers[highlightedIndex]);
        setShowUsersList(false);
        return
      }
    }
    if (event.key === "Enter") {
      submitComment();
    }
  };

  const renderCommentWithMentions = (comment: DealComment) => {
    const parts = [];
    let lastIndex = 0;
    comment?.mentions?.forEach((mention, index) => {
      const beforeMention = comment.content.slice(lastIndex, mention.index);
      if (beforeMention) parts.push(beforeMention);

      parts.push(<MentionSpan key={index}>@{mention.name}</MentionSpan>);
      lastIndex = mention.index + mention.name.length + 1; // Move past this mention
    });

    const afterLastMention = comment.content.slice(lastIndex);
    if (afterLastMention) parts.push(afterLastMention);

    return parts;
  };

  const removeCommentById = (
    comments: DealComment[],
    commentId: string
  ): DealComment[] => {
    return comments
      .map((comment) => {
        if (comment.children && comment.children.length > 0) {
          comment.children = removeCommentById(comment.children, commentId);
        }
        return comment;
      })
      .filter((comment) => comment._id !== commentId);
  };

  const addComment = (
    comments: DealComment[],
    newComment: DealComment
  ): DealComment[] => {
    // If the new comment has no parentId, add it to the root of the comments list
    if (!newComment.parent_id) {
      return [...comments, newComment];
    }

    // Otherwise, find the parent comment and add the new comment to its children
    return comments.map((comment) => {
      if (comment._id === newComment.parent_id) {
        return {
          ...comment,
          children: [...comment.children, newComment],
        };
      } else if (comment.children.length > 0) {
        return {
          ...comment,
          children: addComment(comment.children, newComment),
        };
      }
      return comment;
    });
  };

  const handleDeleteComment = (commentId: string) => {
    setComments((prevComments) => removeCommentById(prevComments, commentId));
    setCurrentParentId("");
    deleteDealComment(dealId, commentId);
  };

  const submitComment = () => {
    if (currentComment.length > 0) {
      const newComment: DealComment = {
        _id: moment().toString(),
        first_name: user.first_name,
        last_name: user.last_name,
        user_id: user._id,
        timestamp: moment().toString(),
        content: currentComment,
        parent_id: currentParentId,
        children: [],
        mentions: mentions,
      };
      setComments((prevComments) => addComment(prevComments, newComment));
      if (dealId) {
        postDealComment(dealId, {
          content: currentComment,
          parent_id: currentParentId,
          mentions: mentions,
        });
      }
      setMentions([]);
      setMentionIndex(0);
      setCurrentParentId("");
      setCurrentComment("");
    }
  };

  useEffect(() => {
    if (itemRefs.current[highlightedIndex]) {
      itemRefs.current[highlightedIndex]?.scrollIntoView({
        block: "nearest",
      });
    }
    if (commentRefs.current[comments.length - 1]) {
      commentRefs.current[comments.length - 1]?.scrollIntoView({
        block: "nearest",
      });
    }
  }, [highlightedIndex, comments]);

  return (
    <CommentsSection>
      <h3>Comments</h3>
      <CommentsContainer>
        {comments && comments.length > 0 ? (
          comments?.map((comment: DealComment, index: number) => (
            <>
              <Comment key={index} ref={(el) => (commentRefs.current[index] = el)}>
                <CommentBody>
                  <CommentHeader>
                    <div className="letter-wrapper">
                      {comment.first_name[0].toUpperCase()}
                    </div>
                    <Username>
                      {comment.first_name} {comment.last_name[0].toUpperCase()}.
                    </Username>
                    <div className="actions-wrapper">
                      <div
                        className="reply-wrapper"
                        onClick={() => setCurrentParentId(comment._id)}
                      >
                        Reply
                      </div>
                      {comment.user_id === user._id && (
                        <div
                          className="delete-wrapper"
                          onClick={() => handleDeleteComment(comment._id)}
                        >
                          <Trash />
                          Delete
                        </div>
                      )}
                    </div>
                    <Time>
                      {moment(comment.timestamp).format("MMM Do YYYY")}
                    </Time>
                    {/* <ReplyButton>Reply</ReplyButton> */}
                  </CommentHeader>
                  <CommentContent>
                    {renderCommentWithMentions(comment)}
                  </CommentContent>
                </CommentBody>
              </Comment>
              {comment.children &&
                comment.children.length > 0 &&
                comment.children.map((child: DealComment, index: number) => (
                  <div className="child-wrapper">
                    <div className="line"></div>
                    <Comment className="child" key={index}>
                      <CommentBody>
                        <CommentHeader>
                          <div className="letter-wrapper">
                            {child.first_name[0].toUpperCase()}
                          </div>
                          <Username>
                            {child.first_name}{" "}
                            {child.last_name[0].toUpperCase()}.
                          </Username>
                          <div className="actions-wrapper">
                            {child.user_id === user._id && (
                              <div
                                className="delete-wrapper"
                                onClick={() => handleDeleteComment(child._id)}
                              >
                                <Trash />
                                Delete
                              </div>
                            )}
                          </div>
                          <Time>
                            {moment(child.timestamp).format("MMM Do YYYY")}
                          </Time>
                          {/* <ReplyButton>Reply</ReplyButton> */}
                        </CommentHeader>
                        <CommentContent>{renderCommentWithMentions(child)}</CommentContent>
                      </CommentBody>
                    </Comment>
                  </div>
                ))}
              {comment._id === currentParentId && <div className="line"></div>}
            </>
          ))
        ) : (
          <h4>No comments yet</h4>
        )}
      </CommentsContainer>
      <TextInput>
        <input
          onChange={(event) => setCurrentComment(event.target.value)}
          value={currentComment}
          placeholder="Enter your comment here."
          onKeyDown={handleKeyPress}
        />
        <button onClick={() => submitComment()}>
          <Send />
        </button>
        {showUsersList && (
          <UserList>
            {filteredUsers.map((user: User, index: number) => (
              <UserListItem
                key={user._id}
                highlighted={index === highlightedIndex}
                ref={(el) => (itemRefs.current[index] = el)}
                onClick={() => handleUserClick(user)}
              >
                {user.first_name + " " + user.last_name}
              </UserListItem>
            ))}
          </UserList>
        )}
      </TextInput>
    </CommentsSection>
  );
};

export default DealComments;
