import { colors } from "colors";
import styled from "styled-components";

export const DealNotesWrapper = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: ${({ isOpen }) => (isOpen ? "0" : "-40%")};
  width: 40%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 100000;
  padding: 20px;
  overflow-y: auto;
  overflow-x: visible;
  color: ${colors.goodBlack};

  padding: 20px;
  min-height: 300px; // Adjust as needed
  overflow: auto;

  .close-button {
    position: absolute;
    top: 25px;
    right: 25px;
    background-color: ${colors.darkGray};
    color: ${colors.white};
    border: 1px solid ${colors.white};
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;
  }

  h2 {
    margin-bottom: 20px;
  }
`;

export const StyledQuillWrapper = styled.div`
  height: 86%;

  .quill {
    height: 100%;
  }

  .ql-toolbar {
    display: flex !important;
    flex-wrap: wrap;
    padding: 8px;
    background-color: #333;
    align-items: center;
  }

  .ql-formats {
    display: flex !important;
    align-items: center;
    margin-right: 15px !important;
    margin-bottom: 5px;
  }

  .ql-formats button,
  .ql-formats .ql-picker {
    color: white !important;
    height: 28px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    margin: 0 2px !important;
  }

  .ql-formats button {
    width: 28px !important;
  }

  .ql-header.ql-picker {
    width: auto !important;
    padding-right: 0 !important;
  }

  .ql-header .ql-picker-label {
    padding: 0 24px 0 8px !important;
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    width: auto !important;
    position: relative;
  }

  .ql-picker-options {
    background-color: #333 !important;
    padding: 5px 0 !important;
    border: 1px solid #555 !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
    max-height: none !important;
    overflow: visible !important;
  }

  .ql-picker-item {
    color: white !important;
    padding: 5px 8px !important;
  }

  .ql-picker {
    position: relative !important;
  }

  .ql-picker-options {
    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    z-index: 1000 !important;
  }

  .ql-formats button svg,
  .ql-formats span svg {
    width: 18px !important;
    height: 18px !important;
  }

  .ql-formats button svg path,
  .ql-formats span svg path,
  .ql-formats button svg line,
  .ql-formats span svg line,
  .ql-formats button svg rect,
  .ql-formats span svg rect,
  .ql-formats button svg circle,
  .ql-formats span svg circle {
    stroke: white !important;
    fill: none !important;
  }

  .ql-formats button svg path[fill],
  .ql-formats span svg path[fill] {
    fill: black !important;
  }

  .ql-active,
  .ql-active svg path {
    color: #06c !important;
  }

  .ql-active svg path[fill],
  .ql-active svg line,
  .ql-active svg rect,
  .ql-active svg circle {
    stroke: #06c !important;
    fill: #06c !important;
  }

  .ql-editor {
    color: ${colors.goodBlack};
  }

  .ql-container {
    position: relative !important;
  }

  // Adjust link tooltip positioning and visibility
  .ql-snow .ql-tooltip {
    position: absolute !important;
    top: -40px !important; // Adjust this value as needed
    left: 0 !important;
    z-index: 1000 !important;
    display: flex !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    background-color: #333 !important;
    border: 1px solid #555 !important;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2) !important;
    color: white !important;
    padding: 5px 12px !important;
    white-space: nowrap !important;
    visibility: visible !important;
    opacity: 1 !important;
    transition: none !important;
  }

  .ql-snow .ql-tooltip.ql-hidden {
    display: none !important;
  }

  .ql-snow .ql-tooltip input[type="text"] {
    background-color: #444 !important;
    border: 1px solid #555 !important;
    color: white !important;
    font-size: 13px !important;
    height: 26px !important;
    margin: 0 !important;
    padding: 3px 5px !important;
    width: 170px !important;
  }

  .ql-snow .ql-tooltip a.ql-action,
  .ql-snow .ql-tooltip a.ql-remove {
    color: #06c !important;
    margin-left: 6px !important;
    text-decoration: none !important;
  }

  .ql-snow .ql-tooltip a.ql-action::after {
    content: "Edit" !important;
  }

  .ql-snow .ql-tooltip a.ql-remove::before {
    content: "Remove" !important;
  }

  .ql-snow .ql-tooltip::before {
    content: "Link:" !important;
    line-height: 26px !important;
    margin-right: 8px !important;
  }
`;
