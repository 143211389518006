import { colors } from "colors";
import styled from "styled-components";

interface MarketingBlogWrapperProps {
  topRef?: number | null;
}

export const MarketingBlogWrapper = styled.div<MarketingBlogWrapperProps>`
  display: flex;
  justify-content: center;
  background-color: ${colors.goodBlack};
  .inner-wrapper {
    width: 100vw;
    max-width: 1800px;
    padding: 125px 25px 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 68px;
      font-weight: 700;
      margin-bottom: 30px;
      span {
        color: ${colors.green};
      }
    }
    h2 {
      font-size: 53px;
      color: white;
      margin-bottom: 30px;
    }
    .team-header {
      color: ${colors.green};
    }
    h3 {
      font-size: 35px;
      font-weight: 500;
    }
    h4 {
      font-size: 21px;
      font-weight: 400;
      margin-bottom: 30px;
      color: ${colors.lightGray};
    }
    hr {
      width: 90%;
      margin-top: 70px;
      margin-bottom: 70px;
      border-bottom: transparent;
      border-top: 1px solid #707277;
    }
    .heading-wrapper {
      display: flex;
      justify-content: space-between;
      .heading-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 0 1 100%;
      }
      .heading-right {
        position: relative;
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        svg {
          position: absolute;

          right: 0;
        }
      }
    }
    .blog-posts-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }
  @media (min-width: 906px) {
    .inner-wrapper {
      padding: 150px 78px 0 78px;
      .heading-wrapper {
        .heading-left {
          flex: 0 1 50%;
        }
      }
    }
  }
`;
export const BlogPostListItemWrapper = styled.div`
  flex: 0 1 calc(100% - 30px);
  margin: 15px;
  background-color: black;
  padding: 40px;
  border-radius: 5px;
  min-width: 350px;
  min-height: 400px;
  width: 400px;
  .title {
    font-weight: 700 !important;
    min-height: 50px;
    color: white !important;
  }
  .date {
    font-weight: 700;
    font-size: 16px;
  }
  .description {
    font-size: 16px;
    min-height: 80px;
    color: ${colors.lightGray};
  }
  .author-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    .author {
      font-size: 18px;
      font-weight: 700;
    }
    div {
      display: flex;
      align-items: center;
      .image-wrapper {
        border-radius: 5000px;
        overflow: hidden;
        height: fit-content;
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        .author-image {
          height: 30px;
          width: 30px;
        }
      }
      .author-name {
        font-size: 16px;
      }
    }
  }
  @media (min-width: 906px) {
    flex: 0 1 calc(50% - 30px);
  }
  @media (min-width: 1296px) {
    flex: 0 1 calc(33% - 30px);
  }
`;

interface MarkeringBlogPostPageWrapperProps {
  topRef?: number | null;
}
export const MarketingBlogPostPageWrapper = styled.div<MarkeringBlogPostPageWrapperProps>`
  display: flex;
  justify-content: center;
  background-color: ${colors.goodBlack};
  .author {
    margin-bottom: 20px;
    p {
      font-weight: 700;
      font-size: 14px;
      color: ${colors.white};
      margin-top: 0;
    }
    .inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      .image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5000px;
        overflow: hidden;
        height: fit-content;
        width: fit-content;
        min-height: 40px;
        min-width: 40px;
        margin-right: 0;
        margin-bottom: 15px;
        margin-left: 10px;
        img {
          height: 40px;
          width: 40px;
        }
      }
      .info-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
        }
        .socials-wrapper {
          svg {
            margin-right: 7px;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            &:hover {
              opacity: 0.6;
            }
          }
        }
      }
      .info {
      }
    }
  }
  .inner-wrapper {
    width: 100vw;
    max-width: 1800px;
    padding: 150px 25px 0 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 68px;
      font-weight: 700;
      margin-bottom: 30px;
      span {
        color: ${colors.green};
      }
    }
    h2 {
      font-size: 42px;
      color: white;
      margin-bottom: 30px;
    }

    h3 {
      font-size: 35px;
      font-weight: 500;
    }
    h4 {
      font-size: 21px;
      font-weight: 400;
      margin-bottom: 30px;
    }
    hr {
      width: 95%;
      margin-top: 50px;
      margin-bottom: 50px;
      border-bottom: transparent;
      border-top: 1px solid #707277;
    }
    .heading-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .heading-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 0 1 100%;
      }
      .heading-right {
        position: relative;
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        svg {
          position: absolute;
          width: 200px;
          right: 0;
        }
      }
    }
    .content-wrapper {
      max-width: 1000px;
      width: 100%;
      a {
        color: ${colors.mainGreen};
        text-decoration: underline;

        &:hover {
          opacity: 0.8;
        }
      }
      h2 {
        color: white;
        text-align: left;
        margin-bottom: 0;
      }
      h4 {
        margin-top: 30px;
        margin-bottom: 5px;
      }
    }
    .author-section {
      width: 100%;
      min-height: 200px;
      background-color: white;
      margin-top: 75px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      flex-direction: column;
      hr {
        display: block;
        width: 190px;
        border: none;
        height: 1px;
        background-color: ${colors.grayWhite};
        margin-left: 0;
        margin-right: 0;
      }
      .left {
        display: flex;
        flex-direction: column;
        flex: 0 1 40%;
        padding: 40px 20px 40px 20px;
        min-height: inherit;
        align-items: center;
        p {
          font-weight: 700;
          font-size: 14px;
          color: black;
          margin-top: 0;
        }
        .inner {
          display: flex;
          flex-direction: column;

          align-items: center;
          .image-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5000px;
            overflow: hidden;
            height: fit-content;
            width: fit-content;
            min-height: 80px;
            min-width: 80px;
            margin-right: 0;
            margin-bottom: 15px;
            img {
              height: 80px;
              width: 80px;
            }
          }
          .info-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
              margin-top: 0;
              margin-bottom: 7px;
              font-weight: 400;
            }
            .socials-wrapper {
              svg {
                margin-right: 7px;
                cursor: pointer;
                transition: 0.2s ease-in-out;
                &:hover {
                  opacity: 0.6;
                }
              }
            }
          }

          .info {
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        flex: 0 1 60%;
        min-height: inherit;
        align-items: center;
        padding: 40px 20px 40px 20px;
        p {
          font-size: 14px;
          color: black;
          font-weight: 700;
          margin-top: 0;
        }
        .socials-wrapper {
          margin-top: 10px;
          svg {
            margin-left: 10px;
            margin-right: 10px;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            &:hover {
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
  @media (min-width: 727px) {
    .inner-wrapper {
      .author-section {
        flex-direction: row;
        hr {
          transform: rotate(90deg);
        }
        .left {
          align-items: unset;
          .inner {
            flex-direction: row;
            .image-wrapper {
              margin-right: 15px;
              margin-bottom: unset;
            }
            .info-wrapper {
              align-items: unset;
            }
          }
        }
        .right {
          align-items: flex-start;
          .socials-wrapper {
            svg {
              margin-left: unset;
            }
          }
        }
      }
    }
  }
  @media (min-width: 906px) {
    .inner-wrapper {
      padding: 150px 78px 0 78px;
    }
  }
`;
