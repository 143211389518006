import React, { FC, useEffect, useState } from "react";
import {
  ChartWrapper,
  ColorSelect,
  ContentWrapper,
  FilterSelect,
  FiltersWrapper,
  MeddicSpiderChartWrapper,
  FilterTitle,
  FilterGroup,
  FilterHeader,
  FilterLabel,
} from "./MeddicSpiderChart.styled";
import {
  getDealsQuick,
  getOrganizationScoresRadar,
  getOrganizationUsers,
  getTeams,
  postRadarInsights,
} from "api/helpers";
import SpiderChart from "components/SpiderChart/SpiderChart";
import { QuickDeal, Stage, Team, UserData } from "types";
import { useSelector } from "react-redux";
import InsightsAMA from "components/InisghtsAMA/InisghtsAMA";

interface MeddicSpiderCahrtProps {}

interface ScoreData {
  averageScore: number;
  category_name: string;
}

interface ChartData extends ScoreData {
  formattedScore: number;
}

interface Deal {
  _id: string;
  name: string;
}

interface Insight {
  category: string;
  reason: string;
}

const LOADING_MESSAGES = [
  "Analyzing data patterns",
  "Processing pipelines",
  "Generating insights",
  "Crunching numbers",
  "Comparing performance metrics",
  "Evaluating trends",
  "Calculating correlations",
  "Examining deal history",
  "Synthesizing information",
  "Building recommendations",
];

const PRESET_COLORS = [
  { value: "#FF0000", label: "Red" },
  { value: "#0000FF", label: "Blue" },
  { value: "#00BF63", label: "Green" },
  { value: "#FFA500", label: "Orange" },
  { value: "#800080", label: "Purple" },
  { value: "#FFD700", label: "Gold" },
  { value: "#00FFFF", label: "Cyan" },
  { value: "#FF69B4", label: "Pink" },
];

const bubbleQueries = [
  "Explain key gaps between ${filter1.name} and ${filter2.name}",
  "Explain main similarities ${filter1.name} and ${filter2.name}",
  "What are the biggest opportunities to improve?",
  "Which deals should I focus on?",
];

const MeddicSpiderChart: FC<MeddicSpiderCahrtProps> = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [aiResponse, setAiResponse] = useState("");
  const [filteredDeals1, setFilteredDeals1] = useState<QuickDeal[]>([]);
  const [filteredDeals2, setFilteredDeals2] = useState<QuickDeal[]>([]);
  const [filter1Error, setFilter1Error] = useState(false);
  const [filter2Error, setFilter2Error] = useState(false);
  const [loading, setLoading] = useState(true);
  const [aiLoading, setAiLoading] = useState(false);
  const [loadingSet1, setLoadingSet1] = useState(false);
  const [loadingSet2, setLoadingSet2] = useState(false);
  const [scores, setScores] = useState<ChartData[]>([]);
  const [scores2, setScores2] = useState<ChartData[]>([]);
  const [deal, setDeal] = useState<QuickDeal | null>(null);
  const [rep, setRep] = useState<UserData | null>(null);
  const [repTeam, setRepTeam] = useState<Team | null>(null);
  const [stage, setStage] = useState<Stage | null>(null);
  const [users, setUsers] = useState<UserData[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [deals, setDeals] = useState<QuickDeal[]>([]);
  const [filterOneColor, setFilterOneColor] = useState<string>("#0000FF"); // Default red
  const [filterTwoColor, setFilterTwoColor] = useState<string>("#00BF63"); // Default blue
  const [deal2, setDeal2] = useState<QuickDeal | null>(null);
  const [rep2, setRep2] = useState<UserData | null>(null);
  const [repTeam2, setRepTeam2] = useState<Team | null>(null);
  const [stage2, setStage2] = useState<Stage | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (aiLoading) {
      const messageInterval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % 8);
      }, 20000); // Exactly 3 seconds per face (12 seconds total / 4 faces)

      return () => clearInterval(messageInterval);
    }
  }, [aiLoading]);

  // Get four consecutive messages for the cube sides
  const getFourMessages = () => {
    return [
      LOADING_MESSAGES[currentIndex % 8],
      LOADING_MESSAGES[(currentIndex + 1) % 8],
      LOADING_MESSAGES[(currentIndex + 2) % 8],
      LOADING_MESSAGES[(currentIndex + 3) % 8],
    ];
  };

  //   const [startDate, setStartDate] = useState<string>("");
  //   const [endDate, setEndDate] = useState<string>(today);
  const stages = useSelector((state: any) => state.app.stages);

  // Initial load effect
  useEffect(() => {
    const initialLoad = async () => {
      try {
        setLoading(true);

        // Just get initial data without any filters
        const [scoreData1, dealsData, userData, teamsData] = await Promise.all([
          getOrganizationScoresRadar({}),
          getDealsQuick(true),
          getOrganizationUsers(),
          getTeams(),
        ]);

        const formattedData: ChartData[] = scoreData1.map((score: any) => ({
          ...score,
          formattedScore: score.averageScore,
        }));

        // Set the same initial data for both charts
        setScores(formattedData);
        setScores2(formattedData);
        setUsers(userData);
        setDeals(dealsData);
        setTeams(teamsData.teams);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setLoading(false);
      }
    };

    initialLoad();
  }, []); // Empty dependency array for initial load only

  // Effect for filter set 1
  useEffect(() => {
    if (!users.length || (!deal && !rep && !stage && !repTeam)) return;

    const fetchData = async () => {
      try {
        setLoadingSet1(true);
        const filters = {
          deal_id: deal?.id,
          user_id: rep?._id,
          stage: stage?.label,
          team_id: repTeam?.team_id,
        };

        const scoreData = await getOrganizationScoresRadar(filters);
        const formattedData: ChartData[] = scoreData.map((score: any) => ({
          ...score,
          formattedScore: score.averageScore,
        }));

        if (formattedData.length === 0) {
          setFilter1Error(true);
        } else {
          setFilter1Error(false);
        }

        setScores(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingSet1(false);
      }
    };

    fetchData();
  }, [deal, rep, stage, repTeam]);

  // Effect for filter set 2
  useEffect(() => {
    if (!users.length || (!deal2 && !rep2 && !stage2 && !repTeam2)) return;

    const fetchData = async () => {
      try {
        setLoadingSet2(true);
        const filters = {
          deal_id: deal2?.id,
          user_id: rep2?._id,
          stage: stage2?.label,
          team_id: repTeam2?.team_id,
        };

        const scoreData = await getOrganizationScoresRadar(filters);
        const formattedData: ChartData[] = scoreData.map((score: any) => ({
          ...score,
          formattedScore: score.averageScore,
        }));

        if (formattedData.length === 0) {
          setFilter2Error(true);
        } else {
          setFilter2Error(false);
        }

        setScores2(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingSet2(false);
      }
    };

    fetchData();
  }, [deal2, rep2, stage2, repTeam2]);

  const handleSuggestionClick = (query: string) => {
    // Handle the suggestion click - you can implement the logic here
    console.log(`Selected suggestion: ${query}`);
  };

  const generateFilterLabel = (
    deal: QuickDeal | null,
    rep: UserData | null,
    stage: Stage | null,
    repTeam: Team | null
  ): string => {
    if (deal) {
      return deal.name;
    }

    if (repTeam) {
      return repTeam.team_name;
    }

    if (rep && stage) {
      return `All ${stage.label} data for ${rep.first_name} ${rep.last_name}`;
    }

    if (rep) {
      return `${rep.first_name} ${rep.last_name}'s Data`;
    }

    if (stage) {
      return `All ${stage.label} data`;
    }

    return "All data";
  };

  // Effect for filtering deals based on rep1
  useEffect(() => {
    if (rep) {
      const userDeals = deals.filter((deal) => deal.user_id === rep._id);
      setFilteredDeals1(userDeals);
      // Clear deal selection when deals are filtered
      setDeal(null);
    } else {
      setFilteredDeals1(deals);
    }
  }, [rep, deals]);

  // Effect for filtering deals based on rep2
  useEffect(() => {
    if (rep2) {
      const userDeals = deals.filter((deal) => deal.user_id === rep2._id);
      setFilteredDeals2(userDeals);
      // Clear deal selection when deals are filtered
      setDeal2(null);
    } else {
      setFilteredDeals2(deals);
    }
  }, [rep2, deals]);

  const handleSearch = async () => {
    setAiResponse("");
    setAiLoading(true);
    try {
      const searchData = {
        query: searchQuery,
        filter1: {
          deal_id: deal?.id || null,
          rep: rep ? rep._id : null,
          stage: stage?.label || null,
          team_id: repTeam?.team_id || null,
          name: generateFilterLabel(deal, rep, stage, repTeam),
        },
        filter2: {
          deal_id: deal2?.id || null,
          rep: rep2 ? rep2._id : null,
          stage: stage2?.label || null,
          team_id: repTeam2?.team_id || null,
          name: generateFilterLabel(deal2, rep2, stage2, repTeam2),
        },
      };
      console.log("Search data:", searchData);
      const data = await postRadarInsights(searchData);
      console.log("Data:", data);
      const formattedInsights = data.insights
        .map(
          (insight: Insight) =>
            `<h3><strong>${insight.category}</strong></h3>\n<p>${insight.reason}</p>`
        )
        .join("\n");
      setAiResponse(formattedInsights);
    } finally {
      setAiLoading(false);
    }
  };

  return (
    <MeddicSpiderChartWrapper>
      <FiltersWrapper>
        <div className="filter-title-container">
          <FilterTitle>Compare Completeness</FilterTitle>
          {loadingSet1 && (
            <div className="loading-bars">
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          )}
          {loadingSet2 && (
            <div className="loading-bars">
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <FilterGroup>
            <FilterHeader>
              <FilterLabel>Filter #1</FilterLabel>
              <ColorSelect
                value={filterOneColor}
                onChange={(e) => setFilterOneColor(e.target.value)}
                title="Select color"
              >
                {PRESET_COLORS.map((color) => (
                  <option
                    key={color.value}
                    value={color.value}
                    style={{ backgroundColor: color.value }}
                  >
                    {color.label}
                  </option>
                ))}
              </ColorSelect>
            </FilterHeader>

            <FilterSelect
              value={rep?._id || repTeam?.team_id || ""}
              onChange={(e) => {
                const selectedUser =
                  users.find((user) => user._id === e.target.value) || null;
                setRep(selectedUser);
                const selectedTeam =
                  teams.find((team) => team.team_id === e.target.value) || null;
                setRepTeam(selectedTeam);
                if (selectedUser || selectedTeam) {
                  setDeal(null);
                  setAiResponse("");
                  setSearchQuery("");
                }
              }}
              defaultValue=""
            >
              <option value="">All Users</option>
              {teams.map((team) => (
                <option key={team.team_id} value={team.team_id}>
                  {team.team_name}
                </option>
              ))}
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.first_name} {user.last_name}
                </option>
              ))}
            </FilterSelect>

            <FilterSelect
              value={stage?.label || ""}
              onChange={(e) => {
                const selectedStage =
                  stages?.find((s: any) => s.label === e.target.value) || null;
                setStage(selectedStage);
                setAiResponse("");
                setSearchQuery("");
              }}
              defaultValue=""
            >
              <option value="">All Stages</option>
              {stages?.map((stage: any) => (
                <option key={stage.value} value={stage.label}>
                  {stage.label}
                </option>
              ))}
            </FilterSelect>

            <FilterSelect
              value={deal?.id || ""}
              onChange={(e) => {
                let selectedDeal;
                if (e.target.value === "ClosedWon") {
                  selectedDeal = {
                    id: "ClosedWon",
                    name: "All Closed Won",
                  };
                } else if (e.target.value === "ClosedLost") {
                  selectedDeal = {
                    id: "ClosedLost",
                    name: "All Closed Lost",
                  };
                } else {
                  selectedDeal =
                    filteredDeals1?.find((d) => d.id === e.target.value) ||
                    null;
                }
                setDeal(selectedDeal as QuickDeal);
                if (selectedDeal) {
                  setStage(null);
                  setAiResponse("");
                  setSearchQuery("");
                }
              }}
              defaultValue=""
            >
              <option value="">All Deals</option>
              <option value="ClosedWon">All Closed Won</option>
              <option value="ClosedLost">All Closed Lost</option>
              {filteredDeals1?.map((deal) => (
                <option key={deal.id} value={deal.id}>
                  {deal.name}
                </option>
              ))}
            </FilterSelect>
          </FilterGroup>

          <FilterGroup>
            <FilterHeader>
              <FilterLabel>Filter #2</FilterLabel>
              <ColorSelect
                value={filterTwoColor}
                onChange={(e) => setFilterTwoColor(e.target.value)}
                title="Select color"
              >
                {PRESET_COLORS.map((color) => (
                  <option
                    key={color.value}
                    value={color.value}
                    style={{ backgroundColor: color.value }}
                  >
                    {color.label}
                  </option>
                ))}
              </ColorSelect>
            </FilterHeader>

            <FilterSelect
              value={rep2?._id || repTeam2?.team_id || ""}
              onChange={(e) => {
                const selectedUser =
                  users.find((user) => user._id === e.target.value) || null;
                setRep2(selectedUser);
                const selectedTeam =
                  teams.find((team) => team.team_id === e.target.value) || null;
                setRepTeam2(selectedTeam);
                if (selectedUser || selectedTeam) {
                  setDeal2(null);
                  setAiResponse("");
                  setSearchQuery("");
                }
              }}
              defaultValue=""
            >
              <option value="">All Users</option>
              {teams.map((team) => (
                <option key={team.team_id} value={team.team_id}>
                  {team.team_name}
                </option>
              ))}
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.first_name} {user.last_name}
                </option>
              ))}
            </FilterSelect>

            <FilterSelect
              value={stage2?.label || ""}
              onChange={(e) => {
                const selectedStage =
                  stages?.find((s: any) => s.label === e.target.value) || null;
                setStage2(selectedStage);
                setAiResponse("");
                setSearchQuery("");
              }}
              defaultValue=""
            >
              <option value="">All Stages</option>
              {stages?.map((stage: any) => (
                <option key={stage.value} value={stage.label}>
                  {stage.label}
                </option>
              ))}
            </FilterSelect>

            <FilterSelect
              value={deal2?.id || ""}
              onChange={(e) => {
                let selectedDeal;
                if (e.target.value === "ClosedWon") {
                  selectedDeal = {
                    id: "ClosedWon",
                    name: "All Closed Won",
                  };
                } else if (e.target.value === "ClosedLost") {
                  selectedDeal = {
                    id: "ClosedLost",
                    name: "All Closed Lost",
                  };
                } else {
                  selectedDeal =
                    filteredDeals2?.find((d) => d.id === e.target.value) ||
                    null;
                }
                setDeal2(selectedDeal as QuickDeal);
                if (selectedDeal) {
                  setStage2(null);
                  setAiResponse("");
                  setSearchQuery("");
                }
              }}
              defaultValue=""
            >
              <option value="">All Deals</option>
              <option value="ClosedWon">All Closed Won</option>
              <option value="ClosedLost">All Closed Lost</option>
              {filteredDeals2?.map((deal) => (
                <option key={deal.id} value={deal.id}>
                  {deal.name}
                </option>
              ))}
            </FilterSelect>
          </FilterGroup>
          {filter1Error && (
            <p style={{ color: "black", margin: 0 }}>
              No data found for filter #1
            </p>
          )}
          {filter2Error && (
            <p style={{ color: "black", margin: 0 }}>
              No data found for filter #2
            </p>
          )}
        </div>
      </FiltersWrapper>
      <ContentWrapper>
        <ChartWrapper>
          <SpiderChart
            data={scores}
            comparisonData={scores2}
            loading={loading}
            colorOne={filterOneColor}
            colorTwo={filterTwoColor}
            primaryLabel={generateFilterLabel(deal, rep, stage, repTeam)}
            comparisonLabel={generateFilterLabel(deal2, rep2, stage2, repTeam2)}
            darkMode={true}
          />
        </ChartWrapper>

        {/* Right side - Legend/Insights */}
        <InsightsAMA
          loading={loading}
          title={`${generateFilterLabel(
            deal,
            rep,
            stage,
            repTeam
          )} vs. ${generateFilterLabel(deal2, rep2, stage2, repTeam2)}`}
          filter1={{
            deal_id: deal?.id || null,
            rep: rep ? rep._id : null,
            stage: stage?.label || null,
            team_id: repTeam?.team_id || null,
            name: generateFilterLabel(deal, rep, stage, repTeam),
          }}
          filter2={{
            deal_id: deal2?.id || null,
            rep: rep2 ? rep2._id : null,
            stage: stage2?.label || null,
            team_id: repTeam2?.team_id || null,
            name: generateFilterLabel(deal2, rep2, stage2, repTeam2),
          }}
          bubbleQueries={bubbleQueries}
          prompt_type="method"
        />
      </ContentWrapper>
    </MeddicSpiderChartWrapper>
  );
};

export default MeddicSpiderChart;
