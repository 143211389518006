import styled, { keyframes } from "styled-components";
import { colors, scoreToColor } from "colors";

export const DealsTable = styled.div`
  width: 100%;
  height: fit-content;
  margin-top: -2px;
  border-collapse: separate;
  border-spacing: 0 30px;
  background-color: white;
  color: black;
  border-radius: 0 0 5px 5px;
  border: none;
  border-collapse: separate;
  // Reduce the border-spacing (this controls the gap between rows)
  border-spacing: 0 4px; // Changed from 8px to 4px
  background: white;
  position: relative;
  z-index: 1;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  thead {
    tr {
      height: 50px; // Ensure consistent header height
    }

    th {
      padding: 0 12px; // Adjust padding
      font-weight: 600;
      color: #2c3e50;
      text-align: left;
      background: white;
    }
  }
  h3 {
    padding-left: 40px;
    padding-top: 40px;
    font-size: 20px;
  }
  .stage {
    min-width: 120px;
  }
  .stage-select {
    select {
      background-color: ${colors.black};
      color: ${colors.white};
      border: 0;
      height: 30px;
      border: none;
      border-radius: 5px;
      font-size: 14px;
      padding: 0px 10px;
      cursor: pointer;
      appearance: none; // Remove default stylingSize of the arrow
      width: 83%; // Ensure the select takes full width of its container
      max-width: 200px; // Adjust this value as needed
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const spin = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const Deal = styled.div`
  cursor: pointer;
  background: white;
  transition: all 0.2s ease-in-out;
  margin: 8px 0;
  position: relative;
  z-index: 10;
  width: calc(100% - 10px);
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
  margin: 0 5px;
  padding: 12px;
  gap: 30px;

  .cell {
    font-size: 14px;
    min-height: 50px;
    color: ${colors.goodBlack};
    vertical-align: middle;
    text-overflow: ellipsis;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: wrap;
    overflow: visible;
  }

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    background-color: #fafbfc;
    z-index: 11;
    box-shadow: ${colors.goodBoxShadow};
    border-radius: 5px;
    border: none;
    transition: all 0.2s ease-in-out;
  }

  &.processing {
    opacity: 0.5;
    pointer-events: none;
  }

  .spin-wrapper {
    height: fit-content;
    width: fit-content;
    padding: 0px;
    svg {
      width: 27px;
      height: 27px;
      margin-right: 10px;
      animation: ${spin} 2s linear infinite;
    }
  }

  .delete-deal {
    cursor: pointer;
    padding: 0px;
    border-radius: 6px;
    transition: all 0.2s;
    svg {
      transition: transform 0.2s ease-in-out;
    }
    &:hover {
      svg {
        transform: scale(1.05);
      }
      svg path {
        stroke: #e74c3c;
      }
    }

    svg {
      width: 25px;
      height: 25px;
      path {
        stroke: ${colors.grayWhite};
        transition: stroke 0.2s;
      }
    }
  }

  .name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 18%;
    max-width: 18%;
    min-width: 18%;
    word-break: break-all;
  }

  .value {
    width: 70px;
  }

  .close-date {
    width: 100px;
  }

  .score {
    padding: 0;
    width: fit-content;
    width: 59px;
  }

  .lead {
    padding: 0px;
    width: 5%;

    .text {
      width: 100%;
      margin-left: 4px;
      display: none;
      p {
        width: 100%;
        margin: 0;
        font-weight: 500;
        font-size: 12px;

        &.role {
          font-size: 10px;
          font-weight: 300;
          margin-top: 5px;
        }
      }
    }

    .pfp {
      width: 42px;
      height: 42px;
      min-width: 42px;
      min-height: 42px;
      border-radius: 100%;
      margin-left: 2px;
      cursor: pointer;
      position: relative;
      border: 2px solid #f8f9fa;
      transition: border-color 0.2s;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        border-color: ${colors.mainGreen};

        .text {
          display: block;
          position: absolute;
          width: 150px;
          min-height: 40px;
          right: -160px;
          top: 50%;
          transform: translateY(-50%);
          background-color: #2c3e50;
          color: white;
          padding: 12px;
          border-radius: 8px;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          z-index: 1001; // Increased z-index
          pointer-events: none; // Add this to prevent tooltip from interfering with hover
        }
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        object-fit: cover;
      }
    }

    .letter-wrapper {
      width: 42px;
      height: 42px;
      min-width: 42px;
      min-height: 42px;
      border-radius: 100%;
      background-color: ${colors.mainGreen};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: 600;
      color: white;
    }

    .user-wrapper {
      width: 100%;
      height: 48px;
      display: flex;
      border-radius: 100px;
      margin-right: 4px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
    }
  }

  .last-call {
    width: 8%;
    color: #6c757d;
    font-size: 13px;
  }

  .scores-wrapper {
    display: flex;
    gap: 4px;
    padding: 0 8px;
    width: 290px;
    justify-content: space-between;
    align-items: center;
  }

  .stage-select {
    width: 10%;
    select {
      background-color: #f8f9fa;
      color: #2c3e50;
      border: 1px solid #e9ecef;
      padding: 5px 12px;
      border-radius: 6px;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.2s;
      width: 83%;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:hover {
        border-color: ${colors.mainGreen};
      }

      &:focus {
        outline: none;
        border-color: ${colors.mainGreen};
        box-shadow: 0 0 0 2px rgba(46, 204, 113, 0.1);
      }
    }
  }

  div {
    margin: 0;
  }
`;

export const DealsWrapper = styled.div<{ activeOption: string }>`
  width: 100%;
  height: 100%;
  border-radius: 3px;
  text-align: left;
  box-shadow: 0 1px 7px rgba(0, 0, 0, 0.2);
  position: relative;
  max-width: unset;
  min-width: 1200px;
  max-width: 1200px;
  ${({ activeOption }) =>
    activeOption === "team-deals" &&
    `
    min-width: 1350px;
    max-width: 1350px;
  `}
  hr {
    width: 100%;
    position: absolute;
    top: 50px;
    border: none;
    height: 1px;
    background-color: ${colors.middleGray};
  }
  p {
    width: 20%;
  }
  .deals-table-wrapper {
    width: 100%;
  }
`;

export const DealsHeader = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  padding-left: 20px;
  position: relative;
  margin-top: 10px;
  &::after {
    content: "";
    position: absolute;
    left: 0.5%;
    bottom: 7px;
    width: 99%;
    height: 2px;
    background: #f9f9f9;
    border-radius: 2px;
  }
  .cell {
    font-size: 14px;
    min-height: 50px;
    color: ${colors.goodBlack};
    vertical-align: middle;
    text-overflow: ellipsis;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: wrap;
    overflow: visible;
  }
  .title-wrapper {
    p {
      width: fit-content;
      position: relative;
      margin: 0;
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 20px;
      text-wrap: nowrap;
      height: 50px; // Match header height
    }
  }

  .score {
  }

  .categories {
    width: 290px;
  }
  .value {
    width: 70px;
  }

  .close-date {
    width: 100px;
  }

  .rep {
    width: 5%;
    min-width: 5%;
    max-width: 5%;
  }
  .stage {
    width: 10%;
    min-width: 10%;
    max-width: 10%;
  }

  .deal-name {
    width: 18%;
  }

  .last-call {
    width: 8%;
  }

  .filter-wrapper {
    width: 15px;
    height: 15px;
    min-width: 15px;
    min-height: 15px;
    cursor: pointer;
    margin-left: 4px;
    margin-top: -6px;

    svg {
      width: 100%;
      height: 100%;
      path {
        stroke: ${colors.mainGreen};
      }
    }
  }
`;

export const SearchWrapper = styled.div`
  width: 200px;
  margin-bottom: 25px;
`;

const skeletonLoading = keyframes`
0% {
  background-position: -200% 0;
}
100% {
  background-position: 200% 0;
}
`;

export const DealsLoadWrapper = styled.div`
  width: 591%;
  margin-bottom: 25px;
  height: 600px;
  background: linear-gradient(90deg, #f5f5f5 25%, #e0e0e0 50%, #f5f5f5 75%);
  animation: ${skeletonLoading} 2s infinite;
  background-repeat: no-repeat;
  background-size: 200% 100%; /* Stretch horizontally */
  background-position: left center;
`;

export const ToggleContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #e0f7fa;
  border-radius: 50px;
  padding: 4px;
  width: 275px;
  margin-bottom: 25px;
`;

export const ToggleButton = styled.button<{ active: boolean }>`
  flex: 1;
  padding: 10px 20px;
  background-color: transparent;
  color: ${({ active }) => (active ? "#ffffff" : "#000000")};
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  z-index: 1;
  transition: color 0.3s;

  &:focus {
    outline: none;
  }
`;

interface TabProps {
  active: boolean;
}

export const Tab = styled.button<TabProps>`
  padding: 12px 24px;
  border: 0;
  outline: 0;
  background: white;
  color: black;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease;
  position: relative;
  flex: 1;
  width: 200px;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-shadow: none; // Add this
  text-shadow: none; // Add this
  border-radius: 0; // Add this
  border-style: none; // Add this explicitly
  border-width: 0; // Add this explicitly

  &::-moz-focus-inner {
    // Firefox specific fix
    border: 0;
  }

  ${(props) =>
    props.active &&
    `
    background: #1f2937;
    border: 0;  // Reinforce no border in active state
  `}

  ${(props) =>
    !props.active &&
    `
    &:hover {
      background: rgba(31, 41, 55, 0.1);
    }
  `}

  &:focus {
    outline: none;
    border: 0;
  }
`;

interface TabsWrapperProps {
  numberOfTabs: number;
}

export const TabsWrapper = styled.div<TabsWrapperProps>`
  display: flex;
  z-index: 100;
  background: white;
  border-radius: 8px;
  gap: 0px;
  margin-bottom: 0px;
  width: fit-content;
  position: relative;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
  width: 25%;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    background: linear-gradient(356deg, ${colors.mainGreen} 0%, #4cabca 100%);
    transition: all 0.3s ease;
    width: calc(100% / ${(props) => props.numberOfTabs});
    transform: translateX(calc(100% * var(--active-tab-index, 0)));
  }
`;

interface ScoreBoxProps {
  score: number;
}

export const TableScoreBox = styled.div<ScoreBoxProps>`
  width: 50px;
  min-width: 50px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  padding: 0 5px;
  span {
    font-size: 10px;
    font-weight: 300;
  }
  ${(props) => {
    let { background, text } = scoreToColor(props.score);
    return `
      background: linear-gradient(135deg, ${background}, ${background}dd);
      color: ${text};
      box-shadow: 0 2px 8px ${background}40;
      transition: all 0.2s ease-in-out;
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          45deg,
          transparent 0%,
          rgba(255, 255, 255, 0.1) 50%,
          transparent 100%
        );
        transform: translateX(-100%);
        transition: transform 0.3s ease;
      }

      &:hover {
        transform: scale(1.02);

        &::before {
          transform: translateX(100%);
        }
      }

      span {
        margin-left: 2px;
        opacity: 0.8;
      }
    `;
  }};
`;

export const FiltersContainer = styled.div<{ isOpen: boolean }>`
  width: 100%;
  background-color: white;
  border-radius: 5px;
  border-radius: 0px 5px 0 0;
  margin-bottom: 0px;
  transition: max-height 0.3s ease;
  color: ${colors.mainGreen};
  font-weight: 600;
  font-size: 14px;
  position: relative;
  z-index: 500;
  background-color: ${({ isOpen }) => (isOpen ? "#f9f9f9" : "white")};
  transition: background-color 0.3s ease;
  box-shadow: ${({ isOpen }) =>
    isOpen ? "0 2px 4px rgba(0,0,0,0.05)" : "none"};

  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

export const FiltersHeader = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  padding-bottom: 0px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 5px;
  color: ${colors.mainGreen};
  width: 100px;
  margin-bottom: ${({ isOpen }) => (isOpen ? "-10px" : "0px")};
`;

export const FiltersContent = styled.div<{ isOpen: boolean }>`
  max-height: ${({ isOpen }) => (isOpen ? "200px" : "0")};
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out,
    opacity 0.3s ease-in-out;
  padding: ${({ isOpen }) => (isOpen ? "10px 20px" : "0 20px")};
  overflow: hidden; // Always hidden during transition
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  pointer-events: ${({ isOpen }) => (isOpen ? "auto" : "none")};
  .dropdown-list {
    width: 200px;
  }
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;

  // Show overflow only after transition completes when open
  ${({ isOpen }) =>
    isOpen &&
    `
    background-color: #f9f9f9;
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      z-index: -1;
      pointer-events: none;
    }
    
    @keyframes showOverflow {
      to { overflow: visible; }
    }
    
    animation: showOverflow 0s linear 0.5s forwards;
  `}
  p {
    width: auto;
    margin-left: 50px;
    &.sort-direction {
      font-size: 12px;
      margin-left: 10px;
      color: ${colors.mainGreen};
      margin-right: -27px;
      cursor: pointer;
    }
  }
`;

export const FilterItem = styled.div`
  margin-bottom: 10px;
`;

export const Arrow = styled.div<{ isOpen: boolean }>`
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: ${({ isOpen }) =>
    isOpen ? "5px solid " + colors.mainGreen : "none"};
  border-right: ${({ isOpen }) =>
    isOpen ? "none" : "5px solid " + colors.mainGreen};
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? "rotate(90deg)" : "rotate(180deg)")};
`;

interface ScoreBoxProps {
  score: number;
}

export const ScoreBox = styled.div<ScoreBoxProps>`
  height: 25px;
  width: 25px;
  min-height: 25px;
  min-width: 25px;
  color: ${colors.white2};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  position: relative;
  font-weight: 700;
  border-radius: 6px;
  margin-right: 10px;
  cursor: pointer;
  overflow: visible;
  transition: transform 0.1s ease-in-out;
  ${(props) => {
    let { background, text } = scoreToColor(props.score);
    return `
      background-color: ${background};
      color: ${text};
    `;
  }};
  .floating-advice {
    width: 330px;
    min-height: 286px;
    height: fit-content;
    background-color: white;
    display: none;
    position: absolute;
    top: 47px;
    left: 27%;
    transform: translate(-50%, 0);
    border-radius: 5px;
    padding: 20px;
    padding-bottom: 80px;
    z-index: 100000;
    box-shadow: ${colors.goodBoxShadow};
    h3 {
      position: relative;
      padding: 0;
      margin-bottom: 10px;
      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -8px;
        width: 90px;
        height: 3px;
        ${(props) => {
          let { background, text } = scoreToColor(props.score);
          return `
            background: ${background};
          `;
        }};
        border-radius: 2px;
      }
    }
    p {
      width: 100%;
      text-align: left;
      font-weight: 400;
    }
    .triangle {
      height: 38px;
      width: 20px;
      position: absolute;
      top: -13px;
      left: 50%;
      transform: translate(-50%, 0) rotate(45deg);
      background: white;
      z-index: 99;
    }
    button {
      width: 90%;
      height: 60px;
      background-color: ${colors.black};
      border: none;
    }
  }
  &:hover {
    transform: scale(1.05);
    .floating-advice {
      display: block;
    }
  }
`;
