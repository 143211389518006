import styled from "styled-components";
import { colors } from "colors";

interface ChatWrapperProps {
  height?: string;
  width?: string;
}
export const DealCoachChatWrapper = styled.div<ChatWrapperProps>`
  display: flex;
  flex-wrap: nowrap;
  padding: 20px;
  padding-bottom: 0px;
  height: 100%;
  ${(props) => props.width && `width: ${props.width};`}
  ${(props) => props.height && `height: ${props.height};`}
  h3 {
    color: ${colors.mainGreen};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }
`;

export const ChatWrapper = styled.div`
  width: 625px;
  height: 100%;
  padding: 0;

  /* Modern scrollbar styling */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
    display: block;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.mainGreen}40;
    border-radius: 4px;
    transition: background 0.2s ease;
    min-height: 40px; /* Ensures minimum grabbable area */

    &:hover {
      background: ${colors.mainGreen}60;
    }
  }

  ::-webkit-scrollbar-track {
    background: ${colors.mainGreen}10;
    border-radius: 4px;
    margin: 4px 0;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${colors.mainGreen}40 ${colors.mainGreen}10;

  /* Ensure scrollbar is always visible */
  &::-webkit-scrollbar {
    display: block !important;
  }

  /* Additional WebKit specific rules to force visibility */
  &::-webkit-scrollbar-thumb {
    visibility: visible !important;
  }
  
  &::-webkit-scrollbar-track {
    visibility: visible !important;
  }
  border-right: 1px solid ${colors.mainGreen};
`;

export const ChatBox = styled.div`
  width: 100%;
  height: 90%;
  padding-right: 0;
  padding-bottom: 0px;
  overflow: auto;
  overflow-x: hidden;
  .load-more {
    text-decoration: underline;
    width: 100%;
    text-align: center;
    color: ${colors.mainGreen};
    cursor: pointer;
  }
  .no-more {
    width: 100%;
    text-align: center;
  }
  .full-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .deal-box {
    width: 530px;
    height: 200px;
    padding: 15px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 5px;
      padding: 1px; /* control the border thickness */
      background: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
      -webkit-mask: linear-gradient(#fff 0 0) content-box,
        linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
      pointer-events: none;
    }
    font-size: 20px;
    text-align: center;
  }
  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333; /* Change color as needed */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  .no-messages {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 40px;
    h3 {
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 20px;
      width: 100%;
    }
    .svg-wrapper {
      margin-bottom: 30px;
      margin-top: 30px;
      svg {
        width: 100px;
        height: 100px;
        path {
          fill: url(#gradient);
        }
      }
    }
  }
`;

export const ChatBotMessage = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  color: ${colors.black};
  .text {
    width: 86%;
    margin: 0;
    margin-bottom: 20px;
  }
  p {
    color: ${colors.mainGreen};
    font-size: 12px;
    font-weight: 900;
    margin: 0;
  }
  svg {
    width: 20px;
    height: 20px;
    path {
      fill: url(#gradient);
    }
  }
  .inner-svg {
    svg {
      height: inherit;
      width: inherit;
    }
  }
  .icon-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.white};
    border-radius: 100%;
    margin-right: 5px;
    /* border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(
      180deg,
      #7cdf4d 0%,
      #4cabca 100%
    ); /* Gradient colors */
    /* border-radius: 100%;
    border-image-slice: 1; /* Adjust the slice value to control the size of the border */
    /* border-image-repeat: round; */
  }
`;

export const UserMessage = styled.div`
  width: 95%;
  margin-bottom: 24px;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 12px;
  
  p {
    font-size: 13px;
    margin: 0;
  }

  .text {
    background-color: ${colors.mainGreen}08;
    color: ${colors.black};
    padding: 6px 20px;
    font-size: 14px;
    font-weight: 400;
    border-radius: 12px;
    line-height: 1.5;
    letter-spacing: 0.2px;
    border: 1px solid ${colors.mainGreen}15;
    max-width: 85%;
    transition: all 0.2s ease;
    
    &:hover {
      background-color: ${colors.mainGreen}12;
    }
  }

  .pfp {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
    overflow: hidden;
    background-color: ${colors.mainGreen}10;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.mainGreen}20;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.05);
    }
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    
    .text {
      max-width: 80%;
    }
  }
`;

export const TextInput = styled.div`
  padding: 0px 0px;
  margin-top: 10px;
  border-top: 1px solid ${colors.mainGreen};
  display: flex;
  position: relative;
  justify-content: center;
  
  input {
    flex-grow: 1;
    margin-right: 10px;
    margin-top: 5px;
    width: 90%;
    height: 30px;
    padding: 8px 50px 8px 16px;
    border-radius: 12px;
    justify-content: space-between;
    border: 1px solid ${colors.mainGreen}15;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;
    white-space: pre-wrap;
    transition: all 0.2s ease;
    background-color: ${colors.white};
    color: black;
    
    &::placeholder {
      color: gray;
    }
    
    &:focus {
      outline: none;
      transform: scale(1.01);
      border-color: ${colors.mainGreen};
      box-shadow: 0px 4px 12px ${colors.mainGreen}15;
    }
  }
  
  button {
    position: absolute;
    padding: 8px;
    background-color: ${colors.mainGreen};
    color: white;
    border: none;
    top: 50%;
    right: 32px;
    transform: translate(0, -50%);
    box-shadow: 0px 2px 6px ${colors.mainGreen}40;
    cursor: pointer;
    border-radius: 100%;
    font-weight: 700;
    height: 32px;
    width: 32px;
    transition: all 0.2s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    
    svg {
      width: 16px;
      height: 16px;
    }
    
    &:hover {
      transform: translate(0, -50%) scale(1.05);
      box-shadow: 0px 4px 8px ${colors.mainGreen}30;
      background-color: ${colors.mainGreen}ee;
    }

    &:active {
      transform: translate(0, -50%) scale(0.98);
    }
  }
`;

export const PrePromptsWrapper = styled.div`
  width: 364px;
  height: 100%;
  overflow: hidden;
  /* Modern scrollbar styling */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.mainGreen}40;
    border-radius: 4px;
    transition: background 0.2s ease;

    &:hover {
      background: ${colors.mainGreen}60;
    }
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${colors.mainGreen}40 transparent;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: ${colors.lightGray};
    opacity: 0.7;
  }
  .preprompts-scroll {
    overflow: scroll;
    overflow-x: hidden;
    height: 100%;
    padding-bottom: 50px;
    padding-top: 0px;
    padding-top: 20px;
    margin-top: 20px;
  }
  h5 {
    color: ${colors.black};
    font-size: 10px;
    font-weight: 400;
    line-height: 8px;
    text-align: center;
    margin: 0;
  }
  .preprompt {
    .inner-prompt {
      width: 260px;
      position: relative;
      height: fit-content;
      min-height: 80px;
      padding: 10px 20px 10px 20px;
      background-color: ${colors.white};
      border-radius: 5px;
      margin: 0 auto;
      margin-bottom: 20px;
      opacity: 0px;
      cursor: pointer;
      transition: transform 0.3s ease;
      &:hover {
        transform: scale(1.1);
      }
    }

    p {
      font-size: 13px;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: -0.01em;
      text-align: left;
      color: ${colors.black};
    }
    .shape {
      height: 5px;
      width: 100%;
      border-radius: 10px;
      background: linear-gradient(180deg, #7cdf4d 0%, #4cabca 100%);
    }
  }
  .prompt-title-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    h3 {
      font-size: 14px;
      font-weight: 700;
      line-height: 19px;
      letter-spacing: -0.01em;
      text-align: left;
      color: ${colors.mainGreen};
    }
    svg {
      height: 17px;
      width: 17px;
      margin-right: 5px;
    }
  }
`;
