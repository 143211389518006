import { colors } from "colors";

import styled from "styled-components";

export const SectionWrapper = styled.div`
  background-color: white;
  width: 100%;
  color: ${colors.goodBlack};
  svg {
    stroke: ${colors.mainGreen};
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 6xl;
  margin: auto;
  padding: 1.5rem;
  max-width: 1200px;
  padding: 80px 20px;
  margin: 0 auto;
  width: 100%;
`;

export const Header = styled.div`
  text-align: center;
  margin-bottom: 3rem;

  h2 {
    text-align: center;
    font-size: 48px;
    color: #333;
    margin-bottom: 30px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 24%;
      bottom: -8px;
      width: 190px;
      height: 4px;
      background: ${colors.mainGreen};
      border-radius: 2px;
    }
  }

  p {
    font-size: 20px;
    color: ${colors.goodBlack};
    margin-bottom: 50px;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
  position: relative;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const Line = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
    position: absolute;
    top: 6rem;
    left: 0;
    right: 0;
    height: 0.25rem;
    background-color: #0e1923;
    opacity: 0.1;
    z-index: 0;
  }
`;

export const StepContainer = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StepIndicator = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: ${colors.goodBlack};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  z-index: 10;
  svg {
    height: 2rem;
    width: 2rem;
  }
`;

export const StepNumber = styled.div`
  width: 5rem;
  height: 2rem;
  border-radius: 9999px;
  background-color: ${colors.mainGreen};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: medium;
  margin-bottom: 1rem;
`;

export const CardContainer = styled.div`
  width: 100%;
  height: 16rem;
  perspective: 1000px;
  cursor: pointer;
`;

export const Card = styled.div<{ flipped: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  transform-style: preserve-3d;
  transform: ${({ flipped }) => (flipped ? "rotateY(180deg)" : "none")};
  @media (max-width: 768px) {
    width: 300px;
    margin: 0 auto;
  }
`;

export const CardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

  .timing-wrapper {
    display: flex;
    align-items: center;
    color: ${colors.goodBlack};
    margin-bottom: 0.5rem;
    gap: 0.25rem;
  }

  h3 {
    font-size: 24px !important;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0.5rem;
  }

  div {
    display: flex;
    align-items: center;
    color: ${colors.goodBlack};
    margin-bottom: 0.5rem;
  }

  p {
    text-align: center;
    color: ${colors.goodBlack};
  }

  div:last-child {
    margin-top: auto;
    padding-top: 1rem;
    color: ${colors.mainGreen};
    font-weight: medium;
    display: flex;
    align-items: center;
  }

  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.02);
  }
`;

export const CardBack = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  border: 1px solid #e5e7eb;
  border-radius: 0.5rem;
  padding: 15px 15px;
  background-color: ${colors.mainGreen};
  color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 20px !important;
    font-weight: bold;
    margin-bottom: 0.75rem;
  }

  p {
    flex-grow: 1;
    margin: 0;
  }
`;

export const FlipButton = styled.button`
  margin-top: auto;
  align-self: center;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  background-color: white;
  color: ${colors.mainGreen};
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-weight: medium;
  position: absolute;
  bottom: -15px;
  border: 1px solid ${colors.lightGray};
`;

export const Button = styled.button`
  padding: 0.75rem 2rem;
  background-color: ${colors.mainGreen};
  color: white;
  font-weight: medium;
  border-radius: 0.375rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0d9668;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 50px;
`;
