import styled, { keyframes } from 'styled-components';

const scroll = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 5)); // Adjust based on logo count
  }
`;

export const LogoScrollerWrapper = styled.div`
  padding: 10px 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 0px;
  background-color: white;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 100px;
    height: 100%;
    z-index: 2;
    pointer-events: none;
  }
`;

export const ScrollingContent = styled.div`
  display: flex;
  animation: ${scroll} 20s linear infinite;
  width: fit-content;
`;

export const Logo = styled.div`
  width: 200px; // Adjust based on your needs
  height: 140px; // Adjust based on your needs
  margin: 0 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.02);
  }
`;