import React, { FC, useEffect, useState } from "react";
import {
  ChartWrapper,
  ColorSelect,
  ContentWrapper,
  FilterSelect,
  FiltersWrapper,
  MeddicSpiderChartWrapper,
  SparkleWrapper,
  SuggestionBubbles,
  SearchContainer,
  Bubble,
  SearchInput,
  FilterTitle,
  FilterGroup,
  FilterHeader,
  FilterLabel,
  LoaderBar,
  LoaderContainer,
  AiResponseContainer,
  LoaderText,
  LegendWrapper,
} from "./StageTimingChart.styled";
import {
  getDealsQuick,
  getOrganizationScoresRadar,
  getOrganizationUsers,
  getTeams,
  postRadarInsights,
  postStageTimingInsights,
} from "api/helpers";
import SpiderChart from "components/SpiderChart/SpiderChart";
import { QuickDeal, Stage, Team, UserData } from "types";
import { useSelector } from "react-redux";
import Sparkle from "static/svgs/Sparkle";
import LightningRobot from "static/svgs/LightningRobot";
import LeadoffRobot from "static/svgs/LeadoffRobot";
import Cycle from "static/svgs/Cycle";
import InsightsAMA from "components/InisghtsAMA/InisghtsAMA";

interface StageTimingChartProps {}

interface StageData {
  average_duration: number;
  stage: string;
}

interface Deal {
  _id: string;
  name: string;
}

interface Insight {
  category: string;
  reason: string;
}

const LOADING_MESSAGES = [
  "Analyzing data patterns",
  "Processing pipelines",
  "Generating insights",
  "Crunching numbers",
  "Comparing performance metrics",
  "Evaluating trends",
  "Calculating correlations",
  "Examining deal history",
  "Synthesizing information",
  "Building recommendations",
];

const PRESET_COLORS = [
  { value: "#FF0000", label: "Red" },
  { value: "#0000FF", label: "Blue" },
  { value: "#00BF63", label: "Green" },
  { value: "#FFA500", label: "Orange" },
  { value: "#800080", label: "Purple" },
  { value: "#FFD700", label: "Gold" },
  { value: "#00FFFF", label: "Cyan" },
  { value: "#FF69B4", label: "Pink" },
];

const bubbleQueries = [
  "Explain key gaps between time in stages for ${filter1.name} and ${filter2.name}",
  "Why are some stages taking longer to progress past than others?",
  "Which stage should I focus on improving performance in?",
];

const StageTimingChart: FC<StageTimingChartProps> = () => {
  const [filteredDeals1, setFilteredDeals1] = useState<QuickDeal[]>([]);
  const [filteredDeals2, setFilteredDeals2] = useState<QuickDeal[]>([]);
  const [filter1Error, setFilter1Error] = useState(false);
  const [filter2Error, setFilter2Error] = useState(false);
  const [loading, setLoading] = useState(true);
  const [aiLoading, setAiLoading] = useState(false);
  const [loadingSet1, setLoadingSet1] = useState(false);
  const [loadingSet2, setLoadingSet2] = useState(false);
  const [scores, setScores] = useState<StageData[]>([]);
  const [scores2, setScores2] = useState<StageData[]>([]);
  const [deal, setDeal] = useState<QuickDeal | null>(null);
  const [rep, setRep] = useState<UserData | null>(null);
  const [users, setUsers] = useState<UserData[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [deals, setDeals] = useState<QuickDeal[]>([]);
  const [filterOneColor, setFilterOneColor] = useState<string>("#0000FF"); // Default red
  const [filterTwoColor, setFilterTwoColor] = useState<string>("#00BF63"); // Default blue
  const [deal2, setDeal2] = useState<QuickDeal | null>(null);
  const [rep2, setRep2] = useState<UserData | null>(null);
  const [repTeam, setRepTeam] = useState<Team | null>(null);
  const [repTeam2, setRepTeam2] = useState<Team | null>(null);

  const stages = useSelector((state: any) => state.app.stages);

  // Initial load effect
  useEffect(() => {
    const initialLoad = async () => {
      try {
        setLoading(true);

        // Just get initial data without any filters
        const [timingData, dealsData, userData, teamsData] = await Promise.all([
          postStageTimingInsights({
            deal_id: undefined,
            user_id: undefined,
            team_id: undefined,
          }),
          getDealsQuick(true),
          getOrganizationUsers(),
          getTeams(),
        ]);

        // Set the same initial data for both charts

        setScores(timingData.stage_statistics);
        setScores2(timingData.stage_statistics);
        setUsers(userData);
        setDeals(dealsData);
        setTeams(teamsData.teams);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      } finally {
        setLoading(false);
      }
    };

    initialLoad();
  }, []); // Empty dependency array for initial load only

  // Effect for filter set 1
  useEffect(() => {
    if (!users.length || (!deal && !rep && !repTeam)) return;

    const fetchData = async () => {
      try {
        setLoadingSet1(true);
        const filters = {
          deal_id: deal?.id,
          user_id: rep?._id,
          team_id: repTeam?.team_id,
        };

        const timingData = await postStageTimingInsights(filters);

        if (timingData.stage_statistics.length === 0) {
          setFilter1Error(true);
        } else {
          setFilter1Error(false);
        }

        setScores(timingData.stage_statistics);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingSet1(false);
      }
    };

    fetchData();
  }, [deal, rep, repTeam]);

  // Effect for filter set 2
  useEffect(() => {
    if (!users.length || (!deal2 && !rep2 && !repTeam2)) return;

    const fetchData = async () => {
      try {
        setLoadingSet2(true);

        const filters = {
          deal_id: deal2?.id,
          user_id: rep2?._id,
          team_id: repTeam2?.team_id,
        };

        const timingData = await postStageTimingInsights(filters);

        if (timingData.stage_statistics.length === 0) {
          setFilter2Error(true);
        } else {
          setFilter2Error(false);
        }

        setScores2(timingData.stage_statistics);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoadingSet2(false);
      }
    };

    fetchData();
  }, [deal2, rep2, repTeam2]);

  const generateFilterLabel = (
    deal: QuickDeal | null,
    rep: UserData | null,
    repTeam: Team | null
  ): string => {
    if (deal) {
      return deal.name;
    }

    if (repTeam) {
      return repTeam.team_name;
    }

    if (rep) {
      return `${rep.first_name} ${rep.last_name}'s Data`;
    }

    return "All data";
  };

  // Effect for filtering deals based on rep1
  useEffect(() => {
    if (rep) {
      const userDeals = deals.filter((deal) => deal.user_id === rep._id);
      setFilteredDeals1(userDeals);
      // Clear deal selection when deals are filtered
      setDeal(null);
    } else {
      setFilteredDeals1(deals);
    }
  }, [rep, deals]);

  // Effect for filtering deals based on rep2
  useEffect(() => {
    if (rep2) {
      const userDeals = deals.filter((deal) => deal.user_id === rep2._id);
      setFilteredDeals2(userDeals);
      // Clear deal selection when deals are filtered
      setDeal2(null);
    } else {
      setFilteredDeals2(deals);
    }
  }, [rep2, deals]);

  return (
    <MeddicSpiderChartWrapper>
      <FiltersWrapper>
        <div className="filter-title-container">
          <FilterTitle>Time in Stage (Days)</FilterTitle>
          {loadingSet1 && (
            <div className="loading-bars">
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          )}
          {loadingSet2 && (
            <div className="loading-bars">
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          <FilterGroup>
            <FilterHeader>
              <FilterLabel>Filter #1</FilterLabel>
              <ColorSelect
                value={filterOneColor}
                onChange={(e) => setFilterOneColor(e.target.value)}
                title="Select color"
              >
                {PRESET_COLORS.map((color) => (
                  <option
                    key={color.value}
                    value={color.value}
                    style={{ backgroundColor: color.value }}
                  >
                    {color.label}
                  </option>
                ))}
              </ColorSelect>
            </FilterHeader>

            <FilterSelect
              value={rep?._id || repTeam?.team_id || ""}
              onChange={(e) => {
                const selectedUser =
                  users.find((user) => user._id === e.target.value) || null;
                setRep(selectedUser);
                const selectedTeam =
                  teams.find((team) => team.team_id === e.target.value) || null;
                setRepTeam(selectedTeam);
                if (selectedUser || selectedTeam) {
                  setDeal(null);
                }
              }}
              defaultValue=""
            >
              <option value="">All Users</option>
              {teams.map((team) => (
                <option key={team.team_id} value={team.team_id}>
                  {team.team_name}
                </option>
              ))}
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.first_name} {user.last_name}
                </option>
              ))}
            </FilterSelect>

            <FilterSelect
              value={deal?.id || ""}
              onChange={(e) => {
                let selectedDeal;
                if (e.target.value === "ClosedWon") {
                  selectedDeal = {
                    id: "ClosedWon",
                    name: "All Closed Won",
                  };
                } else if (e.target.value === "ClosedLost") {
                  selectedDeal = {
                    id: "ClosedLost",
                    name: "All Closed Lost",
                  };
                } else {
                  selectedDeal =
                    filteredDeals1?.find((d) => d.id === e.target.value) ||
                    null;
                }
                setDeal(selectedDeal as QuickDeal);
              }}
              defaultValue=""
            >
              <option value="">All Deals</option>
              <option value="ClosedWon">All Closed Won</option>
              <option value="ClosedLost">All Closed Lost</option>
              {filteredDeals1?.map((deal) => (
                <option key={deal.id} value={deal.id}>
                  {deal.name}
                </option>
              ))}
            </FilterSelect>
          </FilterGroup>

          <FilterGroup>
            <FilterHeader>
              <FilterLabel>Filter #2</FilterLabel>
              <ColorSelect
                value={filterTwoColor}
                onChange={(e) => setFilterTwoColor(e.target.value)}
                title="Select color"
              >
                {PRESET_COLORS.map((color) => (
                  <option
                    key={color.value}
                    value={color.value}
                    style={{ backgroundColor: color.value }}
                  >
                    {color.label}
                  </option>
                ))}
              </ColorSelect>
            </FilterHeader>

            <FilterSelect
              value={rep2?._id || repTeam2?.team_id || ""}
              onChange={(e) => {
                const selectedUser =
                  users.find((user) => user._id === e.target.value) || null;
                setRep2(selectedUser);
                const selectedTeam =
                  teams.find((team) => team.team_id === e.target.value) || null;
                setRepTeam2(selectedTeam);
                if (selectedUser || selectedTeam) {
                  setDeal2(null);
                }
              }}
              defaultValue=""
            >
              <option value="">All Users</option>
              {teams.map((team) => (
                <option key={team.team_id} value={team.team_id}>
                  {team.team_name}
                </option>
              ))}
              {users.map((user) => (
                <option key={user._id} value={user._id}>
                  {user.first_name} {user.last_name}
                </option>
              ))}
            </FilterSelect>

            <FilterSelect
              value={deal2?.id || ""}
              onChange={(e) => {
                let selectedDeal;
                if (e.target.value === "ClosedWon") {
                  selectedDeal = {
                    id: "ClosedWon",
                    name: "All Closed Won",
                  };
                } else if (e.target.value === "ClosedLost") {
                  selectedDeal = {
                    id: "ClosedLost",
                    name: "All Closed Lost",
                  };
                } else {
                  selectedDeal =
                    filteredDeals2?.find((d) => d.id === e.target.value) ||
                    null;
                }
                setDeal2(selectedDeal as QuickDeal);
                setRepTeam2(null);
              }}
              defaultValue=""
            >
              <option value="">All Deals</option>
              <option value="ClosedWon">All Closed Won</option>
              <option value="ClosedLost">All Closed Lost</option>
              {filteredDeals2?.map((deal) => (
                <option key={deal.id} value={deal.id}>
                  {deal.name}
                </option>
              ))}
            </FilterSelect>
          </FilterGroup>
        </div>
        {filter1Error && (
          <p style={{ color: "black", margin: 0 }}>
            No data found for filter #1
          </p>
        )}
        {filter2Error && (
          <p style={{ color: "black", margin: 0 }}>
            No data found for filter #2
          </p>
        )}
      </FiltersWrapper>
      <ContentWrapper>
        <ChartWrapper>
          <SpiderChart
            data={scores}
            comparisonData={scores2}
            loading={loading}
            colorOne={filterOneColor}
            colorTwo={filterTwoColor}
            primaryLabel={generateFilterLabel(deal, rep, repTeam)}
            comparisonLabel={generateFilterLabel(deal2, rep2, repTeam2)}
            darkMode={true}
          />
        </ChartWrapper>
        <InsightsAMA
          loading={loading}
          title="Stage Timing"
          prompt_type="stage_timing"
          filter1={{
            deal_id: deal?.id || null,
            rep: rep?._id || null,
            stage: null,
            team_id: repTeam?.team_id || null,
            name: generateFilterLabel(deal, rep, repTeam),
          }}
          filter2={{
            deal_id: deal2?.id || null,
            rep: rep2?._id || null,
            stage: null,
            team_id: repTeam2?.team_id || null,
            name: generateFilterLabel(deal2, rep2, repTeam2),
          }}
          bubbleQueries={bubbleQueries}
        />
        {/* <button className="update-stages-button">
          <Cycle />
          Update Active CRM Stages
        </button> */}
      </ContentWrapper>
    </MeddicSpiderChartWrapper>
  );
};

export default StageTimingChart;
