import { useState } from "react";
import {
  Container,
  Header,
  Grid,
  Line,
  StepContainer,
  StepIndicator,
  StepNumber,
  CardContainer,
  Card,
  CardFront,
  CardBack,
  Button,
  FlipButton,
  SectionWrapper,
  ButtonWrapper,
} from "./SalesSection.styled";
import {
  Clock,
  RotateCcw,
  Users,
  FileInput,
  PresentationIcon,
} from "lucide-react";
import { MarketingGreenButtonWrapper } from "../MarketingHomepage.styled";
import { useNavigate } from "react-router-dom";
export default function FlipCardTimeline() {
  const [flippedCards, setFlippedCards] = useState<number[]>([]);
  const navigate = useNavigate();

  const toggleCard = (cardIndex: number) => {
    if (flippedCards.includes(cardIndex)) {
      setFlippedCards(flippedCards.filter((i) => i !== cardIndex));
    } else {
      setFlippedCards([...flippedCards, cardIndex]);
    }
  };

  const steps = [
    {
      title: "Intro Call",
      duration: "45 minutes",
      shortDescription:
        "Understand your needs and tell you what Leadoff can offer",
      detailedDescription:
        "During this 45-minute call, our team will learn about your specific challenges and goals. We'll discuss your current sales process, identify pain points and goals, and determine how Leadoff.ai can help optimize your approach.",
      icon: <Users />,
    },
    {
      title: "Data Ingestion",
      duration: "15 minutes",
      shortDescription: "Connect to your tech stack and ingest necessary data",
      detailedDescription:
        "This brief 15-minute session focuses on securely connecting to your data sources. We prioritize data security and only collect what's necessary to provide meaningful insights for your business.",
      icon: <FileInput />,
    },
    {
      title: "Processing",
      duration: "48 hours",
      shortDescription:
        "Our AI analyzes your data to produce behavioral insights",
      detailedDescription:
        "Over the next 48 hours, our advanced AI models will analyze your sales data to identify patterns and opportunities for improvement. This will also create baseline for success that your team can use going forward.",
      icon: <Clock />,
    },
    {
      title: "Insights & Decision",
      duration: "1 hour",
      shortDescription: "Review insights and plan next steps",
      detailedDescription:
        "We'll present our findings to your team in a clear, actionable format and help you understand the potential impact of moving forward with Leadoff.ai.",
      icon: <PresentationIcon />,
    },
  ];

  return (
    <SectionWrapper>
      <Container>
        <Header>
          <h2>Our Streamlined Sales Process</h2>
          <p>From discovery to decision in just 4 easy steps</p>
        </Header>

        <Grid>
          <Line />
          {steps.map((step, index) => (
            <StepContainer key={index}>
              <StepIndicator>{step.icon}</StepIndicator>
              <StepNumber>Step {index + 1}</StepNumber>
              <CardContainer onClick={() => toggleCard(index)}>
                <Card flipped={flippedCards.includes(index)}>
                  <CardFront>
                    <h3>{step.title}</h3>
                    <div className="timing-wrapper">
                      <Clock />
                      <span>{step.duration}</span>
                    </div>
                    <p>{step.shortDescription}</p>
                    <div>
                      <span>Click for details</span>
                    </div>
                  </CardFront>
                  <CardBack>
                    <p>{step.detailedDescription}</p>
                    <FlipButton>
                      <RotateCcw className="w-4 h-4" />
                      <span>Flip back</span>
                    </FlipButton>
                  </CardBack>
                </Card>
              </CardContainer>
            </StepContainer>
          ))}
        </Grid>
        <ButtonWrapper>
          <MarketingGreenButtonWrapper
            onClick={() => {
              navigate("/contact-us");
            }}
          >
            Contact Sales
          </MarketingGreenButtonWrapper>
        </ButtonWrapper>
      </Container>
    </SectionWrapper>
  );
}
