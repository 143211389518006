import { FC, useState, useEffect, Dispatch, SetStateAction } from "react";
import { SettingWrapper } from "./Settings.styled";
import { useSelector, useDispatch } from "react-redux";
import { startLoading, endLoading } from "state/appActions";
import { User, UpdateUser } from "types";
import Edit from "static/svgs/Edit";
import { InputWrapper, Input, Label } from "components/AddDeal/AddDeal.styled";
import {
  GradientButton,
  GradientText,
} from "shared/shared_styled_comps/components.styled";
import { postProfilePicture, putUser } from "api/helpers";

interface ProfileProps {
  setTourIsOpen?: Dispatch<SetStateAction<boolean>>;
}

const Profile: FC<ProfileProps> = ({ setTourIsOpen }) => {
  let dispatch = useDispatch();
  const user: User = useSelector((state: any) => state.auth.user);
  const [editing, setEditing] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const isValidType =
        file.type === "image/png" || file.type === "image/jpeg";
      if (isValidType) {
        setSelectedFile(file);
        let response = await handleFileUpload(file);
        window.location.reload();
      } else {
        setSelectedFile(null);
      }
    }
  };

  const handleFileUpload = async (file: File) => {
    if (file) {
      const formData = new FormData();
      formData.append("image", file);
      return await postProfilePicture(formData);
    }
  };

  const [currentUser, setCurrentUser] = useState<UpdateUser>({
    email: "",
    first_name: "",
    last_name: "",
    linkedin_url: "",
  });
  type UserKeys = keyof User;
  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    setCurrentUser((prevUser: UpdateUser) => ({
      ...prevUser,
      [fieldName]: fieldValue,
    }));
  }

  useEffect(() => {
    setCurrentUser({
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      linkedin_url: user.linkedin_url,
    });
  }, [user]);

  const handleSave = async () => {
    if (editing) {
      dispatch(startLoading());
      let response = await putUser(currentUser);
      dispatch(endLoading());
      window.location.reload();
    }
    setEditing(!editing);
  };

  return (
    <SettingWrapper>
      <div className="profile-header">
        <h3>Profile</h3>
        <button className="edit-button" onClick={() => setEditing(!editing)}>
          {editing ? "Save" : "Edit"}
        </button>
      </div>
      <hr />
      <div className="profile-info-wrapper">
        <div className="profile-pic">
          <div className="pfp">
            {user.image_url ? (
              <img src={user.image_url} />
            ) : (
              user.first_name && (
                <div className="letter-wrapper">
                  {user.first_name[0].toUpperCase()}
                  {user.last_name[0].toUpperCase()}
                </div>
              )
            )}
            <input
              type="file"
              id="file"
              accept=".png, .jpg, .jpeg"
              onChange={handleFileChange}
            />
            <label htmlFor="file">
              <Edit />
              Change
            </label>
          </div>
        </div>
        {editing ? (
          <div className="edit-wrapper">
            <div className="dual-input-wrapper">
              <InputWrapper className="small-input">
                <Label>First Name</Label>
                <Input
                  type="text"
                  placeholder="First name"
                  value={currentUser.first_name}
                  name="first_name"
                  onChange={(e) => handleInputChange(e)}
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid #e4e7e5',
                    borderRadius: '5px',
                    padding: '10px 15px',
                    fontSize: '16px',
                    width: '100%',
                    color: 'black',
                    transition: 'all 0.2s ease',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                  }}
                />
              </InputWrapper>
              {/* <InputWrapper className="small-input">
                <Label>Company Email</Label>
                <Input
                  type="text"
                  placeholder="Contact title"
                  name="email"
                  value={currentUser.email}
                  onChange={(e) => handleInputChange(e)}
                />
              </InputWrapper> */}
            </div>
            <div className="dual-input-wrapper">
              <InputWrapper className="small-input">
                <Label>Last Name</Label>
                <Input
                  type="text"
                  placeholder="Last name"
                  name="last_name"
                  value={currentUser.last_name}
                  onChange={(e) => handleInputChange(e)}
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid #e4e7e5',
                    borderRadius: '5px',
                    padding: '10px 15px',
                    fontSize: '16px',
                    width: '100%',
                    color: 'black',
                    transition: 'all 0.2s ease',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                  }}
                />
              </InputWrapper>
              <InputWrapper className="small-input">
                <Label>LinkedIn Url</Label>
                <Input
                  type="text"
                  placeholder="LinkedIn Url"
                  name="linkedin_url"
                  value={currentUser.linkedin_url}
                  onChange={(e) => handleInputChange(e)}
                  style={{
                    backgroundColor: '#fff',
                    border: '1px solid #e4e7e5',
                    borderRadius: '5px',
                    padding: '10px 15px',
                    fontSize: '16px',
                    color: 'black',
                    width: '100%',
                    transition: 'all 0.2s ease',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)',
                  }}
                />
              </InputWrapper>
            </div>
          </div>
        ) : (
          <div className="display-wrapper">
            <div className="dual-input-wrapper">
              <InputWrapper className="small-input">
                <Label>First Name</Label>
                <p>{currentUser.first_name}</p>
              </InputWrapper>
              <InputWrapper className="small-input">
                <Label>Company Email</Label>
                <p>{currentUser.email}</p>
              </InputWrapper>
            </div>
            <div className="dual-input-wrapper">
              <InputWrapper className="small-input">
                <Label>Last Name</Label>
                <p>{currentUser.last_name}</p>
              </InputWrapper>
              <InputWrapper className="small-input">
                <Label>LinkedIn Url</Label>
                <p>{currentUser.linkedin_url}</p>
              </InputWrapper>
            </div>
          </div>
        )}
      </div>
    </SettingWrapper>
  );
};

export default Profile;
