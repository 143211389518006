import React from "react";
import {
  ValuePropSectionWrapper,
  SectionTitle,
  FeaturesGrid,
  FeatureCard,
  FeatureIcon,
  FeatureTitle,
  FeatureDescription,
  LearnMoreLink,
  TryButton,
  GradientBar,
  GradientBarText,
  InnerWrapper,
} from "./ValuePropSection.styled";
import Check from "static/svgs/Check";
import Notification from "static/svgs/Notification";
import Smiling from "static/svgs/Smiling";
import Lock from "static/svgs/Lock";
import Keyboard from "static/svgs/Keyboard";
import Rankings from "static/svgs/Rankings";
import LeadoffRobot from "static/svgs/LeadoffRobot";
import { useNavigate } from "react-router-dom";
const ValuePropSection: React.FC = () => {
  const navigate = useNavigate();
  const features = [
    {
      icon: <Keyboard />,
      title: "Learn from past data",
      description:
        "Leverage historical deal data to identify winning patterns and strategies that have proven successful for your team.",
    },
    {
      icon: <Rankings />,
      title: "Increase Win Rates",
      description:
        "Get actionable insights and recommendations to improve deal velocity and boost your team's win rates.",
    },
    {
      icon: <Notification />,
      title: "Predict outcomes",
      description:
        "Advanced AI models analyze deal signals to forecast win probability and highlight risk factors early.",
      learnMore: true,
    },
    {
      icon: <LeadoffRobot />,
      title: "Coach with confidence",
      description:
        "Data-driven coaching recommendations help sales managers develop their reps and improve performance.",
    },
    {
      icon: <Check />,
      title: "Zero effort setup",
      description:
        "Get up and running in minutes with seamless integrations into your existing sales tools (CRM, Call Recording tools, etc.).",
      learnMore: true,
    },
    {
      icon: <Lock />,
      title: "SOC II certified and secure",
      description:
        "Your data is protected by industry-leading security standards with SOC II Type II certification and end-to-end encryption.",
    },
  ];

  return (
    <ValuePropSectionWrapper>
      <InnerWrapper>
        <SectionTitle>Why choose Leadoff?</SectionTitle>
        <FeaturesGrid>
          {features.map((feature, index) => (
            <FeatureCard key={index}>
              <div className="feature-icon-wrapper">
                 <div className="feature-icon">{feature.icon}</div>
                <FeatureTitle>{feature.title}</FeatureTitle>
              </div>
              <FeatureDescription>
                {feature.description}
                {feature.learnMore && (
                  <>
                    {" "}
                    <LearnMoreLink href="/winning-behaviors">Learn more</LearnMoreLink>.
                  </>
                )}
              </FeatureDescription>
            </FeatureCard>
          ))}
        </FeaturesGrid>
        <GradientBar>
          <GradientBarText>Level up your sales team today</GradientBarText>
          <TryButton onClick={() => navigate("/contact-us")}>Contact sales</TryButton>
        </GradientBar>
      </InnerWrapper>
    </ValuePropSectionWrapper>
  );
};

export default ValuePropSection;
