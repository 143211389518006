import { colors } from "colors";
import styled from "styled-components";

export const InquiryFormWrapper = styled.div`
  height: auto;
  padding: 100px 35px;
  padding-top: 100px;
  background-color: ${colors.goodBlack};
  .form-wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
    margin-bottom: 100px;

    h1 {
      color: ${colors.white};
      margin-bottom: 40px;
    }

    /* Target the HubSpot form container */
    .hbspt-form {
      .hs-form {
        display: flex !important;
        flex-wrap: wrap !important;
        margin: 0 -12px; /* Create negative margin for the row */
      }

      .hs-form-field {
        flex: 0 0 50% !important;
        padding: 0 12px;
        margin-bottom: 24px;
        box-sizing: border-box;
      }

      /* Style labels */
      .hs-label-wrapper {
        margin-bottom: 8px;
      }

      label {
        color: ${colors.white} !important;
        font-size: 16px;
      }

      /* Style inputs */
      .hs-input {
        width: 100% !important;
        padding: 12px !important;
        border: 1px solid rgba(255, 255, 255, 0.2) !important;
        border-radius: 4px !important;
        background: transparent !important;
        color: ${colors.white} !important;
        transition: border-color 0.2s ease;

        &:focus {
          outline: none;
          border-color: rgba(255, 255, 255, 0.5) !important;
        }
      }

      /* Submit button container */
      .hs_submit {
        flex: 0 0 100% !important;
        padding: 0 12px;
      }

      /* Submit button */
      .hs-button {
        width: auto;
        background: ${colors.white};
        color: ${colors.goodBlack};
        font-weight: 600;
        cursor: pointer;
        border: none;
        padding: 16px 32px;
        margin-top: 16px;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
`;
