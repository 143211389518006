import styled from "styled-components";
import { colors } from "colors";

export const LoginLabel = styled.label`
  text-align: left;
  display: block;
  width: 100%;
  margin: 20px 0;
`;

export const LoginInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  background: linear-gradient(0deg, #edf2f7, #edf2f7), #ffffff;
  border: 1px solid #e0e4ec;
  border-radius: 8px;
  padding-left: 10px;
  margin-top: 8px;
`;

export const Select = styled.select`
  box-sizing: border-box;
  width: 100%;
  height: 52px;
  background: linear-gradient(0deg, #edf2f7, #edf2f7), #ffffff;
  border: 1px solid #e0e4ec;
  border-radius: 8px;
  padding-left: 10px;
  margin-top: 8px;
`;

interface SquareButtonProps {
  inverse?: boolean;
}

export const SquareButton = styled.button<SquareButtonProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 62px;

  ${(props) =>
    props.inverse
      ? `
    background-color: white;
    color: black;
  `
      : `
    background-color: #000000;
    color: white;
  `}

  border: 1px solid #EAEAEA;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 5px;
  cursor: pointer;
  line-height: 25px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;

  &:hover {
    background-color: #cabfbf;
    color: black;
  }
`;

export const DropDown = styled.select`
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  color: ${colors.white2};
  background-color: ${colors.goodBlack};
  width: auto;
  height: 32px;
  padding: 7px 13px 7px 13px;
  gap: 7px;
  border-radius: 5px;
  border: none;
  border-right: 16px solid transparent;
  max-width: 190px;
  box-shadow: ${colors.goodBoxShadow};
`;
