import { colors } from "colors";
import styled from "styled-components";

export const IntegrationSectionWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  position: relative;
  z-index: 1;
  padding-top: 150px;
  overflow: hidden;
  .hero-background-wrapper {
    svg {
      position: absolute;
      top: 00px;
      left: 00px;
      height: 100%;
      opacity: 0.8;
      z-index: -100;
    }
  }
  .inner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0;
    padding-top: 100 !important;
    div {
      margin-bottom: 75px;
      h2 {
        margin-bottom: 25px;
        text-align: center;
      }
      h4 {
        text-align: center;
        color: ${colors.lightGray};
      }
    }
    .item-row {
      display: flex;
      justify-content: center;
      width: inherit;
      margin-bottom: 100px;
      flex-wrap: wrap;
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 15px;
        background-color: transparent;
        margin: 0 5px 0 5px;
        border-radius: 30px;
        transition: 0.3s ease-in-out;
        cursor: pointer;
        svg {
          .rect {
            fill: white;
            z-index: -100;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
          }
        }
      }
      .sf {
        background-color: ${colors.goodBlack};
      }
      .item { 
        transition: transform 0.2s ease-in-out;
      }
      .item:hover {
        transform: scale(1.02);
      }
    }
  }
`;
