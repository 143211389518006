import { colors } from "colors";
import styled from "styled-components";

export const ValuePropSectionWrapper = styled.div`
  background-color: white;
  width: 100%;
`;

export const InnerWrapper = styled.div`
  max-width: 1200px;
  padding: 80px 20px;
  margin: 0 auto;
  width: 100%;
`;

export const SectionTitle = styled.h2`
  text-align: center;
  font-size: 48px;
  color: #333;
  margin-bottom: 60px;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 28%;
    bottom: -8px;
    width: 190px;
    height: 4px;
    background: ${colors.mainGreen};
    border-radius: 2px;
  }
`;

export const FeaturesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 120px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  .feature-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
    h3 {
      margin-bottom: 0;
    }
  }
`;

export const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.2s ease-in-out;
  padding: 20px;
  cursor: pointer;
  .feature-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 30px;
      height: 30px;
      path {
        stroke: ${colors.mainGreen};
        fill: ${colors.mainGreen};
      }
    }
  }
  &:hover {
    transform: scale(1.02);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    background-color: #fafbfc;
    z-index: 11;
    box-shadow: ${colors.goodBoxShadow};
    border-radius: 5px;
    border: none;
    transition: all 0.2s ease-in-out;
  }
`;

export const FeatureIcon = styled.div`
  font-size: 24px;
  margin-bottom: 20px;
`;

export const FeatureTitle = styled.h3`
  font-size: 18px !important;
  color: ${colors.goodBlack};
  margin-bottom: 16px;
  font-weight: 600;
`;

export const FeatureDescription = styled.p`
  font-size: 14px;
  color: ${colors.goodBlack};
  line-height: 1.5;
  margin: 0;
`;

export const LearnMoreLink = styled.a`
  color: #6666ff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const GradientBar = styled.div`
  background: linear-gradient(90deg, #00bf63 50%, #4cabca 100%);
  border-radius: 24px;
  padding: 60px 40px;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    gap: 30px;
  }
`;

export const GradientBarText = styled.h2`
  color: white;
  font-size: 48px;
  font-weight: 500;
  max-width: 600px;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 36px;
  }
`;

export const TryButton = styled.button`
  background: white;
  color: #4cabca;
  border: none;
  border-radius: 12px;
  padding: 16px 32px;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.05);
  }
`;
