import { colors } from "colors";
import styled from "styled-components";

export const InisghtsAMAWrapper = styled.div``;
export const CopyButton = styled.button`
  position: absolute;
  top: -15px;
  right: -15px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;
  background: transparent;
  border: none;
`;

export const BackButton = styled.div`
  position: absolute;
  top: -34px;
  left: -11px;
  color: ${colors.mainGreen};
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  z-index: 10;
  background: transparent;
  border: none;
  transition: all 0.2s ease-in-out;
  &:hover {
    color: ${colors.mainGreen};
    transform: scale(1.03);
  }
`;
