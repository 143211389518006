import { colors } from "colors";
import styled from "styled-components";

export const DealPredictionsWrapper = styled.div`
  color: ${colors.goodBlack};
  padding: 25px;
  padding-bottom: 0;
  overflow-y: scroll;
  height: 100%;
  position: relative;
  /* Modern scrollbar styling */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.mainGreen}40;
    border-radius: 4px;
    transition: background 0.2s ease;

    &:hover {
      background: ${colors.mainGreen}60;
    }
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${colors.mainGreen}40 transparent;
  .score-section {
    .score-tracker {
      background: #f0f0f0;
      border-radius: 10px;
      height: 40px;
      position: relative;
      overflow: hidden;
      margin: 20px 0;
    }

    .progress-bar {
      background: linear-gradient(
        90deg,
        ${colors.lightGreen} 0%,
        ${colors.mainGreen} 100%
      );
      height: 100%;
      border-radius: 10px;
      transition: width 0.5s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      min-width: 40px;
    }

    .score-label {
      color: white;
      font-weight: bold;
      padding: 0 15px;
      text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const PercentageIndicator = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  background-color: ${colors.veryLightGreen};
  color: ${colors.mainGreen};
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;

  &::before {
    content: "↑";
    font-size: 12px;
  }
`;

export const RecommendationsSection = styled.div`
  margin-top: 24px;
  overflow-y: scroll;
  /* Modern scrollbar styling */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.mainGreen}40;
    border-radius: 4px;
    transition: background 0.2s ease;

    &:hover {
      background: ${colors.mainGreen}60;
    }
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${colors.mainGreen}40 transparent;
  h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #2d3748;
  }
`;

export const RecommendationItem = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  margin-bottom: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid #f0f0f0;
  transition: transform 0.2s ease, box-shadow 0.2s ease;

  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.07);
  }
`;

export const FeatureDetails = styled.div`
  flex: 1;

  .feature-name {
    font-weight: 500;
    font-size: 15px;
    color: #2d3748;
    margin-bottom: 4px;
    text-transform: capitalize;
  }

  .explanation {
    font-size: 14px;
    color: #718096;
    line-height: 1.5;
  }
`;

export const ShareButtonWrapper = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 200px;
  &:hover {
    transform: scale(1);
    transition: transform 0.4s ease;
  }
  p {
    white-space: nowrap;
    font-size: 14px;
    color: ${colors.mainGreen};
  }
  svg {
    width: 100%;
    height: 100%;
    transition: transform 0.2s ease;

    path {
      fill: ${colors.mainGreen};
    }
  }
`;
