import styled from 'styled-components';
import { colors } from 'colors' 

export const PageWrapperWrapper = styled.div`
  padding-left: 120px;
  padding-right: 5%;
  height: 100%;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 40px;
  min-width: 1210px;
  max-width: 2000px;
  background-color: ${colors.lightBlue};
  margin-left: 0;
`;
