import React from "react";

const Rankings = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8375 28.4375H2.5C1.9875 28.4375 1.5625 28.0125 1.5625 27.5V20C1.5625 18.1 3.1 16.5625 5 16.5625H10.8375C11.35 16.5625 11.775 16.9875 11.775 17.5V27.5C11.775 28.0125 11.35 28.4375 10.8375 28.4375ZM3.4375 26.5625H9.9V18.4375H5C4.1375 18.4375 3.4375 19.1375 3.4375 20V26.5625Z"
      fill="#EEF7F2"
    />
    <path
      d="M19.1627 28.4375H10.8252C10.3127 28.4375 9.8877 28.0125 9.8877 27.5V15C9.8877 13.1 11.4252 11.5625 13.3252 11.5625H16.6627C18.5627 11.5625 20.1002 13.1 20.1002 15V27.5C20.1002 28.0125 19.6877 28.4375 19.1627 28.4375ZM11.7752 26.5625H18.2377V15C18.2377 14.1375 17.5377 13.4375 16.6752 13.4375H13.3377C12.4752 13.4375 11.7752 14.1375 11.7752 15V26.5625Z"
      fill="#EEF7F2"
    />
    <path
      d="M27.5001 28.4375H19.1626C18.6501 28.4375 18.2251 28.0125 18.2251 27.5V21.25C18.2251 20.7375 18.6501 20.3125 19.1626 20.3125H25.0001C26.9001 20.3125 28.4376 21.85 28.4376 23.75V27.5C28.4376 28.0125 28.0126 28.4375 27.5001 28.4375ZM20.1001 26.5625H26.5626V23.75C26.5626 22.8875 25.8626 22.1875 25.0001 22.1875H20.1001V26.5625Z"
      fill="#EEF7F2"
    />
    <path
      d="M17.1249 10.4375C16.8249 10.4375 16.4499 10.35 16.0249 10.1L14.9999 9.48753L13.9874 10.0875C12.9624 10.7 12.2874 10.3375 12.0374 10.1625C11.7874 9.98754 11.2499 9.43754 11.5124 8.28754L11.7499 7.25002L10.8999 6.40003C10.3749 5.87503 10.1874 5.25002 10.3749 4.67502C10.5624 4.10002 11.0749 3.70002 11.7999 3.57502L12.8874 3.38752L13.4999 2.16255C14.1749 0.825049 15.8124 0.825049 16.4749 2.16255L17.0874 3.38752L18.1749 3.57502C18.8999 3.70002 19.4249 4.10002 19.5999 4.67502C19.7874 5.25002 19.5874 5.87503 19.0749 6.40003L18.2249 7.25002L18.4624 8.28754C18.7249 9.45004 18.1874 9.98755 17.9374 10.1751C17.8249 10.2751 17.5374 10.4375 17.1249 10.4375ZM14.9999 7.60002C15.2999 7.60002 15.5999 7.67505 15.8499 7.82505L16.5499 8.23753L16.3999 7.56252C16.2749 7.03752 16.4624 6.40003 16.8499 6.01253L17.4874 5.37502L16.6999 5.23754C16.1999 5.15004 15.7124 4.78754 15.4874 4.33754L14.9999 3.40004L14.5249 4.33754C14.2999 4.78754 13.8124 5.15004 13.3124 5.23754L12.5249 5.36255L13.1624 6.00002C13.5499 6.38752 13.7249 7.02505 13.6124 7.55005L13.4624 8.22502L14.1624 7.81254C14.3999 7.66254 14.6999 7.60002 14.9999 7.60002Z"
      fill="#EEF7F2"
    />
  </svg>
);

export default Rankings;
