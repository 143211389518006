import styled from "styled-components";
import { colors } from "colors";

export const PaginateWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 30px 0;
  flex-wrap: nowrap;
  justify-content: center;
  .pages-wrapper {
    display: flex;
    width: 50%;
  }
  .breakdown-wrapper {
    width: 50%;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: -0.01em;
    text-align: left;
    color: ${colors.goodBlack};
  }
`;

interface PageProps {
  index: number;
  activePage: number;
}

export const Page = styled.div<PageProps>`
  cursor: pointer;
  width: 25px;
  height: 25px;
  padding: 6px 9px 6px 9px;
  border-radius: 5px;
  background-color: ${colors.lightGray};
  color: ${colors.darkGray2};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 9px;
  font-size: 12px;
  ${(props) =>
    props.index + 1 === props.activePage
      ? `
    background-color: ${colors.darkGray};
    color: ${colors.textWhite};
  `
      : `
  
  `}
  &:hover {
    background-color: ${colors.darkGray};
    color: ${colors.textWhite};
  }
`;
