import React from "react"

const Check = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1882 7.73783L9.00465 18.9214C8.93974 18.9864 8.86265 19.0379 8.77779 19.0731C8.69294 19.1083 8.60199 19.1264 8.51013 19.1264C8.41827 19.1264 8.32732 19.1083 8.24247 19.0731C8.15761 19.0379 8.08052 18.9864 8.01561 18.9214L3.12281 14.0286C2.99165 13.8974 2.91797 13.7195 2.91797 13.534C2.91797 13.3486 2.99165 13.1707 3.12281 13.0395C3.25396 12.9084 3.43185 12.8347 3.61733 12.8347C3.80281 12.8347 3.9807 12.9084 4.11185 13.0395L8.51013 17.4387L19.1992 6.74878C19.3303 6.61763 19.5082 6.54395 19.6937 6.54395C19.8792 6.54395 20.057 6.61763 20.1882 6.74878C20.3194 6.87994 20.393 7.05782 20.393 7.24331C20.393 7.42879 20.3194 7.60667 20.1882 7.73783Z"
        fill="#34D399"
      />
    </svg>
  )
}

export default Check
