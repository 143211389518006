import { colors } from "colors";
import styled from "styled-components";

interface TranscriptProps {
  video_url?: string;
}

export const TranscriptWrapper = styled.div<TranscriptProps>`
  width: 100%;
  padding: 25px;
  color: ${colors.black};
    /* Modern scrollbar styling */
    ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.mainGreen}40;
    border-radius: 4px;
    transition: background 0.2s ease;

    &:hover {
      background: ${colors.mainGreen}60;
    }
  }

  ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 4px;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${colors.mainGreen}40 transparent;
  .search-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 20px;
    button {
      margin-left: 66px;
    }
    .search-bar {
      width: 70%;
    }
  }
  .transcript-content {
    height: 540px;
    overflow-y: auto;
    padding: 20px;
    padding-right: 45px;
    ${(props) => props?.video_url && `height: 175px;`}
  }
  .highlight {
    background-color: yellow;
    color: black;
    transition: background-color 0.5s ease;
  }
  .scroll-highlight {
    .message-content-wrapper {
      background-color: #93fbc9;
      color: black;
      transition: background-color 0.5s ease;
    }
  }
  .video-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
  }
  video {
    height: 320px;
    min-height: 320px;
    margin: 0 auto;
    border-radius: 5px;
  }
`;

// Styled components for the transcript UI
export const TranscriptLine = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
`;

export const AvatarWrapper = styled.div`
  flex-shrink: 0;
  margin-right: 10px;
`;

export const Avatar = styled.div<{ color: string; textColor: string }>`
  background-color: ${({ color }) => color};
  color: ${({ textColor }) => textColor};
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
`;

export const MessageWrapper = styled.div`
  max-width: 70%;
  .message-content-wrapper {
    background-color: #f7f9fc;
    border-radius: 0 5px 5px 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: ${colors.black};
    padding: 10px 15px;
    font-size: 14px;
  }
`;

export const SpeakerName = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
`;

export const Timestamp = styled.span`
  font-size: 12px;
  color: ${colors.goodBlack};
  margin-left: 10px;
`;

// Example mapping of avatars based on speakers
export const avatarColors: Record<string, string> = {
  Drew: "#E8A6A6", // Light red
  "Sam Maliska": "#D6B497", // Light brown
  // Add more speakers and colors as needed
};
